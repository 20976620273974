import { DashboardApiProps, DashboardAppType, DashboardCallType, DashboardDuration, DashboardFeedback, DashboardOverview } from '@interfaces';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, debounceTime, filter, from, merge, Observable, of, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

type WithErrorState<T> = T | ErrorState;

export class DashboardOverviewState {}

export class DashboardSuccessState extends DashboardOverviewState {
  conferences: WithErrorState<DashboardOverview>;

  appType: WithErrorState<DashboardAppType>;

  callType: WithErrorState<DashboardCallType>;

  feedbacks: WithErrorState<DashboardFeedback>;

  conferencesTotalCount: number;

  duration: WithErrorState<DashboardDuration>;

  ongoingCount: WithErrorState<number>;

  constructor(
    conferences: DashboardOverview | ErrorState,
    duration: DashboardDuration | ErrorState,
    appType: DashboardAppType | ErrorState,
    callType: DashboardCallType | ErrorState,
    feedbacks: DashboardFeedback | ErrorState,
    ongoingCount: number | ErrorState
  ) {
    super();
    this.appType = appType;
    this.callType = callType;
    this.conferences = conferences;
    this.feedbacks = feedbacks;
    this.duration = duration;
    this.ongoingCount = ongoingCount;
    this.conferencesTotalCount = Object.values(conferences).reduce((a, b) => a + b, 0);
  }
}

export class DashboardPreviousState extends DashboardSuccessState {
  previous: DashboardSuccessState;

  start: Moment;

  end: Moment;

  constructor(current: DashboardSuccessState, previous: DashboardSuccessState, start: Moment, end: Moment) {
    super(current.conferences, current.duration, current.appType, current.callType, current.feedbacks, current.ongoingCount);

    this.previous = previous;
    this.start = start;
    this.end = end;
  }
}

export class DashboardService extends Service<DashboardOverviewState> {
  constructor(dashboardApi: DashboardApiProps) {
    super(new LoadingState());

    this.dashboardApi = dashboardApi;

    merge(this.start, this.end, this.retry, this.appType, this.callType)
      .pipe(debounceTime(200))
      .pipe(switchMap(this.collect))
      .subscribe(dashboardState => this.next(dashboardState));

    combineLatest([this.previous, this])
      .pipe(debounceTime(200))
      .pipe(filter(([prev, state]) => prev && !(state instanceof DashboardPreviousState) && state instanceof DashboardSuccessState))
      .pipe(switchMap(([, state]) => this.collectPreviousData(state as DashboardSuccessState)))
      .subscribe(dashboardPrevState => this.next(dashboardPrevState));
  }

  dashboardApi: DashboardApiProps;

  start = new BehaviorSubject<Moment>(moment().startOf('days'));

  end = new BehaviorSubject<Moment>(moment().endOf('days'));

  previous = new BehaviorSubject<boolean>(false);

  retry = new BehaviorSubject<number>(0);

  appType = new BehaviorSubject<number | 'All'>('All');

  callType = new BehaviorSubject<number | 'All'>('All');

  private collect = (): Observable<DashboardOverviewState> => {
    this.next(new LoadingState());
    const getState = async (): Promise<DashboardSuccessState | ErrorState> => {
      try {
        const state = await this.getData(this.start.getValue(), this.end.getValue());
        return new DashboardSuccessState(state.conferences, state.duration, state.appType, state.callType, state.feedbacks, state.ongoingCount);
      } catch (err) {
        return new ErrorState((err as AxiosError).message);
      }
    };

    return from(getState()).pipe(
      switchMap(state => {
        if (this.previous.getValue() && state instanceof DashboardSuccessState) {
          return this.collectPreviousData(state);
        }
        return of(state);
      })
    );
  };

  private collectPreviousData = (currentState: DashboardSuccessState): Observable<DashboardOverviewState> => {
    this.next(new LoadingState());
    const differenceInSeconds = moment(this.end.getValue()).unix() - moment(this.start.getValue()).unix() + 1;
    const compareFrom = moment(this.start.getValue()).subtract(differenceInSeconds, 'seconds').startOf('day');
    const compareTo = moment(this.end.getValue()).subtract(differenceInSeconds, 'seconds').endOf('day');

    const getState = async (): Promise<DashboardPreviousState | ErrorState> => {
      try {
        const previousState = await this.getData(compareFrom, compareTo);
        return new DashboardPreviousState(currentState, previousState, compareFrom, compareTo);
      } catch (error) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  private getData = async (start: Moment, end: Moment) => {
    return Promise.allSettled([
      this.dashboardApi.getConferences(start, end, this.appType.value, this.callType.value),
      this.dashboardApi.getDuration(this.start.value, this.end.value, this.appType.value),
      this.dashboardApi.getCallsByPartner(this.start.value, this.end.value),
      this.dashboardApi.getCallsByType(this.start.value, this.end.value, this.callType.value),
      this.dashboardApi.getConferenceFeedbacks(this.start.value, this.end.value, this.callType.value, this.appType.value),
      this.dashboardApi.getOngoingCount(),
    ]).then(result => {
      return new DashboardSuccessState(
        result[0].status === 'fulfilled' ? result[0].value : new ErrorState(result[0].reason.message),
        result[1].status === 'fulfilled' ? result[1].value : new ErrorState(result[1].reason.message),
        result[2].status === 'fulfilled' ? result[2].value : new ErrorState(result[2].reason.message),
        result[3].status === 'fulfilled' ? result[3].value : new ErrorState(result[3].reason.message),
        result[4].status === 'fulfilled' ? result[4].value : new ErrorState(result[4].reason.message),
        result[5].status === 'fulfilled' ? result[5].value.count : new ErrorState(result[5].reason.message)
      );
    });
  };

  // Whenever there's a network error or a case where the data couldn't be retreived
  // we increment the value of retry in order to refetch the request from the
  // merged sources which listens to this variables value changes
  refetch = () => this.retry.next(this.retry.getValue() + 1);
}
