import { createContext, useMemo } from 'react';
import { DashboardApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { DashboardService } from '@services';

const dashboardApi = new DashboardApi();

export const DashboardContext = createContext<DashboardService>({} as DashboardService);

export const DashboardProvider = ({ children }: ContextProviderProps) => {
  const dashboardService = useMemo(() => new DashboardService(dashboardApi), []);

  return <DashboardContext.Provider value={dashboardService}>{children}</DashboardContext.Provider>;
};
