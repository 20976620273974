import { ReactElement } from 'react';
import { Col } from 'reactstrap';
import { GraphsProps } from './Graphs.props';

export const GraphSeriesText = ({ series }: GraphsProps): ReactElement => (
  <>
    {series &&
      series
        .filter(s => typeof s.points[0]?.y !== 'number')
        .filter(serie => serie.serieName !== 'qualityLimitationDurations')
        .map(serie => (
          <Col key={serie.serieName} className='p-1 dark:text-gray-400' style={{ fontSize: '14px' }}>
            <span>{`${serie.serieName}: `}</span>

            {[...new Set(serie.points.map(point => JSON.stringify(point.y)))].map(p => {
              return (
                <strong key={p} className='dark:text-gray-400'>
                  {p}
                </strong>
              );
            })}
          </Col>
        ))}
  </>
);
