import { ReactElement } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { Error, Loader } from '@components';
import { SingleParticipantContext } from '@context';
import { HealthcareRole, ParticipantStateEnums } from '@enums';
import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import { UserAgent, UserAgentProvider } from '@quentin-sommer/react-useragent';
import { ErrorState, LoadingState, SingleParticipantService, SingleParticipantState, SingleParticipantSucessState, useService } from '@services';
import { singleParticipantHeaders } from '@static';
import { getLowerCaseString, utcTimeStampToLocaleTime } from '@utils';

export const SingleParticipant = (): ReactElement => {
  const [state] = useService<SingleParticipantState, SingleParticipantService>(SingleParticipantContext);

  const isSuccessState = state instanceof SingleParticipantSucessState;
  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;

  return (
    <Card className='dark:bg-dark dark:border-gray-700'>
      <CardHeader className='dark:bg-dark-body dark:text-gray-300 flex items-center dark:border-gray-700'>
        <UserIcon className='w-6 h-6 text-gray-300 mr-2' />
        <p className='mb-0 text-gray-300 dark:text-gray-300 text-sm'>Participant</p>
      </CardHeader>
      <CardBody>
        <Table>
          <thead>
            <tr className='text-sm dark:border-gray-700 dark:text-[#989082]'>
              {singleParticipantHeaders.map(header => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isError && (
              <tr className='text-sm'>
                <td colSpan={14}>
                  <Error message={state.errorMessage} />
                </td>
              </tr>
            )}
            {isLoading && (
              <tr className='text-sm'>
                <td colSpan={14}>
                  <Loader />
                </td>
              </tr>
            )}
            {isSuccessState && (
              <tr className='text-sm dark:text-gray-300 dark:border-gray-700'>
                <td>{state.participant?.fullName}</td>
                <td>{state.participant?.device}</td>
                <td>{state.participant?.deviceSerialNumber}</td>
                <td>{getLowerCaseString(ParticipantStateEnums[state.participant?.state])}</td>
                <td>{state.participant?.ipAddress}</td>
                <td>{utcTimeStampToLocaleTime(state.participant?.joinTime)}</td>
                <td>{state.participant?.appVersion}</td>
                <td align='center'>
                  {state.participant?.usedAudio ? (
                    <CheckCircleIcon className='w-6 h-6 text-green-400 ' />
                  ) : (
                    <MinusCircleIcon className='w-6 h-6 text-gray-300' />
                  )}
                </td>
                <td align='center'>
                  {state.participant?.usedVideo ? (
                    <CheckCircleIcon className='w-6 h-6 text-green-400 ' />
                  ) : (
                    <MinusCircleIcon className='w-6 h-6 text-gray-300' />
                  )}
                </td>
                <td align='center'>
                  {state.participant?.usedScreen ? (
                    <CheckCircleIcon className='w-6 h-6 text-green-400 ' />
                  ) : (
                    <MinusCircleIcon className='w-6 h-6 text-gray-300' />
                  )}
                </td>
                <td>
                  <UserAgentProvider ua={state.participant?.userAgent}>
                    <UserAgent returnFullParser>
                      {/* This is a very specific case where I need to infer type of any, 
                        since the parser is extracted from a JS library that doesn't have type definitions */}
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {(parser: any) => (
                        <p>
                          {parser.getBrowser().name != null
                            ? `${parser.getBrowser().name} ${parser.getBrowser().version != null ? `${parser.getBrowser().version} on` : ' '}`
                            : ' '}{' '}
                          {parser.getOS().name} {parser.getOS().version}{' '}
                        </p>
                      )}
                    </UserAgent>
                  </UserAgentProvider>
                </td>
                <td align='center'>{state.participant?.objectId || 'n/a'}</td>
                <td align='center'>{getLowerCaseString(HealthcareRole[state.participant.companyMemberRoleId])}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
