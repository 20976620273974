export const AIStatisticsLabels = {
  1: 'Accurate',
  2: 'False',
  3: 'Other',
};

export const AIStatisticsNurseLabels = {
  5: 'Acknowledge',
  6: 'False',
  7: 'Forwarded',
};

export const AIStatisticsStatuses = {
  ACCURATE: 1,
  FALSE: 2,
  OTHER: 3,
};

export const AIStatisticsColors = {
  1: '#5f91ff',
  2: '#e27c7c',
  3: '#c7d9fa',
};

export const AIStatisticsNurseColors = {
  5: '#5f91ff',
  6: '#e27c7c',
  7: '#c7d9fa',
};

export const OrganizationLevels = {
  HEALTHSYSTEM: 0,
  REGION: 1,
  HOSPITAL: 2,
  DEPARTMENT: 3,
  FLOOR: 4,
  ROOM: 5,
  ORGANIZATION: 6,
};
