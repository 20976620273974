import { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import { ReloadButton } from '@components';
import { DashboardContext } from '@context';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { DashboardOverviewState, DashboardService, DashboardSuccessState, ErrorState, useService } from '@services';

export const Calls = (): ReactElement => {
  const [state, service] = useService<DashboardOverviewState, DashboardService>(DashboardContext);

  if (state instanceof ErrorState) {
    return (
      <div className='flex flex-col items-center'>
        <div className='flex flex-col items-center space-y-2'>
          <div className='flex justify-start items-center w-full'>
            <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:text-red-400' />
            <p className='mb-0 ml-1 dark:text-[#989082]'>{state.errorMessage}</p>
          </div>
          <p className='font-medium'>There was a problem fetching your data.</p>
          <ReloadButton description='Reload' className='w-full' onClick={service.refetch} />
        </div>
      </div>
    );
  }

  const isSuccessState = state instanceof DashboardSuccessState;

  return (
    <Row>
      <Col>
        <div className='flex flex-col relative px-4 border-l-4 border-[#63c2de] rounded'>
          <small className='text-[#73818f] dark:text-[#989082] font-normal text-[80%]'>Ongoing Calls</small>
          <strong className='dark:text-gray-400 text-xl font-medium'>
            {isSuccessState && !(state.ongoingCount instanceof ErrorState) ? state.ongoingCount : 0}
          </strong>
        </div>
      </Col>
      <Col>
        <div className='flex flex-col relative px-4 border-l-4 border-[#63c2de] rounded'>
          <small className='text-[#73818f] dark:text-[#989082] font-normal text-[80%]'>Today&apos;s Calls</small>
          <strong className='dark:text-gray-400 text-xl font-medium'>{isSuccessState ? state.conferencesTotalCount : 0}</strong>
        </div>
      </Col>
    </Row>
  );
};
