import { ReactElement } from 'react';
import { Button } from 'reactstrap';
import { ThemeContext } from '@context';
import { ThemeService, ThemeState, useService } from '@services';
import { utcToDateTime } from '@utils';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { GeneratePDFProps } from './GeneratePDF.props';

export const GeneratePDF = ({ isPeerStatsReport, selectedParticipantIds, participants, events, selectedTimezone }: GeneratePDFProps): ReactElement => {
  const [, service] = useService<ThemeState, ThemeService>(ThemeContext);

  const isDarkMode = service.theme.value === 'dark';

  const handleGeneratePDF = async () => {
    if (isPeerStatsReport) {
      const charts = document.getElementById('chart');
      const pdf = new JSPDF('p', 'mm');

      if (charts) {
        html2canvas(charts).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 180;
          const pageHeight = 295;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'text', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          pdf.save('Charts.pdf');
        });
      }
    } else {
      const pdf = new JSPDF('landscape', 'pt', 'A4');
      const headers = [['PARTICIPANT', 'EVENT KEY', 'TIMESTAMP', 'DESCRIPTION']];

      const data = events
        .filter(e => selectedParticipantIds.includes(e.participantId) || !e.participantId)
        .map((item, index) => {
          const user = participants.find(u => u.id === item.participantId);
          return [
            user ? `${index + 1}. ${user.fullName}` : `${index + 1}. Conference Event`,
            item.eventKey,
            `${utcToDateTime(item.timestamp, selectedTimezone)}.${Number(moment(item.timestamp).milliseconds())}`,
            `${item.description}${item.data && item.data.length > 0 ? item.data.map(d => d && ` ${d.item1}: ${d.item2}`) : ''}`,
          ];
        });

      autoTable(pdf, {
        head: headers,
        body: data,
        startY: 50,
        columnStyles: {
          0: { cellWidth: 120 },
          1: { cellWidth: 180 },
          2: { cellWidth: 100 },
          3: { cellWidth: 350 },
        },
        styles: { fontSize: 12 },
      });
      pdf.save('Events.pdf');
    }
  };

  return (
    <Button
      onClick={handleGeneratePDF}
      style={{
        fontSize: '14px',
        background: isDarkMode ? '#0c4a6e' : 'rgb(14 165 233)',
        color: isDarkMode ? 'rgb(229, 231, 235)' : 'white',
        border: '1px solid rgba(107, 114, 128, 1)',
      }}>
      Generate PDF
    </Button>
  );
};
