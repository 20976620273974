import moment, { Moment } from 'moment';

export const customDateFormat = (date: string | Date | Moment | number, format: string): string => {
  if (!moment(date).isValid()) {
    return 'Invalid Date';
  }

  if (date === 0) {
    return 'N/A';
  }

  return moment(date).format(format);
};
