import { LoginActions, LogoutActions } from '@enums';

export const ApplicationName = 'Solaborate.Callstats.Console';

const prefix = '/authentication';

export const ApplicationPaths = {
  DEFAULT_LOGIN_REDIRECT_PATH: '/',
  API_AUTHORIZATION_CLIENT_CONFIGURATION_URL: `/_configuration/${ApplicationName}`,
  API_AUTHORIZATION_PREFIX: prefix,
  LOGIN: `${prefix}/${LoginActions.LOGIN}`,
  LOGIN_FAILED: `${prefix}/${LoginActions.LOGIN_FAILED}`,
  LOGIN_CALLBACK: `${prefix}/${LoginActions.LOGIN_CALLBACK}`,
  REGISTER: `${prefix}/${LoginActions.REGISTER}`,
  PROFILE: `${prefix}/${LoginActions.PROFILE}`,
  LOGOUT: `${prefix}/${LogoutActions.LOGOUT}`,
  LOGGED_OUT: `${prefix}/${LogoutActions.LOGGED_OUT}`,
  LOGOUT_CALLBACK: `${prefix}/${LogoutActions.LOGOUT_CALLBACK}`,
  IDENTITY_REGISTER_PATH: '/Identity/Account/Register',
  IDENTITY_MANAGE_PATH: '/Identity/Account/Manage',
};

interface IdentityConfigProps {
  login: string;
  authority: string;
  client_id: string;
  public_uri: string;
  redirect_uri: string;
  silent_redirect_uri: string;
  post_logout_redirect_uri: string;
  response_mode: 'query' | 'fragment';
  response_type: string;
  scope: string;
  client_secret: string;
}

export const IDENTITY_CONFIG: IdentityConfigProps = {
  authority: '',
  client_id: '',
  login: '',
  post_logout_redirect_uri: '',
  public_uri: '',
  redirect_uri: '',
  scope: '',
  silent_redirect_uri: '',
  response_mode: 'query',
  response_type: '',
  client_secret: '',
};

IDENTITY_CONFIG.response_type = 'id_token token';
IDENTITY_CONFIG.scope = 'openid profile email roles API signaling api.gateway';

if (process.env.NODE_ENV === 'development') {
  IDENTITY_CONFIG.authority = process.env.REACT_APP_IDSRV_URL || '';
  IDENTITY_CONFIG.client_id = process.env.REACT_APP_IDSRV_CLIENT_ID || '';
  IDENTITY_CONFIG.redirect_uri = process.env.REACT_APP_IDSRV_REDIRECT_URL || '';
  IDENTITY_CONFIG.login = `${process.env.REACT_APP_IDSRV_URL}/login` || '';
  IDENTITY_CONFIG.silent_redirect_uri = `${process.env.REACT_APP_SILENT_REDIRECT_URL}/login` || '';
  IDENTITY_CONFIG.post_logout_redirect_uri = process.env.REACT_APP_LOGOUT_URL || '';
  IDENTITY_CONFIG.public_uri = process.env.REACT_APP_PUBLIC_URL || '';
} else {
  IDENTITY_CONFIG.authority = window.__env__.REACT_APP_IDSRV_URL || '';
  IDENTITY_CONFIG.client_id = window.__env__.REACT_APP_IDSRV_CLIENT_ID || '';
  IDENTITY_CONFIG.redirect_uri = window.__env__.REACT_APP_IDSRV_REDIRECT_URL || '';
  IDENTITY_CONFIG.login = `${window.__env__.REACT_APP_IDSRV_URL}/login` || '';
  IDENTITY_CONFIG.silent_redirect_uri = `${window.__env__.REACT_APP_SILENT_REDIRECT_URL}/login` || '';
  IDENTITY_CONFIG.post_logout_redirect_uri = window.__env__.REACT_APP_LOGOUT_URL || '';
  IDENTITY_CONFIG.public_uri = window.__env__.REACT_APP_PUBLIC_URL || '';
}
