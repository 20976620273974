export enum ConferenceStatusDescription {
  STATUS = 'Weren`t able to determine conference status.',
  ABLE_TO_JOIN = 'All participants were able to join.',
  NOT_ABLE_TO_JOIN = 'Some of the participants were not able to join due to errors.',
  NONE_ABLE_TO_JOIN = 'None of the participants were able to connect due to errors.',
  NOT_ABLE_TO_SEE_HEAR_PARTICIPANTS = 'Call was successfully established but during a call an action that one of the participants took, causes all participants to not hear/see each other, but the call remains connected still.',
  NOT_ABLE_TO_SEE_HEAR_PARTICIPANT = 'Call was successfully established but during a call an action that one of the participants took, causes that participant to not hear/see others, but the call remains connected still.',
  NONE_ANSWERED = 'None of the participants answered the call.',
  CALL_ENDS = 'Someone ends the call while others were still connecting or call wasn`t established yet.',
  DISCONNECTED = 'Participant was disconnected from the call and that caused ending the whole conference.',
}

export enum ConferenceStatus {
  UNDEFINED = 0,
  SUCCESSFUL = 1,
  PARTIALLY_SUCCESSFUL = 2,
  FAILED = 3,
  DISRUPTED = 4,
  PARTIALLY_DISRUPTED = 5,
  MISSED = 6,
  ABORTED = 7,
  DROPPED = 8,
}

export enum ConferenceSearchType {
  PARTICIPANT_NAME = 0,
  CONFERENCE_ID = 1,
  DEVICE_SERIAL_NUMBER = 2,
  OBJECT_ID = 3,
}

export const ConferenceStatusLabels = {
  0: 'All',
  1: 'Successful',
  2: 'PartiallySuccessful',
  3: 'Failed',
  4: 'Disrupted ',
  5: 'PartiallyDisrupted',
  6: 'Missed',
  7: 'Aborted',
  8: 'Dropped',
};

export enum ConferenceEndReasonLabels {
  UNDEFINED = 'All',
  PARITICPANT_OFFLINE = 1,
  PARTICIPANT_BUSY = 2,
  INITIATOR_LEFT = 3,
  PARTICIPANT_NOT_ANSWERING = 4,
  PARTICIPANT_DECLINED = 5,
  PARTICIPANT_LEFT = 6,
  OWNER_LEFT = 7,
  ABORTED = 8,
  DROPPED = 9,
  PARTICIPANT_PASSWORD_CHANGED = 10,
  PARTICIPANT_IDLE = 11,
  TERMINATED_BY_ADMINISTRATOR = 12,
  PARTICIPANT_INVITE_DENIED = 13,
  PARTICIPANT_INVITE_FAILED = 14,
  INITIATOR_BUSY = 998,
  MAINTENANCE_WORKER_CLEANUP = 999,
}

export enum ConferenceEndReasonDescriptions {
  WE_COULD_NOT_DETERMINE_THE_REASON = 0,
  ALL_RECIPIENT_PARTICIPANTS_WERE_OFFLINE = 1,
  ALL_RECIPIENT_PARTICIPANTS_WERE_BUSY = 2,
  INITIATOR_LEFT_THE_CALL = 3,
  RECIPIENT_PARTICIPANT_MISSED_THE_CALL = 4,
  PARTICIPANT_DECLINED_THE_CALL = 5,
  PARTICIPANT_LEFT_THE_CALL = 6,
  OWNER_LEFT_THE_MEETING_ROOM = 7,
  A_PARTICIPANT_ENDED_THE_CALL_WHILE_THE_OTHERS_WERE_STILL_CONNECTING_OR_WHILE_THE_CALL_WAS_NOT_YET_ESTABLISHED = 8,
  THE_INITIATOR_DISCONNECTED_CAUSING_THE_CONFERENCE_TO_END = 9,
  PARTICIPANT_PASSWORD_CHANGED = 10,
  PARTICIPANT_IDLE = 11,
  CONFERENCE_TERMINATED_BY_ADMINISTRATOR = 12,
  INITIATOR_ALREADY_IN_A_CALL_OR_MONITORING_PATIENTS = 998,
  CONFERENCE_WAS_ENDED_BY_MAINTENANCE_WORKER = 999,
}

export enum CallTypeLabels {
  AUDIO = 1,
  VIDEO = 2,
  SCREEN_SHARE = 3,
  SECURITY_CAM = 4,
  LIVE_BROADCAST = 5,
  VIDEO_MONITORING = 6,
  FRONTLINE = 7,
  AMBIENT_AND_VIDEO_MONITORING = 97,
  AMBIENT_MONITORING = 98,
  QUEUEING = 99,
}

export enum ClientType {
  ALL = 'All',
  WEB = 0,
  ANDROID = 1,
  IOS = 2,
  HELLO = 3,
}

export enum SessionType {
  VideoMonitoring = 0, // Video Monitroing
  AmbientMonitoring = 1, // Ambient Monitoring
  VideoAmbientMonitoring = 2, // Video and Ambient monitoring
}
