import { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { DiagnosisProvider, EventsProvider, ParticipantsProvider, ThemeContext } from '@context';
import { PencilIcon, ServerStackIcon, UsersIcon } from '@heroicons/react/24/solid';
import { ThemeService, ThemeState, useService } from '@services';
import { NewEventsContainer } from '@views-pages';
import { EventsContainer } from '../../Events/EventsContainer';
import { Participants } from '../Participants/Participants';
import { Diagnosis } from './Diagnosis';

export const SingleConferenceTabs = (): ReactElement => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [, service] = useService<ThemeState, ThemeService>(ThemeContext);

  const { pathname } = useLocation();

  const isOngoing = pathname.includes('ongoing');
  const isDarkMode = service.theme.value === 'dark';

  const handleSetActiveTab = (tab: number) => () => setActiveTab(tab);

  return (
    <div className='mt-6'>
      <Nav tabs className='dark:border-gray-700'>
        <NavItem>
          <NavLink
            className={activeTab === 0 ? 'active-tab' : ''}
            style={{ display: 'flex', alignItems: 'center', color: isDarkMode ? 'rgb(156, 163, 175)' : 'rgb(75 85 99)', fontSize: '14px' }}
            active={activeTab === 0}
            onClick={handleSetActiveTab(0)}>
            <UsersIcon className='w-4 h-4 text-gray-600 dark:text-gray-400 mr-2' />
            Participants
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 1 ? 'active-tab' : ''}
            style={{ display: 'flex', alignItems: 'center', color: isDarkMode ? 'rgb(156, 163, 175)' : 'rgb(75 85 99)', fontSize: '14px' }}
            active={activeTab === 1}
            onClick={handleSetActiveTab(1)}>
            <ServerStackIcon className='w-4 h-4 text-gray-600 dark:text-gray-400  mr-2' />
            Events
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 2 ? 'active-tab' : ''}
            style={{ display: 'flex', alignItems: 'center', color: isDarkMode ? 'rgb(156, 163, 175)' : 'rgb(75 85 99)', fontSize: '14px' }}
            active={activeTab === 2}
            onClick={handleSetActiveTab(2)}>
            <ServerStackIcon className='w-4 h-4 text-gray-600 dark:text-gray-400 mr-2' />
            New Events
          </NavLink>
        </NavItem>
        {!isOngoing && (
          <NavItem>
            <NavLink
              className={activeTab === 3 ? 'active-tab' : ''}
              style={{ display: 'flex', alignItems: 'center', color: isDarkMode ? 'rgb(156, 163, 175)' : 'rgb(75 85 99)', fontSize: '14px' }}
              active={activeTab === 3}
              onClick={handleSetActiveTab(3)}>
              <PencilIcon className='w-4 h-4 text-gray-600 dark:text-gray-400 mr-2' />
              Diagnosis
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <Card className='dark:bg-dark dark:border dark:border-gray-700' style={{ borderTop: 0, borderRadius: '0 0 6px 6px' }}>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              {activeTab === 0 && (
                <ParticipantsProvider isOngoing={isOngoing}>
                  <Participants />
                </ParticipantsProvider>
              )}
            </TabPane>
            <TabPane tabId={1}>
              {activeTab === 1 && (
                <EventsProvider isOngoing={isOngoing}>
                  <EventsContainer />
                </EventsProvider>
              )}
            </TabPane>
            <TabPane tabId={2}>
              {activeTab === 2 && (
                <EventsProvider isOngoing={isOngoing}>
                  <NewEventsContainer />
                </EventsProvider>
              )}
            </TabPane>
            <TabPane tabId={3}>
              {activeTab === 3 && !isOngoing && (
                <DiagnosisProvider>
                  <Diagnosis />
                </DiagnosisProvider>
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};
