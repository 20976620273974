import { gatewayApi } from '@config';
import { ConferenceFeedbacksModel, ConferencesFeedbackApiProps, ConferencesFeedbackPayload } from '@interfaces';
import { AxiosResponse } from 'axios';

export class ConferencesFeedbackApi implements ConferencesFeedbackApiProps {
  getConferencesFeedbacks = async (payload: ConferencesFeedbackPayload): Promise<ConferenceFeedbacksModel> => {
    const { data }: AxiosResponse<ConferenceFeedbacksModel> = await gatewayApi.get('v1/conferences/feedbacks', {
      params: {
        start: payload.start.unix(),
        end: payload.end.unix(),
        rate: payload.rate || 'All',
        conferenceId: payload.conferenceId,
        pageIndex: payload.pageIndex,
        pageSize: payload.pageSize,
      },
    });

    return data;
  };
}
