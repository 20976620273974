import { ReactElement } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';
import { Button, Col, Label, Row } from 'reactstrap';
import { EventsContext, ThemeContext } from '@context';
import { EventFiltersInputs } from '@interfaces';
import { EventsService, EventsState, ThemeService, ThemeState, useService } from '@services';
import queryString from 'query-string';

export const NewEventFilters = (): ReactElement => {
  const [, service] = useService<EventsState, EventsService>(EventsContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue, control, getValues } = useForm<EventFiltersInputs>({
    mode: 'onSubmit',
    defaultValues: { excludeWebRtcEvents: service.excludeWebRtcEvents.value },
  });
  const { timezone: timezoneVal } = useWatch<EventFiltersInputs>({ control });

  const isDarkMode = themeService.theme.value === 'dark';

  const handleApplyFilters = (data: EventFiltersInputs) => {
    if (getValues('searchQuery')) {
      service.pageIndex.next(0);
      navigate({
        search: queryString.stringify({
          pageIndex: service.pageIndex.value,
        }),
      });
    }
    service.applyFilters(data);
  };

  const handleSetChecked = () => service.toggleAllParticipants();

  const handleChangeTimeZone = (timezone: ITimezoneOption) => setValue('timezone', { label: timezone.label, value: timezone.value });

  return (
    <form onSubmit={handleSubmit(handleApplyFilters)}>
      <Row className='flex items-center justify-center mt-2 mb-4 text-sm px-10' md='12'>
        <Col md='3'>
          <input
            type='search'
            id='text-input'
            placeholder='Search by event name, e.g. conference.participantJoined'
            className='px-3 py-2 border-[1px] rounded-lg w-full focus:outline-gray-200 dark:bg-transparent dark:text-gray-400 dark:border-gray-700'
            {...register('searchQuery')}
          />
        </Col>
        <Col md='3'>
          <TimezoneSelect
            styles={
              isDarkMode
                ? {
                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                    menu: styles => ({
                      ...styles,
                      background: '#1e2022',
                      color: '#9ca3af',
                      border: '1px solid #6B7280',
                    }),
                    option: (styles, { isSelected }) => ({
                      ...styles,
                      ':hover': { background: '#374151' },
                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                    }),
                  }
                : {}
            }
            className='z-[40]'
            onChange={handleChangeTimeZone}
            value={!timezoneVal ? Intl.DateTimeFormat().resolvedOptions().timeZone : { label: timezoneVal.label || '', value: timezoneVal.value || '' }}
          />
        </Col>
        <Col md='5' className='flex items-center justify-between'>
          <Col className='space-x-2 flex items-center'>
            <input type='checkbox' id='showAllParticipants' checked={service.participantIds.value.length !== 0} onClick={handleSetChecked} />
            <Label check className='form-check-label dark:text-gray-400' htmlFor='showAllParticipants'>
              {service.participantIds.value.length === 0 ? 'Show All Participants' : 'Hide All Participants'}
            </Label>
          </Col>
          <Col className='space-x-2 flex items-center'>
            <input type='checkbox' id='excludeWebRtcEvents' {...register('excludeWebRtcEvents')} />
            <Label check className='form-check-label dark:text-gray-400' htmlFor='excludeWebRtcEvents'>
              Hide WebRTC events
            </Label>
          </Col>
          <Col className='space-x-2 flex items-center'>
            <input type='checkbox' id='excludeGeneralEvents' {...register('excludeGeneralEvents')} />
            <Label check className='form-check-label dark:text-gray-400' htmlFor='excludeGeneralEvents'>
              Hide General events
            </Label>
          </Col>
        </Col>
        <Col>
          <Button type='submit' color={isDarkMode ? '' : 'success'} className='text-white dark:bg-sky-900' style={{ fontSize: '14px' }}>
            Apply Filters
          </Button>
        </Col>
      </Row>
    </form>
  );
};
