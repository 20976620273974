import { Moment } from 'moment';
import moment from 'moment-timezone';

export const utcToLocalDateTime = (date: string | Date | Moment | number, timezone?: string): string => {
  const stillUtc = moment.utc(date).toDate();
  const localDate = moment(stillUtc);
  return timezone ? moment.tz(localDate, timezone).format('MM/DD/YYYY - HH:mm:ss') : moment(localDate).format('MM/DD/YYYY - HH:mm:ss');
};

export const utcToDateTime = (utcDate: string, timeZone: string) => {
  return !utcDate.includes('+')
    ? moment.tz(utcDate, timeZone).format('MM/DD/YYYY - HH:mm:ss')
    : moment.tz(`${utcDate}Z`, timeZone).format('MM/DD/YYYY - HH:mm:ss');
};
