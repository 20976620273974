import { gatewayApi } from '@config';
import { AIStatisticsApiProps, GetStatisticsParams, HealthSystemsModel, RoomModel, StatisticsModel } from '@interfaces';
import { AxiosResponse } from 'axios';

export class AIStatisticsApi implements AIStatisticsApiProps {
  getStatistics = async (companyId: string, params: Partial<GetStatisticsParams>): Promise<StatisticsModel> => {
    const { data }: AxiosResponse<StatisticsModel> = await gatewayApi.get(`v1/organizations/${companyId}/ai-alerts/statistics`, {
      params: {
        ...(params.fromDate && { 'from-date': params.fromDate }),
        ...(params.toDate && { 'to-date': params.toDate }),
        ...(params.levelId && { 'level-id': params.levelId }),
        ...((params.levelType || 0) >= 0 && { 'level-type': params.levelType }),
      },
    });

    return data;
  };

  getHealthSystems = async (companyId: string): Promise<HealthSystemsModel> => {
    const { data }: AxiosResponse<HealthSystemsModel> = await gatewayApi.get(`v1/organizations/${companyId}/health-systems?roleId=2`);

    return data;
  };

  getRoomsByLevelId = async (companyId: string, levelId: string, level = 0): Promise<RoomModel> => {
    const { data }: AxiosResponse<RoomModel> = await gatewayApi.get(`v1/organizations/${companyId}/level/${level}/id/${levelId}/rooms`);

    return data;
  };
}
