import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from '@components';
import { defaultLayoutServiceContext, ThemeContext } from '@context';
import { CNavGroup, CSidebar, CSidebarNav, CSidebarToggler } from '@coreui/react';
import {
  ChartBarIcon,
  ClockIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  PhoneIcon,
  PhotoIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import { DefaultLayoutService, DefaultLayoutState, SubjectState, ThemeService, ThemeState, useService } from '@services';
import classNames from 'classnames';
import { DefaultAsideProps } from './DefaultLayout.props';

export const DefaultAside = ({ narrow, setNarrow }: DefaultAsideProps): ReactElement => {
  const [, service] = useService<DefaultLayoutState, DefaultLayoutService>(defaultLayoutServiceContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);
  const { pathname } = useLocation();

  const isDarkTheme = themeService.theme.value === 'dark';

  const handleSetNarrow = () => setNarrow(narrow);
  const handleShowConferenceMenu = (value: boolean) => () => {
    service.conferencesMobileMenu.next(value);
    service.investigateMobileMenu.next(false);
  };
  const handleShowInvestigateMenu = (value: boolean) => () => {
    service.investigateMobileMenu.next(value);
    service.conferencesMobileMenu.next(false);
  };

  useEffect(() => {
    const navItemToggler = document.querySelectorAll('.nav-link');
    navItemToggler.forEach(toggler => toggler.classList.toggle('nav-group-toggle', !service.sidebar.value?.narrow));
  }, [service.sidebar.value?.narrow]);

  return (
    <>
      <SubjectState
        subject={service.conferencesMobileMenu}
        render={value =>
          value && narrow ? (
            <div
              onMouseLeave={handleShowConferenceMenu(!value)}
              className='fixed flex flex-col items-center justify-center z-50 left-12 bg-[#2F353A] dark:bg-dark-body top-[106px] w-40'>
              <NavLink className='px-4 py-2.5 hover:bg-sky-400 text-sm dark:text-gray-300' to='/conferences/ongoing'>
                Ongoing
              </NavLink>
              <NavLink className='px-4 w-full hover:bg-sky-400 text-sm dark:text-gray-300' to='/conferences/past'>
                Past
              </NavLink>
            </div>
          ) : (
            <div className='hidden' />
          )
        }
      />

      <SubjectState
        subject={service.investigateMobileMenu}
        render={value =>
          value && narrow ? (
            <div
              onMouseLeave={handleShowInvestigateMenu(!value)}
              className='fixed flex flex-col items-center justify-center z-50 left-12 bg-[#2F353A] dark:bg-dark-body top-[153px] w-40'>
              <NavLink className='w-full px-4 hover:bg-sky-400 text-sm dark:text-gray-300' to='/investigate/failures'>
                Failures
              </NavLink>
            </div>
          ) : (
            <div className='hidden' />
          )
        }
      />

      <SubjectState
        subject={service.sidebar}
        render={({ narrow: narrowS, visible }) => (
          <CSidebar
            id='sidebar'
            visible={visible}
            narrow={narrowS}
            position='fixed'
            style={{
              height: '100%',
              top: '55px',
              width: !narrowS ? '200px' : '50px',
              background: isDarkTheme ? '#181a1b' : '#2F353A',
              borderRight: isDarkTheme ? '1px solid rgb(75, 85, 99)' : '',
            }}>
            <CSidebarNav style={{ fontSize: '14px' }}>
              <NavLink className='flex items-center hover:bg-sky-400 py-3' to='/dashboard'>
                <ClockIcon className={classNames('text-gray-300', !narrowS ? 'w-5 h-5' : 'w-5 h-5')} />
                {!narrow && <span className='ml-2 text-sm text-[#fff] dark:text-gray-300'>Dashboard</span>}
              </NavLink>

              <CNavGroup
                className={`hover:bg-sky-400 ${pathname.includes('conferences') && 'show-nav-items'}`}
                toggler={
                  <div className='flex items-center' onMouseEnter={handleShowConferenceMenu(true)}>
                    <PhoneIcon className={classNames('text-gray-300', !narrowS ? 'w-5 h-5' : 'w-5 h-5')} />
                    {!narrow && <span className='ml-2 text-[#fff] dark:text-gray-300 '>Conferences</span>}
                  </div>
                }>
                <div className='flex flex-col items-center justify-center'>
                  <NavLink to='/conferences/ongoing' className='text-sm hover:bg-sky-400 dark:text-gray-300'>
                    Ongoing
                  </NavLink>
                  <NavLink to='/conferences/past' className='text-sm hover:bg-sky-400 dark:text-gray-300'>
                    Past
                  </NavLink>
                </div>
              </CNavGroup>
              <CNavGroup
                className={`hover:bg-sky-400 ${pathname.includes('investigate') && 'show-nav-items'}`}
                toggler={
                  <div className='flex items-center' onMouseEnter={handleShowInvestigateMenu(true)}>
                    <MagnifyingGlassIcon className={classNames('text-gray-300', !narrowS ? 'w-5 h-5' : 'w-5 h-5')} />
                    {!narrow && <span className='ml-2 text-[#fff] dark:text-gray-300'>Investigate</span>}
                  </div>
                }>
                <div className='flex flex-col items-center justify-center'>
                  <NavLink to='/investigate/failures' className='hover:bg-sky-400 text-sm dark:text-gray-300'>
                    Failures
                  </NavLink>
                </div>
                <div className='flex flex-col items-center justify-center'>
                  <NavLink to='/investigate/feedbacks' className='hover:bg-sky-400 text-sm dark:text-gray-300'>
                    Feedbacks
                  </NavLink>
                </div>
              </CNavGroup>

              <NavLink className='flex items-center hover:bg-sky-400 py-3' to='/ai-detections'>
                <ViewfinderCircleIcon className={classNames('text-gray-300', !narrowS ? 'w-5 h-5' : 'w-5 h-5')} />
                {!narrow && <span className='ml-2 text-sm text-[#fff] dark:text-gray-300'>AI Detections</span>}
              </NavLink>

              <NavLink className='flex items-center hover:bg-sky-400 py-3' to='/frames-da'>
                <PhotoIcon className={classNames('text-gray-300', !narrowS ? 'w-5 h-5' : 'w-5 h-5')} />
                {!narrow && <span className='ml-2 text-sm text-[#fff] dark:text-gray-300'>Frames and DA</span>}
              </NavLink>

              <NavLink className='flex items-center hover:bg-sky-400 py-3' to='/ai-statistics'>
                <ChartBarIcon className={classNames('text-gray-300', !narrowS ? 'w-5 h-5' : 'w-5 h-5')} />
                {!narrow && <span className='ml-2 text-sm text-[#fff] dark:text-gray-300'>AI Statistics</span>}
              </NavLink>

              <NavLink className='flex items-center hover:bg-sky-400 py-3' to='/ai-state'>
                <ListBulletIcon className={classNames('text-gray-300', !narrowS ? 'w-4 h-4' : 'w-5 h-5')} />
                {!narrow && <span className='ml-2 text-sm text-[#fff] dark:text-gray-300'>AI State</span>}
              </NavLink>

              <NavLink className='flex items-center hover:bg-sky-400 py-3' to='/patient-report'>
                <NewspaperIcon className={classNames('text-gray-300', !narrowS ? 'w-4 h-4' : 'w-5 h-5')} />
                {!narrow && <span className='ml-2 text-sm text-[#fff] dark:text-gray-300'>Patient Report</span>}
              </NavLink>
            </CSidebarNav>
            <CSidebarToggler onClick={handleSetNarrow} className='fixed bottom-0' />
          </CSidebar>
        )}
      />
    </>
  );
};
