import { ReactElement } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { EmptyState, Error, Loader } from '@components';
import { EventsContext } from '@context';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { EventModel } from '@interfaces';
import { ErrorState, EventsService, EventsState, EventsSuccessState, LoadingState, useService } from '@services';
import { v4 as uuidv4 } from 'uuid';
import { NewEventsScrollProps } from '../Events.props';
import { NewEventsScrollItem } from './NewEventsScrollItem';

export const NewEventsScroll = ({ showDetails, handleShowDetails }: NewEventsScrollProps): ReactElement => {
  const [state, service] = useService<EventsState, EventsService>(EventsContext);

  const isSuccessState = state instanceof EventsSuccessState;
  const isError = state instanceof ErrorState;
  const isEmptyState = isSuccessState && state.events.events.length === 0;

  const handleSetDetails = (event: EventModel) => () => {
    service.selectedEvent.next(event);
    handleShowDetails();
  };

  const result: EventModel[][] = [[]];
  if (isSuccessState) {
    const { events } = state.events;
    let currentId = events[0]?.participantId;
    let curretnPos = 0;
    for (let i = 0; i < events.length; i += 1) {
      const e = events[i];

      if (e.participantId === currentId) {
        result[curretnPos].push(e);
      } else {
        currentId = e.participantId;
        curretnPos += 1;
        result.push([e]);
      }
    }
  }

  if (state instanceof LoadingState) {
    return <Loader />;
  }

  if (isError) {
    return <Error message={state.errorMessage} />;
  }

  if (isEmptyState) {
    return (
      <EmptyState className='text-center w-full flex items-center justify-center' as='tr'>
        <td className='bg-[#fff] dark:bg-dark' align='center' colSpan={1}>
          <MagnifyingGlassCircleIcon className='text-gray-600 dark:text-gray-400 w-10 h-10' />
          <span className='font-medium dark:text-gray-400'>No events found.</span>
        </td>
      </EmptyState>
    );
  }

  return (
    <>
      {result
        .filter(f => service.participantIds.value.includes(f[0]?.participantId) || !f[0]?.participantId)
        .map(val => {
          const participantName = service.participants.value.find(p => p.id === val[0]?.participantId)?.fullName || '';
          const participantId = service.participants.value.find(p => p.id === val[0]?.participantId)?.id || '';

          return (
            <Card className='dark:bg-dark dark:border-gray-700' id={participantId} key={uuidv4()}>
              {participantName && (
                <CardHeader
                  className='bg-gray-100 dark:bg-dark-body dark:border-gray-700 dark:text-gray-300'
                  style={{
                    fontSize: 14,
                    opacity: 1,
                    fontWeight: 700,
                    position: 'sticky',
                    zIndex: 10,
                    top: 0,
                    boxShadow: `-6px 0px 0px 0px ${service.participantColorCodes.value.find(c => c.id === participantId)?.color}`,
                  }}>
                  {participantName}
                </CardHeader>
              )}

              <CardBody className='divide-y divide-y-gray-200 p-0 z-0'>
                {val
                  .filter(e => service.participantIds.value.includes(e.participantId) || !e.participantId)
                  .map(event => (
                    <>
                      <NewEventsScrollItem
                        key={uuidv4()}
                        service={service}
                        showDetails={showDetails}
                        event={event}
                        handleShowDetails={handleSetDetails(event)}
                      />
                      {!event.participantId && !val.includes(event) && (
                        <NewEventsScrollItem
                          key={uuidv4()}
                          service={service}
                          showDetails={showDetails}
                          event={event}
                          handleShowDetails={handleSetDetails(event)}
                        />
                      )}
                    </>
                  ))}
              </CardBody>
            </Card>
          );
        })}
    </>
  );
};
