import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ParticipantsApi } from '@api';
import { DefaultLayout } from '@containers';
import { PeerConnectionStatsProvider } from '@context';
import { ParticipantModel } from '@interfaces';
import { breadcrumbRoutes } from '@static';
import { PeerConnectionStatsGraphs } from './PeerConnectionStatsGraphs';
import { PeerConnectionStatsSlider } from './PeerConnectionStatsSlider';
import 'rc-slider/assets/index.css';

export const PeerConnectionStatsContainer = (): ReactElement => {
  const [participant, setParticipant] = useState<ParticipantModel>({} as ParticipantModel);
  const participantApi = new ParticipantsApi();

  const { pathname } = useLocation();
  const { participantId, conferenceId } = useParams();

  const isOngoing = pathname.includes('ongoing');

  useEffect(() => {
    const getParticipant = async () => {
      const data = isOngoing
        ? await participantApi.getOngoingConferenceParticipant(participantId || '', conferenceId || '')
        : await participantApi.getConferenceParticipant(participantId || '');

      setParticipant(data);
    };
    getParticipant();
  }, [isOngoing]);

  return (
    <DefaultLayout breadcrumbs={breadcrumbRoutes.conferences}>
      <PeerConnectionStatsProvider isOngoing={isOngoing}>
        <Row>
          <Col xs='12' lg='12'>
            <Row>
              <PeerConnectionStatsSlider participant={participant} />
              <PeerConnectionStatsGraphs participant={participant} />
            </Row>
          </Col>
        </Row>
      </PeerConnectionStatsProvider>
    </DefaultLayout>
  );
};
