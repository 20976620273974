import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { Col, Input, Row } from 'reactstrap';
import { DateRangePicker } from '@components';
import { DashboardContext, ThemeContext } from '@context';
import { DashboardOverviewState, DashboardPreviousState, DashboardService, SubjectState, ThemeService, ThemeState, useService } from '@services';
import moment, { Moment } from 'moment';
import queryString from 'query-string';

export const DashboardFilters = (): ReactElement => {
  const [state, service] = useService<DashboardOverviewState, DashboardService>(DashboardContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();

  const appTypeOptions = [
    { value: '0', label: 'All Applications' },
    { value: '1', label: 'Messenger' },
    { value: '3', label: 'Banyan' },
    { value: '4', label: 'Telemedicine' },
    { value: '6', label: 'Northwell' },
    { value: '7', label: 'Amwell' },
    { value: '999', label: 'Test Banyan' },
  ];

  const callTypeOptions = [
    { label: 'All Call Types', value: '0' },
    { label: 'Audio', value: '1' },
    { label: 'Video', value: '2' },
    { label: 'Screen share', value: '3' },
    { label: 'Security Cam', value: '4' },
    { label: 'Live Broadcast', value: '5' },
    { label: 'Video Monitoring', value: '6' },
    { label: 'Ambient Monitoring', value: '98' },
    { label: 'Ambient and Video Monitoring', value: '97' },
    { label: 'Frontline', value: '7' },
    { label: 'Queueing', value: '99' },
  ];

  const handleApplyRange = (data: { start: Moment; end: Moment }) => {
    service.start.next(data.start.startOf('day'));
    service.end.next(data.end.endOf('day'));
  };
  const handleSetAppType = (values: SingleValue<{ value: string; label: string }>) => {
    const val = Number(values?.value);
    service.appType.next(val === 0 ? 'All' : val);
  };
  const handleSetCallType = (values: SingleValue<{ value: string; label: string }>) => {
    const val = Number(values?.value);
    service.callType.next(val === 0 ? 'All' : val);
  };
  const handleSetCompareToPrevious = (value: boolean) => () => service.previous.next(!value);

  useEffect(() => {
    if (service.callType.value && service.callType.value && service.start.value && service.end.value) {
      navigate({
        search: `${queryString.stringify({
          Start: service.start.value.unix(),
          End: service.end.value.unix(),
          callType: service.callType.value,
          appType: service.appType.value,
        })}`,
      });
    }
  }, [service.start.value, service.end.value, service.appType.value, service.callType.value]);

  return (
    <Row className='text-sm'>
      <Col md='5'>
        <DateRangePicker dateRange={{ start: service.start.value, end: service.end.value }} applyRange={handleApplyRange} />
      </Col>
      <Col className='flex items-center space-x-3'>
        <SubjectState
          subject={service.previous}
          render={value => (
            <Input
              className='mt-0 dark:border-gray-700 dark:checked:bg-sky-500 dark:checked:border-sky-500'
              type='checkbox'
              id='compareToDate'
              name='compareToDate'
              onChange={handleSetCompareToPrevious(value)}
              checked={value}
            />
          )}
        />

        <label htmlFor='compareToDate' className='flex items-center dark:text-gray-400'>
          {state instanceof DashboardPreviousState ? (
            <SubjectState
              subject={service.previous}
              render={value => (
                <p className='flex items-center mb-0'>
                  {value
                    ? `Compare to dates: ${moment.unix(state.start.unix()).format('MM/DD/YYYY')} - ${moment.unix(state.end.unix()).format('MM/DD/YYYY')}`
                    : 'Compare to previous'}
                </p>
              )}
            />
          ) : (
            'Compare to previous'
          )}
        </label>
      </Col>
      <Col>
        <SubjectState
          subject={service.appType}
          render={value => (
            <Select
              styles={
                themeService.theme.value === 'dark'
                  ? {
                      control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                      singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                      menu: styles => ({
                        ...styles,
                        background: '#1e2022',
                        color: '#9ca3af',
                        border: '1px solid #6B7280',
                      }),
                      option: (styles, { isSelected }) => ({
                        ...styles,
                        ':hover': { background: '#374151' },
                        backgroundColor: isSelected ? '#2684FF' : 'transparent',
                      }),
                      input: styles => ({ ...styles, color: themeService.theme.value === 'dark' ? '#9ca3af' : '#000' }),
                    }
                  : {}
              }
              defaultValue={appTypeOptions[0]}
              options={appTypeOptions}
              onChange={handleSetAppType}
              value={appTypeOptions.find(appType => value === appType.value)}
            />
          )}
        />
      </Col>
      <Col>
        <SubjectState
          subject={service.callType}
          render={value => (
            <Select
              styles={
                themeService.theme.value === 'dark'
                  ? {
                      control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                      singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                      menu: styles => ({
                        ...styles,
                        background: '#1e2022',
                        color: '#9ca3af',
                        border: '1px solid #6B7280',
                      }),
                      option: (styles, { isSelected }) => ({
                        ...styles,
                        ':hover': { background: '#374151' },
                        backgroundColor: isSelected ? '#2684FF' : 'transparent',
                      }),
                      input: styles => ({ ...styles, color: themeService.theme.value === 'dark' ? '#9ca3af' : '#000' }),
                    }
                  : {}
              }
              defaultValue={callTypeOptions[0]}
              options={callTypeOptions}
              onChange={handleSetCallType}
              value={callTypeOptions.find(appType => value === appType.value)}
            />
          )}
        />
      </Col>
    </Row>
  );
};
