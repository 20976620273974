import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import { EmptyState, Error, Loader, Pagination } from '@components';
import { ConferenceFeedbacksContext } from '@context';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import { ConferenceFeedbacksService, ConferenceFeedbacksState, ConferenceFeedbacksSuccessState, ErrorState, LoadingState, useService } from '@services';
import { conferenceFeedbacksTableHeaders } from '@static';
import queryString from 'query-string';
import { FeedbacksItem } from './FeedBacksItem';

export const FeedbacksTable = (): ReactElement => {
  const [state, service] = useService<ConferenceFeedbacksState, ConferenceFeedbacksService>(ConferenceFeedbacksContext);

  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = queryString.parse(search);

  const handlePageChange = (_pageSize: number, pageIndex: number) => {
    queryParams.pageIndex = String(pageIndex);
    service.pageIndex.next(pageIndex);
    navigate({
      search: queryString.stringify({ ...queryParams }),
    });
  };

  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;
  const successState = state instanceof ConferenceFeedbacksSuccessState ? state : null;
  const isEmptyState =
    !isLoading && !(state instanceof ErrorState) && state instanceof ConferenceFeedbacksSuccessState && state.feedbacks.conferenceFeedbacks.length === 0;

  return (
    <>
      <Table>
        <thead className='text-sm dark:bg-dark dark:text-[#989082] dark:border-b-gray-500'>
          <tr>
            {conferenceFeedbacksTableHeaders.map(header => (
              <th key={header} className='p-2'>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='dark:bg-dark dark:text-gray-300 dark:border-b-gray-500'>
          {isError && (
            <tr>
              <td colSpan={14}>
                <Error message={state.errorMessage} />
              </td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan={4}>
                <Loader />
              </td>
            </tr>
          )}
          {isEmptyState && (
            <EmptyState className='text-center w-full bg-[#fff] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500' as='tr'>
              <td className='bg-[#fff] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500' colSpan={4} align='center'>
                <NewspaperIcon className='text-gray-600 dark:text-gray-300 w-10 h-10 mx-auto' />
                <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
              </td>
            </EmptyState>
          )}
          {successState?.feedbacks.conferenceFeedbacks.map(feedback => (
            <tr key={feedback.conferenceId} className='text-sm'>
              <FeedbacksItem feedback={feedback} />
            </tr>
          ))}
        </tbody>
      </Table>

      {successState && successState.feedbacks.totalCount > 10 && (
        <Pagination
          maxNumberOfPages={10}
          onChange={handlePageChange}
          pageIndex={service.pageIndex.value}
          pageSize={10}
          totalCount={successState?.feedbacks.totalCount || 0}
        />
      )}
    </>
  );
};
