import { ReactElement } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { EmptyState, Error, GraphStats, Loader } from '@components';
import { PeerConnectionStatsContext } from '@context';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { ErrorState, LoadingState, PeerConnectionStatsService, PeerConnectionStatsState, PeerConnectionStatsSuccessState, useService } from '@services';
import { customDateFormat, getCallDuration } from '@utils';
import moment from 'moment';
import { PeerConnectionStatsProps } from './PeerConnectionStats.props';

export const PeerConnectionStatsGraphs = ({ participant }: PeerConnectionStatsProps): ReactElement => {
  const [state, service] = useService<PeerConnectionStatsState, PeerConnectionStatsService>(PeerConnectionStatsContext);

  const isSuccessState = state instanceof PeerConnectionStatsSuccessState;
  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;

  const joinedTime = customDateFormat(participant.joinTime, 'DD-MMM-YYYY HH:mm:ss');
  const leftTime = customDateFormat(service.isOngoing ? +moment.utc() : participant.leaveTime, 'DD-MMM-YYYY HH:mm:ss');

  return (
    <Card className='dark:bg-dark dark:border-gray-700' style={{ fontSize: '14px', marginTop: '48px', padding: 0 }}>
      <CardHeader className='dark:border-gray-700 dark:text-gray-300'>
        <span>Graphs for this period call time: </span>
        {isSuccessState && (
          <>
            <b>
              {joinedTime} - {leftTime}
            </b>
            <p className='mb-0'>
              Duration: <b>{getCallDuration(participant.joinTime, service.isOngoing ? +moment.utc() : participant.leaveTime)}</b>
            </p>
          </>
        )}
      </CardHeader>
      <CardBody>
        {isError && <Error message={state.errorMessage} />}
        {isLoading && <Loader />}
        {isSuccessState &&
          state.stats.length !== 0 &&
          typeof state.stats !== 'undefined' &&
          state.stats.map(graph => <GraphStats key={graph.name} graph={graph} />)}
        {isSuccessState && state.stats.length === 0 && (
          <EmptyState className='text-center w-full flex justify-center'>
            <div className='bg-[#fff] dark:bg-dark'>
              <ChartBarIcon className='text-gray-600 dark:text-gray-400 w-8 h-8 mx-auto' />
              <span className='font-medium text-sm dark:text-gray-400'>No graphs are found through this interval...</span>
            </div>
          </EmptyState>
        )}
        {!isSuccessState && !isError && !isLoading && (
          <div className='w-full flex items-center justify-center text-gray-500 dark:text-gray-400 text-sm py-2'>
            Change the range above to display graphs...
          </div>
        )}
      </CardBody>
    </Card>
  );
};
