export const pastConferenceTableHeaders = [
  'Conference ID',
  'Initiator',
  'Call Type',
  'Start Time',
  'End Time',
  'Duration',
  'Participants',
  'End Reason',
  'Status',
  'Audio',
  'Video',
  'Screen Sharing',
  'Is Using Media Server',
  'Call Rating',
];

export const ongoingConferenceTableHeaders = ['Conference ID', 'Initiator', 'Call Type', 'Start Time', 'Duration (so far)', 'Participants', 'Force Terminate'];

export const singleOnoingConferenceTableHeaders = ['Conference ID', 'Initiator', 'Call Type', 'Start Time', 'Whiteboard', 'Audio', 'Video', 'Screen Sharing'];

export const singlePastConferenceTableHeaders = [
  'Conference ID',
  'Initiator',
  'Call Type',
  'Start Time',
  'End Time',
  'Duration',
  'End Reason',
  'Status',
  'Whiteboard',
  'Audio',
  'Video',
  'Screen Sharing',
  'Is Using Media Server',
  'Call Rating',
];

export const conferenceFeedbacksTableHeaders = ['Conference ID', 'Participant', 'Comment', 'Rate'];
