import { createContext, useMemo } from 'react';
import { PatientReportApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { PatientReportService } from '@services';

const patientReportApi = new PatientReportApi();

export const PatientReportContext = createContext({} as PatientReportService);

export const PatientReportProvider = ({ children }: ContextProviderProps) => {
  const patientReportService = useMemo(() => new PatientReportService(patientReportApi), []);

  return <PatientReportContext.Provider value={patientReportService}>{children}</PatientReportContext.Provider>;
};
