import { ReactElement, useState } from 'react';
import { Chart, HighchartsChart, HighchartsProvider, Legend, XAxis, YAxis } from 'react-jsx-highcharts';
import { Card, Col, Input, Label, Row } from 'reactstrap';
import { ThemeContext } from '@context';
import { ThemeService, ThemeState, useService } from '@services';
import Highcharts from 'highcharts';
import { GraphStatsProps } from './Graphs.props';
import { GraphSeries } from './GraphSeries';
import { GraphSeriesText } from './GraphSeriesText';

export const GraphStats = ({ graph }: GraphStatsProps): ReactElement => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [, service] = useService<ThemeState, ThemeService>(ThemeContext);

  const isDarkMode = service.theme.value === 'dark';

  const handleSetCollapsed = () => setCollapsed(!collapsed);

  Highcharts.setOptions({
    accessibility: { enabled: false },
    ...(isDarkMode && { legend: { itemStyle: { color: '#6b7280' }, itemHoverStyle: { color: '#9ca3af' } } }),
  });

  return (
    <Row className='py-2 pl-2 pr-1'>
      <Col lg='12' className='border-[1px] dark:border-gray-700 rounded-md'>
        <Label className='relative right-5 flex items-center mb-0 py-1 font-semibold'>
          <Input type='checkbox' onClick={handleSetCollapsed} className='mr-2 mt-0' />
          <p className='mb-0 text-sky-500'>{graph.name}</p>
        </Label>

        {collapsed && (
          <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart tooltip={{ enabled: true, shape: 'square', split: true }}>
              <br />
              <Chart backgroundColor={isDarkMode ? 'transparent' : ''} />
              <Legend layout='horizontal' width='100%' align='left' verticalAlign='bottom' itemDistance={15} itemMarginBottom={10} />
              <XAxis type='datetime' labels={{ format: '{value:%l:%M %p}' }}>
                <XAxis.Title>Time</XAxis.Title>
              </XAxis>
              <YAxis gridLineColor={isDarkMode ? 'gray' : ''}>
                <GraphSeries series={graph.series} />
              </YAxis>
            </HighchartsChart>
            <Card className='p-1 mb-3 dark:bg-transparent dark:border-gray-700'>
              <GraphSeriesText series={graph.series} />
            </Card>
          </HighchartsProvider>
        )}
      </Col>
    </Row>
  );
};
