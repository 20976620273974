import { ReactElement } from 'react';
import { IconProps } from './Icon.props';

export const QuestionMarkIcon = ({ className, ...rest }: IconProps): ReactElement => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 10 10' fill='none' {...rest}>
    <path
      d='M2.91666 3.26169C2.91666 3.01026 2.95104 2.43199 3.71731 1.76672C4.17231 1.37173 4.71872 1.25733 5.20833 1.2503C5.65544 1.24451 6.05505 1.32421 6.29401 1.44691C6.70245 1.6576 7.5 2.17051 7.5 3.26169C7.5 4.40994 6.80343 4.93039 6.01161 5.50389C5.21979 6.07739 5.01736 6.43868 5.01736 7.08333'
      stroke='currentColor'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.00004 9.22916C5.26468 9.22916 5.47921 9.01463 5.47921 8.75C5.47921 8.48536 5.26468 8.27083 5.00004 8.27083C4.7354 8.27083 4.52087 8.48536 4.52087 8.75C4.52087 9.01463 4.7354 9.22916 5.00004 9.22916Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.125'
    />
  </svg>
);
