import { ElementType, ReactElement } from 'react';
import { EmptyStateProps } from './EmptyState.props';

export const EmptyState = <T extends ElementType = 'div'>({ children, as, className, ...rest }: EmptyStateProps<T>): ReactElement => {
  const Component = as || 'div';

  return (
    <Component className={className} {...rest}>
      {children}
    </Component>
  );
};
