import { MouseEvent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Nav, Navbar, NavbarToggler, UncontrolledDropdown } from 'reactstrap';
import { ThemeContext, userContext } from '@context';
import { UserRoles } from '@enums';
import { Bars3Icon, LockClosedIcon, MoonIcon, SunIcon, UserIcon } from '@heroicons/react/24/solid';
import { ThemeService, ThemeState, UserService, UserState, UserSuccessState, useService } from '@services';
import classNames from 'classnames';
import { DefaultHeaderProps } from './DefaultLayout.props';

export const DefaultHeader = ({ onToggle }: DefaultHeaderProps): ReactElement => {
  const [state] = useService<UserState, UserService>(userContext);
  const [, service] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();

  const isDarkTheme = service.theme.value === 'dark';
  const logo = 'https://static.solaborate.com/global/brand/assets/logos/favicon/favicon.svg';
  const userState = state instanceof UserSuccessState ? state : null;

  const handleLogout = () => navigate('/authentication/logout');
  const handleChangeTheme = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    service.changeTheme();
  };

  return (
    <Navbar
      style={{ position: 'fixed' }}
      className={classNames('flex justify-between items-center z-50 w-full py-2 px-2 dark:bg-dark-body bg-[#fff] border-b dark:border-gray-700')}>
      <div className='flex items-center space-x-6'>
        <img src={logo} alt='Logo' className='w-[89px] h-[25px]' />
        <NavbarToggler className='border-0 dark:bg-none focus:!ring-1 focus:ring-black dark:focus:ring-gray-400 px-1' onClick={onToggle}>
          <Bars3Icon className={`w-[30px] h-[30px] ${isDarkTheme ? 'text-gray-50' : 'text-gray-700'}`} />
        </NavbarToggler>
        <div className='flex items-center py-1'>
          <FormGroup switch className='mb-0 ml-2'>
            <Input
              className='cursor-pointer dark:checked:bg-black dark:checked:border-gray-50'
              checked={isDarkTheme}
              type='switch'
              onClick={handleChangeTheme}
            />
          </FormGroup>
          {isDarkTheme ? <MoonIcon className='w-4 h-4 text-yellow-500' /> : <SunIcon className='w-4 h-4 text-gray-700 mr-4' />}
        </div>
      </div>

      <Nav className='pr-5' navbar>
        <UncontrolledDropdown className='bg-transparent '>
          <DropdownToggle className='bg-transparent text-gray-500 border-0 dark:text-gray-400 dark:hover:text-gray-400'>{`${userState?.user?.given_name} ${userState?.user?.family_name}`}</DropdownToggle>
          <DropdownMenu style={{ width: '200px', padding: 0, marginTop: 5 }} className='dark:border-gray-50 dark:border'>
            <DropdownItem
              style={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderStyle: 'solid', borderColor: isDarkTheme ? 'darkgray' : 'lightgray' }}
              className='border-b border-gray-200 border-solid dark:bg-dark dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-400 rounded-t-md'>
              <div className='flex items-center py-1'>
                <UserIcon className='w-4 h-4 text-gray-400 mr-4' />
                <p className='m-0 text-sm'>{`${
                  userState?.user?.role && userState.user?.role.some(x => x === UserRoles.BANYAN_USER) ? 'Banyan' : 'Admin'
                } Role`}</p>
              </div>
            </DropdownItem>
            <DropdownItem
              className='dark:bg-dark dark:text-gray-400 dark:border-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-400 rounded-b-md'
              style={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderStyle: 'solid', borderColor: isDarkTheme ? 'darkgray' : 'lightgray' }}
              onClick={handleLogout}>
              <div className='flex items-center py-1'>
                <LockClosedIcon className='w-4 h-4 text-gray-400 mr-4' />
                <p className='m-0 text-sm'>Logout</p>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};
