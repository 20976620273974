import { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export const NotFound = (): ReactElement => (
  <div>
    <div className='text-center mt-10'>
      <h2>Oops!</h2>
      <h4>Couldn&apos;t find the page you&apos;re looking for.</h4>
    </div>
    <img
      className='border-none block mx-auto w-1/2 mt-[50px] mb-[70px]'
      src='https://static.solaborate.com/scoped/pages/solaborate-callstats/not-found.svg'
      alt='Not found page'
    />
    <NavLink to='/' className='navbar-brand flex justify-center'>
      <Button color='primary'>Go Back Home</Button>
    </NavLink>
  </div>
);
