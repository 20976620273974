export enum QueryParameterNames {
  RETURN_URL = 'returnUrl',
  MESSAGE = 'message',
}

export enum LogoutActions {
  LOGOUT_CALLBACK = 'logout-callback',
  LOGOUT = 'logout',
  LOGGED_OUT = 'logged-out',
}

export enum LoginActions {
  LOGIN = 'login',
  LOGIN_CALLBACK = 'login-callback',
  LOGIN_FAILED = 'login-failed',
  PROFILE = 'profile',
  REGISTER = 'register',
}
