import { createContext, useMemo } from 'react';
import { DataAcquisitionApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { DataAcquisitionService } from '@services';

const dataAcqusitionApi = new DataAcquisitionApi();

export const DataAcquisitionContext = createContext({} as DataAcquisitionService);

export const DataAcquisitionProvider = ({ children }: ContextProviderProps) => {
  const dataAcqusitionService = useMemo(() => new DataAcquisitionService(dataAcqusitionApi), []);

  return <DataAcquisitionContext.Provider value={dataAcqusitionService}>{children}</DataAcquisitionContext.Provider>;
};
