import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { Data } from 'react-csv/components/CommonPropTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import TimeZoneSelect, { ITimezoneOption } from 'react-timezone-select';
import { Card, CardBody, CardHeader, Col, Input, InputGroup, Row, UncontrolledTooltip } from 'reactstrap';
import { CSVExport, DateRangePicker, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, LoaderSpinner, Modal } from '@components';
import { ConferenceContext, ThemeContext, userContext } from '@context';
import { ConferenceSearchType, UserRoles } from '@enums';
import { ArrowDownTrayIcon, ArrowPathIcon, FunnelIcon } from '@heroicons/react/24/solid';
import { SelectValuesProps } from '@interfaces';
import {
  ConferencesService,
  ConferencesState,
  ConferencesSuccessState,
  SubjectState,
  ThemeService,
  ThemeState,
  UserService,
  UserState,
  useService,
} from '@services';
import { appTypeOptions, callTypeOptions, searchBy } from '@static';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Moment } from 'moment';
import queryString from 'query-string';
import { AdditionalFilters } from './AdditionalFilters';

export const ConferencesFilters = (): ReactElement => {
  const [csvData, setCsvData] = useState<Data>([]);
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [slideover, setSlideover] = useState<boolean>(false);
  const [csvError, setCsvError] = useState<string>('');
  const [state, service] = useService<ConferencesState, ConferencesService>(ConferenceContext);
  const [, userService] = useService<UserState, UserService>(userContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const queryParams = queryString.parse(search);

  const ref = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isDarkMode = themeService.theme.value === 'dark';
  const isSuccessState = state instanceof ConferencesSuccessState;
  const isOngoing = pathname.includes('ongoing');

  const handlePushQueryParams = () => {
    navigate({
      search: `${queryString.stringify({
        start: service.start.value.unix(),
        end: service.end.value.unix(),
        appType: service.appType.value === 0 ? 'All' : service.appType.value,
        callType: service.callType.value === 0 ? 'All' : service.callType.value,
        pageIndex: 0,
        pageSize: 10,
        searchVal: service.searchValue.value,
        status: service.status.value,
        searchBy: service.searchBy.value.value,
        endReason: service.endReason.value || 'All',
        organization: service.selectedOrganization.value,
        ...(service.timezone.value && { timezone: service.timezone.value }),
        ...(service.isUsingMediaServer.value !== null ? { isUsingMediaServer: service.isUsingMediaServer.value } : {}),
        ...(service.participantLength.value !== null && service.participantLength.value !== 0 ? { participantLength: service.participantLength.value } : {}),
        ...(service.clientType.value !== 'All' ? { clientType: service.clientType.value } : {}),
      })}`,
    });
  };

  const handleApplyFilters = () => {
    if (service.appType.value === 0 || service.callType.value === 0) {
      service.appType.next('All');
      service.callType.next('All');
    }
    service.pageIndex.next(0);
    handlePushQueryParams();
  };
  const handleShowDropdown = () => service.showDropdown.next(!service.showDropdown.value);
  const handleSetSearchBy = (value: number, label: string) => () => {
    handleShowDropdown();
    service.searchBy.next({ label, value });
  };
  const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (service.searchBy.value.value === 1 || service.searchBy.value.value === 2) {
      service.searchValue.next(value.trim());
    } else {
      service.searchValue.next(value);
    }
  };
  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      handleApplyFilters();
    }
  };

  const handleApplyRange = (data: { start: Moment; end: Moment }) => {
    service.start.next(data.start.startOf('day'));
    service.end.next(data.end.endOf('day'));
  };

  const handleSetAppType = (values: SelectValuesProps) => {
    const val = Number(values?.value);
    service.appType.next(val === 0 ? 'All' : val);
  };

  const handleSetCallType = (values: SelectValuesProps) => {
    const val = Number(values?.value);
    service.callType.next(val === 0 ? 'All' : val);
  };
  const handleSetSelectedOrganization = (values: SingleValue<{ value: string; label: string }>) => {
    service.selectedOrganization.next(values?.value || 'All');
  };

  const handleChangeTimezone = (timezone: ITimezoneOption) => {
    service.timezone.next(timezone.value);
  };

  const handleShowFilters = () => service.showFilters.next(!service.showFilters.value);

  const handleSetModal = () => setIsOpen(!isOpen);
  const handleResetFilters = () => {
    if (ref.current) {
      ref.current.value = '';
    }
    service.resetFilters();
    setIsOpen(!isOpen);
    handlePushQueryParams();
  };

  const getCSVData = async () => {
    setCsvError('');
    setIsCsvLoading(true);
    const pageSize = isSuccessState ? state.conferences.totalCount : 10;
    try {
      const data = await service.getCSVData(pageSize);
      setIsCsvLoading(false);
      return data;
    } catch (error) {
      setIsCsvLoading(false);
      setCsvError((error as AxiosError).message);
      return [];
    }
  };

  useEffect(() => {
    if (queryParams.searchVal && ref.current) {
      ref.current.value = queryParams.searchVal?.toString() || '';
    }
    const getUserOrganizations = async () => {
      const { user } = await userService.getUser();
      if (user.role.some(x => x === UserRoles.BANYAN_USER)) {
        const selectedOrganization = service.organizations.value.find(x => x.name === 'banyan');
        service.selectedOrganization.next(selectedOrganization?.id || 'All');
      }
    };
    getUserOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current && ref.current.value) {
      ref.current.value = '';
    }
  }, [isOngoing]);

  return (
    <>
      <div className='flex items-center justify-between mb-2 text-sm'>
        <button type='button' className='flex space-x-3'>
          <ArrowPathIcon onClick={handleApplyFilters} className='text-[#000] dark:text-gray-300 w-6 h-6' />
          <p className='mb-0 dark:text-gray-400'>{`${state instanceof ConferencesSuccessState ? state.conferences.totalCount : 0} Conference(s)`}</p>
        </button>
        <Row>
          <div className='flex space-x-2'>
            <button
              id='filter'
              type='button'
              onClick={handleShowFilters}
              className='bg-[#20a8d8] dark:bg-sky-900 dark:border-gray-700 dark:border p-2 rounded-lg'>
              <FunnelIcon className='text-white dark: w-5 h-5' />
              <UncontrolledTooltip target='filter'>Filter Conferences</UncontrolledTooltip>
            </button>
            {!isOngoing && (
              <CSVExport
                data={csvData}
                setCsvData={setCsvData}
                disable={isSuccessState ? state.conferences.totalCount === 0 || state.conferences.totalCount > 50000 : false}
                filename={isOngoing ? 'Ongoing_Conferences.csv' : 'Past_Conferences.csv'}
                getCSVData={getCSVData}>
                <button
                  id='csv'
                  type='button'
                  disabled={!(state instanceof ConferencesSuccessState)}
                  className='bg-gray-200 dark:bg-transparent dark:border-gray-700 dark:border p-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed'>
                  {csvError && <p className='mb-0 text-red-500 text-sm'>{csvError}</p>}
                  {isCsvLoading && <LoaderSpinner />}
                  {!isCsvLoading && <ArrowDownTrayIcon className='text-[#20a8d8] w-5 h-5 ' />}
                  <UncontrolledTooltip target='csv'>Export as CSV</UncontrolledTooltip>
                </button>
              </CSVExport>
            )}
          </div>
        </Row>
      </div>
      <SubjectState
        subject={service.showFilters}
        render={showFilters => {
          return (
            <>
              {showFilters && (
                <Card className='dark:bg-dark dark:border dark:border-gray-700'>
                  <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
                    <p className='mb-0'>Conference Filters</p>
                    <Col className='space-x-4 flex justify-end'>
                      <button
                        onClick={handleApplyFilters}
                        type='button'
                        className='bg-[#20a8d8] dark:bg-sky-900 border-[1px] dark:border-gray-700 rounded-lg py-2 px-3 text-[#fff] dark:text-gray-300'>
                        Apply Filters
                      </button>
                      <button
                        onClick={handleSetModal}
                        type='button'
                        className='border-[1px] border-gray-400 dark:border-gray-700 py-2 px-3 rounded-lg dark:text-gray-400'>
                        Reset All
                      </button>
                      {!isOngoing && (
                        <button
                          onClick={() => setSlideover(!slideover)}
                          type='button'
                          className='flex items-center bg-transparent border-[1px] border-gray-400 dark:border-gray-700 rounded-lg py-2 px-2 text-gray-700 dark:text-gray-400'>
                          Additional Filters
                          <FunnelIcon className='text-gray-500 dark:text-gray-400 w-5 h-5 ml-1' />
                        </button>
                      )}
                    </Col>
                  </CardHeader>
                  <CardBody style={{ fontSize: '14px' }}>
                    <Row className='items-center'>
                      <Col md='4'>
                        <InputGroup>
                          <SubjectState
                            subject={service.showDropdown}
                            render={value => (
                              <Dropdown ref={dropdownRef} className='flex items-center w-full' onClose={() => service.showDropdown.next(false)}>
                                <SubjectState
                                  subject={service.searchBy}
                                  render={searchDropdown => (
                                    <>
                                      <DropdownTrigger
                                        className='flex items-center w-[55%] justify-between px-2 border rounded-tl-md rounded-bl-md h-full bg-sky-500'
                                        style={{
                                          background: isDarkMode ? '#1e2022' : '#20a8d8',
                                          color: isDarkMode ? '#d1d5db' : '#fff',
                                          fontWeight: 600,
                                          fontSize: '14px',
                                        }}
                                        viewport={{ once: true }}
                                        whileInView={{ background: isDarkMode ? '#1e2022' : '#20a8d8' }}
                                        initial={{ background: isDarkMode ? '#1e2022' : '#20a8d8' }}
                                        whileHover={{ background: isDarkMode ? '#3e3f3f' : '#3cc1f1', transition: { duration: 0.2, ease: 'easeInOut' } }}
                                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                                        onShow={handleShowDropdown}>
                                        {searchDropdown?.label}
                                      </DropdownTrigger>
                                      <DropdownMenu className='w-[182px]' isOpen={value}>
                                        {searchBy
                                          .filter(item => (item.value !== ConferenceSearchType.DEVICE_SERIAL_NUMBER && isOngoing) || !isOngoing)
                                          .map(item => (
                                            <DropdownItem
                                              key={item.value}
                                              initial={{ background: isDarkMode ? '#1e2022' : '#fff' }}
                                              whileHover={{ background: isDarkMode ? '#3e3f3f' : '#f0f3f5', transition: { duration: 0.2, ease: 'easeInOut' } }}
                                              className='py-2 text-sm dark:text-gray-400 cursor-pointer'
                                              onItemClick={handleSetSearchBy(item.value, item.label)}>
                                              {item.label}
                                            </DropdownItem>
                                          ))}
                                      </DropdownMenu>
                                      <SubjectState
                                        subject={service.searchValue}
                                        render={searchValue => (
                                          <Input
                                            id='search'
                                            className={classNames(
                                              'dark:bg-dark dark:border-gray-700 dark:text-gray-400 !rounded-tl-none !rounded-bl-none',
                                              isDarkMode ? 'search-input' : ''
                                            )}
                                            style={{ fontSize: '14px' }}
                                            onChange={handleSearch}
                                            onKeyDown={handleKeyDown}
                                            type='search'
                                            placeholder={service.searchBy.value?.label}
                                            value={searchValue}
                                            innerRef={ref}
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                />
                              </Dropdown>
                            )}
                          />
                        </InputGroup>
                      </Col>
                      {!isOngoing && (
                        <Col md='3'>
                          <SubjectState
                            subject={service.start || service.end}
                            render={() => <DateRangePicker applyRange={handleApplyRange} dateRange={{ start: service.start.value, end: service.end.value }} />}
                          />
                        </Col>
                      )}

                      <Col md={isOngoing ? '3' : '2'}>
                        <SubjectState
                          subject={service.organizations}
                          render={orgs => {
                            const organizations = orgs.map(x => {
                              return { value: x.id, label: x.name };
                            });
                            organizations.unshift({ value: 'All', label: 'All Organizations' });
                            return (
                              <SubjectState
                                subject={service.selectedOrganization}
                                render={selectedOrg => (
                                  <Select
                                    styles={
                                      isDarkMode
                                        ? {
                                            control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                            singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                            menu: styles => ({
                                              ...styles,
                                              background: '#1e2022',
                                              color: '#9ca3af',
                                              border: '1px solid #6B7280',
                                            }),
                                            option: (styles, { isSelected }) => ({
                                              ...styles,
                                              ':hover': { background: '#374151' },
                                              backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                            }),
                                            input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                          }
                                        : {}
                                    }
                                    defaultValue={organizations[0]}
                                    options={organizations}
                                    isSearchable
                                    value={organizations.find(organization => selectedOrg === organization.value)}
                                    onChange={handleSetSelectedOrganization}
                                  />
                                )}
                              />
                            );
                          }}
                        />
                      </Col>
                      {isOngoing && (
                        <>
                          <Col>
                            <SubjectState
                              subject={service.appType}
                              render={value => (
                                <Select
                                  styles={
                                    isDarkMode
                                      ? {
                                          control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                          singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                          menu: styles => ({
                                            ...styles,
                                            background: '#1e2022',
                                            color: '#9ca3af',
                                            border: '1px solid #6B7280',
                                          }),
                                          option: (styles, { isSelected }) => ({
                                            ...styles,
                                            ':hover': { background: '#374151' },
                                            backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                          }),
                                          input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                        }
                                      : {}
                                  }
                                  defaultValue={appTypeOptions[0]}
                                  options={appTypeOptions}
                                  value={appTypeOptions.find(appType => value === +appType.value)}
                                  onChange={handleSetAppType}
                                />
                              )}
                            />
                          </Col>
                          <Col>
                            <SubjectState
                              subject={service.callType}
                              render={value => (
                                <Select
                                  styles={
                                    isDarkMode
                                      ? {
                                          control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                          singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                          menu: styles => ({
                                            ...styles,
                                            background: '#1e2022',
                                            color: '#9ca3af',
                                            border: '1px solid #6B7280',
                                          }),
                                          option: (styles, { isSelected }) => ({
                                            ...styles,
                                            ':hover': { background: '#374151' },
                                            backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                          }),
                                          input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                        }
                                      : {}
                                  }
                                  defaultValue={callTypeOptions[0]}
                                  options={callTypeOptions}
                                  value={callTypeOptions.find(callType => value === +callType.value)}
                                  onChange={handleSetCallType}
                                />
                              )}
                            />
                          </Col>
                        </>
                      )}
                      {!isOngoing && (
                        <Col md='3'>
                          <SubjectState
                            subject={service.timezone}
                            render={value => (
                              <div className='grid grid-cols-3 items-center'>
                                <TimeZoneSelect
                                  className='col-span-2'
                                  styles={
                                    isDarkMode
                                      ? {
                                          control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                          singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                          menu: styles => ({
                                            ...styles,
                                            background: '#1e2022',
                                            color: '#9ca3af',
                                            border: '1px solid #6B7280',
                                          }),
                                          option: (styles, { isSelected }) => ({
                                            ...styles,
                                            ':hover': { background: '#374151' },
                                            backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                          }),
                                        }
                                      : {}
                                  }
                                  defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                  value={value || Intl.DateTimeFormat().resolvedOptions().timeZone}
                                  onChange={handleChangeTimezone}
                                />
                                <p className='mb-0 ml-4 dark:text-gray-400'>{value}</p>
                              </div>
                            )}
                          />
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )}
            </>
          );
        }}
      />

      <Modal
        isOpen={isOpen}
        isLoading={service.isLoading.value}
        icon={<ArrowPathIcon className='w-10 h-10 text-sky-400 dark:text-sky-600' />}
        closeModal={handleSetModal}
        submitModal={handleResetFilters}
        submitButtonText='Reset Filters'
        closeButtonText='Cancel'
        color='danger'>
        <div className='flex flex-col dark:bg-dark'>
          <p className='text-lg font-semibold mb-0'>Reset Filters</p>
          <p className='mb-0'>Are you sure you want to reset all filters?</p>
        </div>
      </Modal>

      <AdditionalFilters
        service={service}
        slideover={slideover}
        isDarkMode={isDarkMode}
        setSlideover={setSlideover}
        handleApplyFilters={handleApplyFilters}
        isOngoing={isOngoing}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};
