import { EventsApi, ParticipantsApi } from '@api';
import { EventFiltersInputs, EventModel, EventsResponseModel, ParticipantModel } from '@interfaces';
import { AxiosError } from 'axios';
import { BehaviorSubject, combineLatest, debounceTime, from, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

interface ColorCodes {
  id: string;
  color: string;
}

export class EventsState {}

export class EventsSuccessState extends EventsState {
  constructor(events: EventsResponseModel) {
    super();
    this.events = events;
  }

  events: EventsResponseModel;
}

export class EventsService extends Service<EventsState> {
  constructor(eventsApi: EventsApi, participantsApi: ParticipantsApi, isOngoing: boolean, conferenceId: string) {
    super(new LoadingState());

    this.eventsApi = eventsApi;

    this.conferenceId = conferenceId;

    this.participantsApi = participantsApi;

    this.getConferences = isOngoing ? participantsApi.getOngoingConferenceParticipants : participantsApi.getConferenceParticipants;

    this.getConferenceParticipants();
  }

  participantsApi: ParticipantsApi;

  getConferences: (id: string) => Promise<ParticipantModel[]>;

  eventsApi: EventsApi;

  conferenceId: string;

  selectedEvent = new BehaviorSubject<EventModel | null>(null);

  pageSize = new BehaviorSubject<number>(50);

  pageIndex = new BehaviorSubject<number>(0);

  excludeGeneralEvents = new BehaviorSubject<boolean>(false);

  excludeWebRtcEvents = new BehaviorSubject<boolean>(true);

  excludeLifesignalsEvents = new BehaviorSubject<boolean>(true);

  groupByUser = new BehaviorSubject<boolean>(false);

  groupedParticipants = new BehaviorSubject<ParticipantModel[]>([]);

  searchQuery = new BehaviorSubject<string>('');

  participants = new BehaviorSubject<ParticipantModel[]>([]);

  participantIds = new BehaviorSubject<string[]>([]);

  participantColorCodes = new BehaviorSubject<ColorCodes[]>([]);

  timezone = new BehaviorSubject<{ label: string; value: string }>({
    label: Intl.DateTimeFormat().resolvedOptions().timeZoneName || '',
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  private getConferenceParticipants = async () => {
    try {
      const eventParticipants = await this.getConferences(this.conferenceId);
      const filteredParticipants = eventParticipants.filter(e => e.joinTime !== 0).sort((a, b) => a.joinTime - b.joinTime);

      this.participantColorCodes.next(
        eventParticipants.map(ep => {
          // eslint-disable-next-line no-bitwise
          let stringHexNumber = (parseInt(parseInt(ep.id, 36).toExponential().slice(2, -5), 10) & 0xffffff).toString(16).toUpperCase();
          if (stringHexNumber.length !== 6) {
            stringHexNumber = `${stringHexNumber}0`;
          }
          return {
            id: ep.id,
            color: `#${stringHexNumber}`,
          };
        })
      );

      this.participants.next([...filteredParticipants, ...eventParticipants.filter(e => e.joinTime === 0)]);
      this.participantIds.next(this.participants.value.map(p => p.id));

      combineLatest([
        this.pageIndex,
        this.pageSize,
        this.excludeGeneralEvents,
        this.excludeWebRtcEvents,
        this.excludeLifesignalsEvents,
        this.searchQuery,
        this.participantIds,
        this.groupByUser,
      ])
        .pipe(debounceTime(200))
        .pipe(switchMap(this.collect))
        .subscribe((state: EventsState) => this.next(state));
    } catch (error: unknown) {
      this.next(new ErrorState((error as AxiosError).message));
    }
  };

  collect = (): Observable<EventsState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        const events = await this.eventsApi.getConferenceEvents(this.conferenceId, {
          excludeGeneralEvents: this.excludeGeneralEvents.value,
          excludeWebRtcEvents: this.excludeWebRtcEvents.value,
          excludeLifesignalsEvents: this.excludeLifesignalsEvents.value,
          groupByUser: this.groupByUser.value,
          pageIndex: this.pageIndex.value,
          pageSize: this.pageSize.value || 50,
          participantIds: this.participantIds.value,
          searchQuery: this.searchQuery.value,
        });

        return new EventsSuccessState(events);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  applyFilters = (data: EventFiltersInputs) => {
    this.excludeGeneralEvents.next(data.excludeGeneralEvents);
    this.excludeWebRtcEvents.next(data.excludeWebRtcEvents);
    this.excludeLifesignalsEvents.next(data.excludeLifesignalsEvents);
    this.searchQuery.next(data.searchQuery);
    this.groupByUser.next(data.groupByUser);
    this.timezone.next({ label: data.timezone.label, value: data.timezone.value });
  };

  toggleParticipant = (participantId: string) => {
    if (this.participantIds.value.includes(participantId)) {
      this.participantIds.next(this.participantIds.value.filter(p => p !== participantId));
      this.pageIndex.next(0);
    } else {
      const orderedParticpants = this.participants.value.filter(p => this.participantIds.value.includes(p.id) || p.id === participantId).map(p => p.id);
      this.participantIds.next(orderedParticpants);
    }
  };

  toggleAllParticipants = () => {
    if (this.participantIds.value.length > 0) {
      this.participantIds.next([]);
    } else {
      this.participantIds.next(this.participants.value.map(p => p.id));
    }
  };

  getCSVData = async (pageSize: number) => {
    const data = await this.eventsApi.getConferenceEvents(this.conferenceId, {
      excludeGeneralEvents: this.excludeGeneralEvents.value,
      excludeWebRtcEvents: this.excludeWebRtcEvents.value,
      excludeLifesignalsEvents: this.excludeLifesignalsEvents.value,
      groupByUser: this.groupByUser.value,
      pageIndex: this.pageIndex.value,
      pageSize,
      participantIds: this.participantIds.value,
      searchQuery: this.searchQuery.value,
    });

    return data;
  };
}
