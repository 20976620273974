import { ReactElement, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { DatePicker, EmptyState, Loader, Pagination } from '@components';
import { AIStateContext, ThemeContext } from '@context';
import { OrganizationLevels, PatientAiSettingsTypesLables } from '@enums';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { NewspaperIcon } from '@heroicons/react/24/solid';
import { SingleValueProps } from '@interfaces';
import { AIState, AIStateService, AISuccessState, ErrorState, LoadingState, SubjectState, ThemeService, ThemeState, useService } from '@services';
import { getAlertIcon } from '@utils';
import classNames from 'classnames';
import { Moment } from 'moment';
import queryString from 'query-string';

export const AIStatePage = (): ReactElement => {
  const [state, service] = useService<AIState, AIStateService>(AIStateContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);
  const [organiationError, setOrganizationError] = useState<string>('');
  const [healthSystemError, setHealthSystemError] = useState<string>('');

  const isDarkMode = useMemo(() => themeService.theme.value === 'dark', [themeService.theme.value]);
  const navigate = useNavigate();
  const { search } = useLocation();

  const isLoading = state instanceof LoadingState;
  const isSuccessState = state instanceof AISuccessState;
  const isEmptyState = (isSuccessState && state.companyLeveAiSettings?.length === 0) || state instanceof AIState;
  const isError = state instanceof ErrorState;
  const queryParams = queryString.parse(search);

  const handleSetSelectedOrganization = (value: SingleValue<SingleValueProps>) => {
    setOrganizationError('');
    service.selectedHealthSystem.next({ label: 'Select a health system', value: '' });
    service.selectedRoom.next({ label: 'Select a room', value: '' });
    service.healthSystems.next([]);
    service.rooms.next([]);
    service.selectedOrganization.next(value);
  };

  const handleSetSelectedHealthSystem = (value: SingleValue<SingleValueProps>) => {
    setHealthSystemError('');
    service.selectedHealthSystem.next(value);
    service.levelType.next(OrganizationLevels.HEALTHSYSTEM);
    service.levelId.next(value?.value || '');
    service.selectedRoom.next({ label: 'Select a room', value: '' });
  };

  const handleSetSelectedRoom = (value: SingleValue<SingleValueProps>) => {
    service.selectedRoom.next(value);
    service.levelType.next(OrganizationLevels.ROOM);
    service.levelId.next(!value ? service.selectedHealthSystem.value?.value || '' : value.value);
  };

  const handleApplyRange = (data: { start: Moment; end: Moment }) => {
    service.start.next(data.start);
    service.end.next(data.end);
  };

  const handlePageChange = (pageSize: number, pageIndex: number) => {
    service.pageIndex.next(pageIndex);
    service.pageSize.next(pageSize);
    navigate({
      search: `${queryString.stringify({
        ...queryParams,
        pageIndex,
      })}`,
    });
  };

  const handleApplyFilters = () => {
    if (!service.selectedOrganization.value?.value) {
      setOrganizationError('Please select an organization.');
      return;
    }
    if (!service.selectedHealthSystem.value?.value) {
      setHealthSystemError('Please select a health system.');
      return;
    }
    navigate({
      search: `${queryString.stringify({
        levelId: service.selectedRoom.value?.value ? service.selectedRoom.value.value : service.selectedHealthSystem.value?.value,
        levelType: service.selectedRoom.value?.value ? OrganizationLevels.ROOM : OrganizationLevels.HEALTHSYSTEM,
        pageIndex: service.pageIndex.value,
        ...(service.start.value && { start: service.start.value.format() }),
        ...(service.end.value && { end: service.end.value.format() }),
        ...(service.selectedOrganization.value?.value && { companyId: service.selectedOrganization.value.value }),
        ...(service.selectedHealthSystem.value?.value && { healthSystemId: service.selectedHealthSystem.value.value }),
        ...(service.selectedRoom.value?.value && { roomId: service.selectedRoom.value.value }),
      })}`,
    });
    service.refreshCounter.next(1);
    service.pageIndex.next(0);
  };

  return (
    <>
      <Card className='dark:bg-dark dark:border dark:border-gray-700'>
        <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          Filters
        </CardHeader>
        <CardBody style={{ fontSize: '14px' }}>
          <Row className='flex items-center'>
            <Col md='4'>
              <DatePicker theme={themeService.theme.value} applyRange={handleApplyRange} dateRange={{ start: service.start.value, end: service.end.value }} />
            </Col>
            <Col md='2' className='p-1'>
              <SubjectState
                subject={service.organizations}
                render={orgs => {
                  const organizations = orgs.map(x => {
                    return { value: x.id, label: x.name };
                  });
                  return (
                    <SubjectState
                      subject={service.selectedOrganization}
                      render={selectedOrg => {
                        return (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                    input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                  }
                                : {}
                            }
                            defaultValue={service.selectedOrganization.value}
                            options={organizations}
                            isSearchable
                            value={organizations.find(organization => selectedOrg?.value === organization.value)}
                            onChange={handleSetSelectedOrganization}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md='2' className='p-1'>
              <SubjectState
                subject={service.healthSystems}
                render={healthSystems => {
                  const allHealthSystems = [...healthSystems];
                  return (
                    <SubjectState
                      subject={service.selectedHealthSystem}
                      render={healthSystem => {
                        const value = service.queryParams.get('healthSystemId') ? allHealthSystems.find(hs => hs.value === healthSystem?.value) : healthSystem;
                        return (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                    input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                  }
                                : {}
                            }
                            defaultValue={healthSystem}
                            options={allHealthSystems}
                            isSearchable
                            isClearable
                            value={value}
                            onChange={handleSetSelectedHealthSystem}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md='2' className='p-1'>
              <SubjectState
                subject={service.rooms}
                render={rooms => {
                  const allRooms = [...rooms];
                  return (
                    <SubjectState
                      subject={service.selectedRoom}
                      render={room => {
                        const value = service.queryParams.get('roomId') ? allRooms.find(r => r.value === room?.value) : room;
                        return (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                    input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                                  }
                                : {}
                            }
                            defaultValue={room}
                            options={allRooms}
                            isSearchable
                            value={value}
                            isClearable
                            onChange={handleSetSelectedRoom}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md='2' className='flex items-center justify-end p-1'>
              <Button
                type='button'
                onClick={handleApplyFilters}
                disabled={state instanceof LoadingState}
                color={isDarkMode ? '' : 'success'}
                className='w-full text-white text dark:bg-sky-900 dark:border-gray-700 dark:hover:bg-sky-700 dark:hover:border-gray-500'
                style={{ fontSize: '14px' }}>
                Apply
              </Button>
            </Col>
          </Row>
          {(healthSystemError || organiationError) && (
            <Row>
              <Col md='4' />
              <Col md='2'>
                <p className='mb-0 text-red-500'>{organiationError}</p>
              </Col>
              <Col md='2'>
                <p className='mb-0 text-red-500'>{healthSystemError}</p>
              </Col>
              <Col md='2' />
              <Col md='2' />
            </Row>
          )}
        </CardBody>
      </Card>

      <Card className='dark:bg-dark dark:border dark:border-gray-700 mt-10'>
        <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          AI State
        </CardHeader>
        <CardBody className='grid grid-cols-5 grid-flow-row gap-4' style={{ fontSize: '14px' }}>
          {isLoading && <Loader className={classNames({ 'col-span-5': isLoading })} />}
          {isEmptyState && (
            <EmptyState className={classNames('flex flex-col items-center my-auto w-full bg-[#fff] dark:bg-transparent', { 'col-span-5': isEmptyState })}>
              <NewspaperIcon className='text-gray-600 dark:text-gray-400 w-10 h-10 mx-auto' />
              <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
            </EmptyState>
          )}
          {isError && (
            <div className={classNames('text-red-500 flex items-center space-x-2 justify-center', { 'col-span-5': isError })}>
              <ExclamationCircleIcon className='w-5 h-5 ' />
              <p className='mb-0'>{state.errorMessage || 'Something went wrong. Try again later.'}</p>
            </div>
          )}
          {isSuccessState &&
            state.companyLeveAiSettings?.map(aiSetting => {
              const enabledPatientAiSettings = aiSetting.patientAiSettings.filter(setting => setting.isEnabled);
              return (
                <div className='flex flex-col' key={aiSetting.roomId}>
                  <div className='bg-[#313a42] p-2 rounded-t-md border-b border-gray-600'>
                    <p className='text-[#fff] mb-0'>{aiSetting.roomName}</p>
                  </div>
                  <div
                    className={classNames('bg-[#313a42] flex flex-col px-2 py-3 space-y-2 rounded-b-md h-full', {
                      'items-center justify-center': enabledPatientAiSettings.length === 0,
                    })}>
                    {enabledPatientAiSettings.length > 0 &&
                      enabledPatientAiSettings.map(setting => (
                        <div key={setting.id} className='text-[#fffefb] px-2 bg-[#3c444c] p-1 border-[1px] border-transparent rounded-lg'>
                          <div className='flex items-center space-x-1'>
                            {getAlertIcon(setting.settingTypeId)}
                            <p className='mb-0 text-[#fffefb]'>{PatientAiSettingsTypesLables[setting.settingTypeId]}</p>
                          </div>
                        </div>
                      ))}
                    {enabledPatientAiSettings.length === 0 && <div className='text-[#fff] opacity-50'>AI is not turned on for this room</div>}
                  </div>
                </div>
              );
            })}
        </CardBody>
        {isSuccessState && state.pagination.total > 20 && (
          <Pagination
            pageIndex={service.pageIndex.value}
            pageSize={service.pageSize.value}
            maxNumberOfPages={10}
            totalCount={state.pagination.total || 0}
            onChange={handlePageChange}
          />
        )}
      </Card>
    </>
  );
};
