import { ReactElement } from 'react';
import { Button } from 'reactstrap';
import { ReloadButtonProps } from './ReloadButton.props';

export const ReloadButton = ({ description, onClick, className }: ReloadButtonProps): ReactElement => {
  return (
    <Button className={className} color='primary' onClick={onClick}>
      {description}
    </Button>
  );
};
