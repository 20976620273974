import { gatewayApi } from '@config';
import { AIStateApiProps, GetAiStateModel, HealthSystemsModel, RoomModel } from '@interfaces';
import { AxiosResponse } from 'axios';

export class AIStateApi implements AIStateApiProps {
  getAIState = async (companyId: string, levelId: string, params: { pageIndex: number; pageSize?: number }): Promise<GetAiStateModel> => {
    const { data }: AxiosResponse<GetAiStateModel> = await gatewayApi.get(`v1/organizations/${companyId}/levels/${levelId}/ai-settings`, {
      params: {
        'page-index': params.pageIndex,
        'page-size': params.pageSize,
      },
    });

    return data;
  };

  getHealthSystems = async (companyId: string): Promise<HealthSystemsModel> => {
    const { data }: AxiosResponse<HealthSystemsModel> = await gatewayApi.get(`v1/organizations/${companyId}/health-systems?roleId=2`);

    return data;
  };

  getRoomsByLevelId = async (companyId: string, levelId: string, level = 0): Promise<RoomModel> => {
    const { data }: AxiosResponse<RoomModel> = await gatewayApi.get(`v1/organizations/${companyId}/level/${level}/id/${levelId}/rooms`);

    return data;
  };
}
