import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { LoaderSpinner } from '../LoaderSpinner';
import { BadgeProps } from './Badge.props';

export const Badge = ({ children, className, variant, isLoading, isActive = false, ...rest }: BadgeProps): ReactElement => {
  return (
    <motion.div
      whileTap={{
        scale: 0.95,
      }}
      whileHover={{
        cursor: 'pointer',
      }}
      className={classNames('badge-c', variant ? `badge-c-${variant}` : '', isActive ? `badge-c-${variant}-active` : '', className)}
      {...rest}>
      {isLoading ? <LoaderSpinner /> : (children as ReactNode)}
    </motion.div>
  );
};
