import { ReactElement } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { EventHeaderActions } from '../EventHeaderActions';
import { NewEventFilters } from './NewEventFilters';
import { NewEvents } from './NewEvents';

export const NewEventsContainer = (): ReactElement => (
  <Card className='dark:bg-dark dark:border-gray-700'>
    <CardHeader className='dark:bg-dark-body flex items-center justify-between text-sm dark:border-gray-700 dark:text-gray-300'>
      Filters
      <EventHeaderActions />
    </CardHeader>
    <CardBody>
      <NewEventFilters />
      <NewEvents />
    </CardBody>
  </Card>
);
