import moment from 'moment';

export const getCallDuration = (start: number, end: number): string => {
  const seconds = moment(end).diff(moment(start), 'seconds');
  const minutes = moment(end).diff(moment(start), 'minutes');
  const hours = moment(end).diff(moment(start), 'hours');
  const days = moment(end).diff(moment(start), 'days');
  const weeks = moment(end).diff(moment(start), 'weeks');

  return `${weeks ? `${weeks} weeks` : ''} ${days ? `${days % 7} days` : ''} ${hours ? `${hours % 24} hours` : ''}  ${
    minutes ? `${minutes % 60} minutes` : ''
  }  ${seconds ? `${seconds % 60} seconds` : ''} `;
};
