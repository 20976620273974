/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { Loader } from '@components';
import { ApplicationPaths } from '@config';
import { LoginActions, LogoutActions, QueryParameterNames } from '@enums';
import authService, { AuthenticationResultStatus } from '../../services/Authorization/AuthorizationService.service';

export const Login = ({ loginAction, logoutAction }: { loginAction: LoginActions; logoutAction?: LogoutActions }) => {
  const [, setMessage] = useState<string>('');

  const getReturnUrl = (state: { returnUrl: string }) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.RETURN_URL);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }

    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
  };

  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;

    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser he gets sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
  };

  const redirectToProfile = () => redirectToApiAuthorizationPath(ApplicationPaths.IDENTITY_MANAGE_PATH);

  const redirectToRegister = () => {
    redirectToApiAuthorizationPath(`${ApplicationPaths.IDENTITY_REGISTER_PATH}?${QueryParameterNames.RETURN_URL}=${encodeURI(ApplicationPaths.LOGIN)}`);
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.

    window.location.replace(returnUrl);
  };

  const processLoginCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);
    if (result) {
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error(`Invalid authentication result status '${result.status}'.`);
      }
    }
  };

  const login = async (returnUrl: string) => {
    const result = await authService.signIn(returnUrl);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(result.message);
        break;
      default:
        throw new Error(`Invalid status result ${result.status}.`);
    }
  };

  const logout = async (returnUrl: string) => {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const result = await authService.signOut(returnUrl);
      switch (result?.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      setMessage('You successfully logged out!');
    }
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        navigateToReturnUrl(getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  };

  useEffect(() => {
    const storage = window.localStorage;
    let oidcStates = [];

    for (let i = 0; i < storage.length; i += 1) {
      oidcStates.push(storage.key(i));
    }
    if (oidcStates.length >= 1) {
      switch (logoutAction) {
        case LogoutActions.LOGOUT:
          logout(getReturnUrl({ returnUrl: '' }));
          break;
        case LogoutActions.LOGOUT_CALLBACK:
          processLogoutCallback();
          break;
        default:
          throw new Error(`Invalid action '${logoutAction}'`);
      }

      oidcStates = [];
    }
    switch (loginAction) {
      case LoginActions.LOGIN:
        login(getReturnUrl({ returnUrl: '' }));
        break;
      case LoginActions.LOGIN_CALLBACK:
        processLoginCallback();
        break;
      case LoginActions.LOGIN_FAILED:
        // eslint-disable-next-line no-case-declarations
        const params = new URLSearchParams(window.location.search);
        // eslint-disable-next-line no-case-declarations
        const error = params.get(QueryParameterNames.MESSAGE);
        setMessage(error || '');
        break;
      case LoginActions.PROFILE:
        redirectToProfile();
        break;
      case LoginActions.REGISTER:
        redirectToRegister();
        break;
      default:
        throw new Error(`Invalid action '${loginAction}'`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (loginAction) {
    case LoginActions.LOGIN:
      return <Loader className='absolute top-[40%] left-[48%]' />;
    case LoginActions.LOGIN_CALLBACK:
      return <Loader className='absolute top-[40%] left-[48%]' />;
    case LoginActions.PROFILE:
    case LoginActions.REGISTER:
      return <div />;
    default:
      throw new Error(`Invalid action '${loginAction}'`);
  }
};
