import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Login, Logout, NotFound } from '@components';
import { ApplicationPaths } from '@config';
import { DefaultLayout } from '@containers';
import { AIDetectionsProvider, AIStateProvider, AIStatisticsProvider, DataAcquisitionProvider, FailuresDetailsProvider, PatientReportProvider } from '@context';
import { LoginActions, LogoutActions, UserRoles } from '@enums';
import {
  AIDetections,
  AIStatePage,
  AIStatistics,
  ConferenceDetailsContainer,
  Dashboard,
  DataAcquisition,
  Failures,
  FailuresDetails,
  Feedbacks,
  PatientReport,
  PeerConnectionStatsContainer,
  SingleConferenceContainer,
  SingleParticipantContainer,
} from '@views-pages';
import { AuthRoute } from './Auth/AuthRoute';

export const DefaultRoutes = (): ReactElement => (
  <Routes>
    <Route path={ApplicationPaths.API_AUTHORIZATION_PREFIX}>
      <Route path={ApplicationPaths.LOGIN} element={<Login loginAction={LoginActions.LOGIN} logoutAction={LogoutActions.LOGOUT} />} />;
      <Route path={ApplicationPaths.LOGIN_FAILED} element={<Login loginAction={LoginActions.LOGIN_FAILED} />} />
      <Route
        path={ApplicationPaths.LOGIN_CALLBACK}
        element={<Login loginAction={LoginActions.LOGIN_CALLBACK} logoutAction={LogoutActions.LOGOUT_CALLBACK} />}
      />
      <Route path={ApplicationPaths.LOGOUT} element={<Logout logoutAction={LogoutActions.LOGOUT} />} />
      <Route path={ApplicationPaths.LOGOUT_CALLBACK} element={<Logout logoutAction={LogoutActions.LOGOUT_CALLBACK} />} />
      <Route path={ApplicationPaths.LOGGED_OUT} element={<Logout logoutAction={LogoutActions.LOGGED_OUT} />} />
    </Route>

    <Route element={<AuthRoute roles={[UserRoles.ADMIN, UserRoles.BANYAN_USER]} />}>
      <Route path='/' element={<Navigate to='/dashboard' />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/conferences' element={<Navigate to='/conferences/past' />} />
      <Route path='/conferences/*'>
        <Route path='ongoing' element={<ConferenceDetailsContainer />} />
        <Route path='ongoing/:conferenceId' element={<SingleConferenceContainer />} />
        <Route path='ongoing/:conferenceId/participants/:participantId' element={<SingleParticipantContainer />} />
        <Route path='ongoing/:conferenceId/participants/:participantId/peers/:peerId/all-stats' element={<PeerConnectionStatsContainer />} />
        <Route path='past' element={<ConferenceDetailsContainer />} />
        <Route path='past/:conferenceId' element={<SingleConferenceContainer />} />
        <Route path='past/:conferenceId/participants/:participantId' element={<SingleParticipantContainer />} />
        <Route path='past/:conferenceId/participants/:participantId/peers/:peerId/all-stats' element={<PeerConnectionStatsContainer />} />
      </Route>
      <Route path='/investigate/*'>
        <Route path='failures' element={<Failures />} />
        <Route
          path='failures/details'
          element={
            <FailuresDetailsProvider>
              <FailuresDetails />
            </FailuresDetailsProvider>
          }
        />
        <Route path='feedbacks' element={<Feedbacks />} />
      </Route>
      <Route
        path='/ai-detections'
        element={
          <AIDetectionsProvider>
            <AIDetections />
          </AIDetectionsProvider>
        }
      />
      <Route
        path='/frames-da'
        element={
          <DataAcquisitionProvider>
            <DefaultLayout>
              <DataAcquisition />
            </DefaultLayout>
          </DataAcquisitionProvider>
        }
      />

      <Route
        path='/patient-report'
        element={
          <PatientReportProvider>
            <DefaultLayout>
              <PatientReport />
            </DefaultLayout>
          </PatientReportProvider>
        }
      />

      <Route
        path='/ai-statistics'
        element={
          <AIStatisticsProvider>
            <DefaultLayout>
              <AIStatistics />
            </DefaultLayout>
          </AIStatisticsProvider>
        }
      />

      <Route
        path='/ai-state'
        element={
          <AIStateProvider>
            <DefaultLayout>
              <AIStatePage />
            </DefaultLayout>
          </AIStateProvider>
        }
      />

      <Route path='*' element={<NotFound />} />
    </Route>
  </Routes>
);
