import { ClientType, ConferenceEndReasonLabels, ConferenceSearchType, ConferenceStatusLabels } from '@enums';
import moment from 'moment';
import { v4 } from 'uuid';

export const now = new Date();
export const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

export const searchBy = [
  { value: ConferenceSearchType.CONFERENCE_ID, label: 'Conference Id' },
  { value: ConferenceSearchType.PARTICIPANT_NAME, label: 'Participant Name' },
  { value: ConferenceSearchType.DEVICE_SERIAL_NUMBER, label: 'Device Serial Number' },
  { value: ConferenceSearchType.OBJECT_ID, label: 'Object ID' },
];

export const appTypeOptions = [
  { value: '0', label: 'All Applications' },
  { value: '1', label: 'Messenger' },
  { value: '3', label: 'Banyan' },
  { value: '4', label: 'Telemedicine' },
  { value: '6', label: 'Northwell' },
  { value: '7', label: 'Amwell' },
  { value: '999', label: 'Test Banyan' },
];

export const callTypeOptions = [
  { label: 'All Call Types', value: '0' },
  { label: 'Audio', value: '1' },
  { label: 'Video', value: '2' },
  { label: 'Screen share', value: '3' },
  { label: 'Security Cam', value: '4' },
  { label: 'Live Broadcast', value: '5' },
  { label: 'Video Monitoring', value: '6' },
  { label: 'Ambient Monitoring', value: '98' },
  { label: 'Ambient and Video Monitoring', value: '97' },
  { label: 'Frontline', value: '7' },
  { label: 'Queueing', value: '99' },
];

export const conferenceStatus = Object.entries(ConferenceStatusLabels).map(([key, value]) => {
  return { value: key, label: value };
});

export const ratingOptions = [
  { value: 'All', label: 'All Ratings' },
  { value: '1', label: 'Poor' },
  { value: '2', label: 'Below Average' },
  { value: '3', label: 'Average' },
  { value: '4', label: 'Above Average' },
  { value: '5', label: 'Excellent' },
];

export const clientTypeOptions = [
  { value: ClientType.ALL, label: 'All' },
  { value: ClientType.WEB, label: 'Web' },
  { value: ClientType.ANDROID, label: 'Android' },
  { value: ClientType.IOS, label: 'iOS' },
  { value: ClientType.HELLO, label: 'HELLO' },
];

export const isUsingMediaServerOptions = [
  { value: null, lable: 'None' },
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

export const endReasonOptions = [
  { value: ConferenceEndReasonLabels.UNDEFINED, label: 'All' },
  { value: ConferenceEndReasonLabels.PARITICPANT_OFFLINE, label: 'Participant offline' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_BUSY, label: 'Participant busy' },
  { value: ConferenceEndReasonLabels.INITIATOR_LEFT, label: 'Initiator left' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_NOT_ANSWERING, label: 'Participant not answering' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_DECLINED, label: 'Participant declined' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_LEFT, label: 'Participant left' },
  { value: ConferenceEndReasonLabels.OWNER_LEFT, label: 'Owner left' },
  { value: ConferenceEndReasonLabels.ABORTED, label: 'Aborted' },
  { value: ConferenceEndReasonLabels.DROPPED, label: 'Dropped' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_PASSWORD_CHANGED, label: 'Participant password changed' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_IDLE, label: 'Participant idle' },
  { value: ConferenceEndReasonLabels.TERMINATED_BY_ADMINISTRATOR, label: 'Terminated by administrator' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_INVITE_DENIED, label: 'Participant invite denied' },
  { value: ConferenceEndReasonLabels.PARTICIPANT_INVITE_FAILED, label: 'Participant invite failed' },
  { value: ConferenceEndReasonLabels.INITIATOR_BUSY, label: 'Initator busy' },
  { value: ConferenceEndReasonLabels.MAINTENANCE_WORKER_CLEANUP, label: 'Maintenance worker cleanup' },
];

export const getCustomRanges = () => {
  return [
    {
      id: v4(),
      label: 'Today',
      value: {
        start: moment(startOfDay).utc(true).startOf('day').format(),
        end: moment(startOfDay).utc(true).endOf('day').format(),
      },
    },
    {
      id: v4(),
      label: 'Yesterday',
      value: {
        start: moment(startOfDay).utc(true).startOf('day').subtract(1, 'day').format(),
        end: moment(startOfDay).utc(true).endOf('day').subtract(1, 'day').format(),
      },
    },
    {
      id: v4(),
      label: 'Last 7 Days',
      value: {
        start: moment(startOfDay).utc(true).startOf('day').subtract(6, 'day').format(),
        end: moment(startOfDay).utc(true).endOf('day').format(),
      },
    },
    {
      id: v4(),
      label: 'Last 30 Days',
      value: {
        start: moment(startOfDay).utc(true).startOf('day').subtract(29, 'day').format(),
        end: moment(startOfDay).utc(true).endOf('day').format(),
      },
    },
    {
      id: v4(),
      label: 'Last 90 Days',
      value: {
        start: moment(startOfDay).utc(true).startOf('day').subtract(89, 'day').format(),
        end: moment(startOfDay).utc(true).endOf('day').format(),
      },
    },
    {
      id: v4(),
      label: 'Last Month',
      value: {
        start: moment(startOfDay).utc(true).startOf('month').subtract(1, 'M').format(),
        end: moment(startOfDay).utc(true).endOf('month').subtract(1, 'M').format(),
      },
    },
    {
      id: v4(),
      label: 'Last Year',
      value: {
        start: moment(startOfDay).utc(true).startOf('year').subtract(1, 'year').format(),
        end: moment(startOfDay).utc(true).endOf('year').subtract(1, 'year').format(),
      },
    },
  ];
};
