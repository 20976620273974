import { gatewayApi } from '@config';
import { DashboardApiProps, DashboardAppType, DashboardCallType, DashboardDuration, DashboardFeedback, DashboardOverview } from '@interfaces';
import { AxiosResponse } from 'axios';
import { Moment } from 'moment';

export class DashboardApi implements DashboardApiProps {
  getOngoingCount = async (): Promise<{ count: number }> => {
    const { data }: AxiosResponse<{ count: number }> = await gatewayApi.get('v1/callstats/ongoing/count');

    return data;
  };

  async getConferences(start: Moment, end: Moment, appType: number | 'All' = 'All', callType: number | 'All' = 'All'): Promise<DashboardOverview> {
    const { data }: AxiosResponse<DashboardOverview> = await gatewayApi.get('v1/callstats/past/count', {
      params: { start: start.unix(), end: end.unix(), appType, callType },
    });

    return data;
  }

  async getDuration(start: Moment, end: Moment, appType: number | 'All' = 'All'): Promise<DashboardDuration> {
    const { data }: AxiosResponse<DashboardDuration> = await gatewayApi.get('v1/callstats/duration', {
      params: { start: start.unix(), end: end.unix(), appType },
    });

    return data;
  }

  async getCallsByPartner(start: Moment, end: Moment): Promise<DashboardAppType> {
    const { data }: AxiosResponse<DashboardAppType> = await gatewayApi.get('v1/callstats/counters/app-type', {
      params: { start: start.unix(), end: end.unix() },
    });

    return data;
  }

  async getCallsByType(start: Moment, end: Moment, callType: number | 'All' = 'All'): Promise<DashboardCallType> {
    const { data }: AxiosResponse<DashboardCallType> = await gatewayApi.get('v1/callstats/counters/call-type', {
      params: { start: start.unix(), end: end.unix(), callType },
    });

    return data;
  }

  async getConferenceFeedbacks(start: Moment, end: Moment, callType: number | 'All', appType: number | 'All'): Promise<DashboardFeedback> {
    const { data }: AxiosResponse<{ data: { rate: number; count: number }[] }> = await gatewayApi.get('v1/callstats/feedback', {
      params: {
        start: start.unix(),
        end: end.unix(),
        callType,
        appType,
      },
    });

    const ret: { [key: string]: number } = {};
    for (let i = 1; i <= 5; i += 1) {
      ret[i] = data.data.find(r => r.rate === i)?.count ?? 0;
    }

    // FOR FUTURE REFERENCE IF WE WANT PERCENTAGE
    // const sum = Object.values(ret).reduce((p, c) => p + c, 0);
    // for (let i = 1; i <= 5; i += 1) {
    //   ret[i] = (ret[i] * 100) / sum;
    // }

    return ret;
  }
}
