import { FailuresApiProps, FailuresProps } from '@interfaces';
import { startOfDay } from '@static';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, debounceTime, from, merge, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../../Helpers/Error.service';
import { LoadingState } from '../../Helpers/Loading.service';
import { Service } from '../../Service';

export class FailuresState {}

export class FailuresSuccessState extends FailuresState {
  constructor(failures: FailuresProps) {
    super();
    this.failures = failures;
  }

  failures: FailuresProps;
}

export class FailuresService extends Service<FailuresState> {
  constructor(failuresApi: FailuresApiProps) {
    super(new LoadingState());
    this.failuresApi = failuresApi;

    merge(this.start, this.end, this.pageIndex)
      .pipe(debounceTime(200))
      .pipe(switchMap(this.collect))
      .subscribe(state => this.next(state));
  }

  private collect = (): Observable<FailuresState> => {
    this.next(new LoadingState());

    const getState = async () => {
      try {
        const state = await this.getFailures();
        return new FailuresSuccessState(state.failures);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  failuresApi: FailuresApiProps;

  start = new BehaviorSubject<Moment>(moment(startOfDay).subtract(7, 'days'));

  end = new BehaviorSubject<Moment>(moment(startOfDay).add(1, 'days').subtract(1, 'seconds'));

  pageIndex = new BehaviorSubject<number>(0);

  pageSize = new BehaviorSubject<number>(10);

  private getFailures = async () => {
    const failuresData = await this.failuresApi.getFailures(
      this.start.getValue(),
      this.end.getValue(),
      this.pageSize.getValue() || 10,
      this.pageIndex.getValue() || 0
    );

    return new FailuresSuccessState(failuresData);
  };
}
