import { ReactElement } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import classNames from 'classnames';
import { NewEventsScrollParticipantsProps } from '../Events.props';

export const NewEventsScrollParticipant = ({
  participant: { id, fullName, device },
  color,
  handleShowParticipant,
  checked,
}: NewEventsScrollParticipantsProps): ReactElement => {
  const participant = document.getElementById(id);

  const goToParticipant = () => {
    participant?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Col onClick={goToParticipant} className={classNames(participant && 'cursor-pointer active:translate-y-1 active:shadow-md')}>
      <Card className='dark:bg-dark-body dark:border-gray-700' style={{ borderStyle: 'solid' }}>
        <div className='w-8 h-12 absolute right-2 rounded-t-md' style={{ boxShadow: `10px 0px 0px 0px ${color}` }} />
        <CardBody className='text-sm'>
          <div className='flex flex-col dark:text-gray-300'>
            <span className='flex items-center justify-between'>
              <div>
                <strong>Participant: </strong>
                {fullName}
              </div>
              <input type='checkbox' className='absolute right-5 z-10' onClick={e => e.stopPropagation()} onChange={handleShowParticipant} checked={checked} />
            </span>
            <div className='flex flex-col'>
              <span className='mt-1'>
                <strong>ID: </strong>
                {id}
              </span>
              <span className='mt-1'>
                <strong>Device: </strong>
                {device}
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
