import { ReactElement } from 'react';
import { IconProps } from './Icon.props';

export const RailsIcon = ({ className, ...rest }: IconProps): ReactElement => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 16 16' fill='none' {...rest}>
    <path
      d='M4.66665 7.33333H3.33331V11.3333H4.66665V9.99999H11.3333V11.3333H12.6666V7.33333H11.3333V8.66666H4.66665V7.33333Z'
      stroke='white'
      strokeLinejoin='round'
    />
    <rect x='1.33331' y='6' width='13.3333' height='1.33333' stroke='white' strokeLinejoin='round' />
    <path
      d='M4 13.3333C4.55228 13.3333 5 12.8856 5 12.3333C5 11.781 4.55228 11.3333 4 11.3333C3.44772 11.3333 3 11.781 3 12.3333C3 12.8856 3.44772 13.3333 4 13.3333Z'
      stroke='white'
      strokeMiterlimit='10'
    />
    <path
      d='M12 13.3333C12.5523 13.3333 13 12.8856 13 12.3333C13 11.781 12.5523 11.3333 12 11.3333C11.4477 11.3333 11 11.781 11 12.3333C11 12.8856 11.4477 13.3333 12 13.3333Z'
      stroke='white'
      strokeMiterlimit='10'
    />
    <path
      d='M1.33332 5.99999V5.99999C0.965133 5.99999 0.666656 5.70152 0.666656 5.33333V4.33333C0.666656 3.78104 1.11437 3.33333 1.66666 3.33333H2.25244C2.51766 3.33333 2.77201 3.43869 2.95955 3.62622L3.7071 4.37377C3.89463 4.5613 4.14899 4.66666 4.4142 4.66666H14.6667C15.0348 4.66666 15.3333 4.96514 15.3333 5.33333V5.33333C15.3333 5.70152 15.0348 5.99999 14.6667 5.99999V5.99999'
      stroke='white'
    />
  </svg>
);
