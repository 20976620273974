import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Error, Pagination } from '@components';
import { EventsContext } from '@context';
import { ErrorState, EventsService, EventsState, EventsSuccessState, SubjectsState, useService } from '@services';
import classNames from 'classnames';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { EventDetails } from '../EventDetails';
import { NewEventsScroll } from './NewEventsScroll';
import { NewEventsScrollParticipant } from './NewEventsScrollParticipant';

export const NewEvents = (): ReactElement => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [details, setDetails] = useState<string>('');
  const [state, service] = useService<EventsState, EventsService>(EventsContext);

  const { search } = useLocation();
  const navigate = useNavigate();

  const successState = state instanceof EventsSuccessState ? state : null;
  const isError = state instanceof ErrorState;
  const queryParams = queryString.parse(search);

  const parseJson = () => {
    try {
      const json = JSON.stringify(
        JSON.parse(successState?.events.events.find(item => item.timestamp === service.selectedEvent.value?.timestamp)?.detail || ''),
        null,
        2
      );

      if (!json) {
        return 'No details found.';
      }

      return json;
    } catch (error: unknown) {
      return `Unexpected error occurred: ${(error as { message: string }).message}`;
    }
  };

  const handlePageChange = (_pageSize: number, pageIndex: number) => {
    queryParams.pageIndex = String(pageIndex);
    service.pageIndex.next(pageIndex);
    navigate({
      search: queryString.stringify({ ...queryParams }),
    });
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
    const detailsJSON = parseJson();
    setDetails(detailsJSON);
  };

  const handleShowParticipant = (participantId: string) => () => service.toggleParticipant(participantId);

  return (
    <>
      <div className='flex my-16 mx-auto max-w-7xl space-x-8'>
        <div className='space-y-2 w-full sticky top-16 h-full overflow-y-scroll max-h-[600px]'>
          {isError && <Error message={state.errorMessage} />}
          {service.participants.value.map(scrollParticipant => {
            return (
              <SubjectsState
                key={uuidv4()}
                subjects={[service.participantIds, service.participants]}
                render={() => (
                  <NewEventsScrollParticipant
                    checked={service.participantIds.value.includes(scrollParticipant.id)}
                    handleShowParticipant={handleShowParticipant(scrollParticipant.id)}
                    participant={scrollParticipant}
                    color={service.participantColorCodes.value.find(p => p.id === scrollParticipant.id)?.color}
                  />
                )}
              />
            );
          })}
        </div>

        <div
          className={classNames(
            'space-y-3 w-full overflow-y-auto max-h-[600px] px-2',
            successState?.events.events.length === 0 ? 'flex items-center justify-center' : ''
          )}>
          <NewEventsScroll showDetails={showDetails} handleShowDetails={handleShowDetails} />
        </div>
      </div>

      {(successState?.events.total || 0) > service.pageSize.value && (
        <Pagination
          totalCount={successState?.events.total || 0}
          pageIndex={service.pageIndex.value || 0}
          pageSize={service.pageSize.value || 50}
          maxNumberOfPages={10}
          onChange={handlePageChange}
        />
      )}

      <EventDetails
        details={details}
        showDetails={showDetails}
        handleToggleDetails={handleShowDetails}
        eventKey={service.selectedEvent.value?.eventKey || ''}
      />
    </>
  );
};
