import { FailuresApi } from '@api';
import { FailuresDetailsProps } from '@interfaces';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, debounceTime, from, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../../Helpers/Error.service';
import { LoadingState } from '../../Helpers/Loading.service';
import { Service } from '../../Service';

export class FailuresDetailsState {}

export class FailuresDetailsSuccessState extends FailuresDetailsState {
  constructor(failuresDetails: FailuresDetailsProps) {
    super();
    this.failuresDetails = failuresDetails;
  }

  failuresDetails: FailuresDetailsProps;
}

export class FailuresDetailsService extends Service<FailuresDetailsState> {
  constructor(failuresApi: FailuresApi) {
    super(new LoadingState());

    this.failuresApi = failuresApi;

    combineLatest([this.start, this.end, this.method, this.message])
      .pipe(debounceTime(200))
      .pipe(switchMap(this.collect))
      .subscribe(state => this.next(state));
  }

  private collect = (): Observable<FailuresDetailsState> => {
    this.next(new LoadingState());

    const getState = async () => {
      try {
        if (this.method.getValue() || this.message.getValue()) {
          const state = await this.getFailuresDetails();
          return new FailuresDetailsSuccessState(state.failuresDetails);
        }
        return new FailuresDetailsState();
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  start = new BehaviorSubject<Moment>(moment());

  end = new BehaviorSubject<Moment>(moment());

  method = new BehaviorSubject<string>('');

  message = new BehaviorSubject<string>('');

  failuresApi: FailuresApi;

  getFailuresDetails = async () => {
    const failuresDetailsData = await this.failuresApi.getFailuresDetails(
      this.start.getValue(),
      this.end.getValue(),
      this.message.getValue(),
      this.method.getValue()
    );

    return new FailuresDetailsSuccessState(failuresDetailsData);
  };
}
