/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactElement, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ApplicationPaths } from '@config';
import { QueryParameterNames, UserRoles } from '@enums';
import authService from '../../services/Authorization/AuthorizationService.service';
import { AuthRouteProps } from './Auth.props';

export const AuthRoute = ({ roles }: AuthRouteProps): ReactElement => {
  const [{ isReady, access, isAuthenticated }, setState] = useState<{ isReady: boolean; isAuthenticated: boolean; access: boolean }>({
    isReady: false,
    isAuthenticated: false,
    access: false,
  });

  const redirectUrl = `${ApplicationPaths.LOGIN}?${QueryParameterNames.RETURN_URL}=${encodeURI(window.location.href)}`;

  const canAccessRoute = async () => {
    const user = await authService.getUser();
    let routeAcess = false;
    if (user && user.role) {
      if (typeof user.role === 'string') {
        routeAcess = user.role === UserRoles.ADMIN || user.role === roles;
      } else {
        routeAcess = user.role.some((r: UserRoles) => roles.includes(r));
      }
    }

    return routeAcess;
  };
  const populateAuthenticationState = async () => {
    const authenticated = await authService.isAuthenticated();
    const routeAcess = await canAccessRoute();
    setState({ isReady: true, isAuthenticated: authenticated, access: routeAcess });
  };

  const authenticationChanged = async () => {
    setState({ isReady: false, isAuthenticated: false, access: false });
    await populateAuthenticationState();
  };

  useEffect(() => {
    const subscription = authService.subscribe(() => authenticationChanged());
    populateAuthenticationState();

    return () => {
      if (subscription) {
        authService.unsubscribe(subscription);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) {
    return <div />;
  }

  return isAuthenticated && access ? <Outlet /> : <Navigate to={redirectUrl} />;
};
