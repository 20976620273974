import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { DefaultLayout } from '@containers';
import { ConferencesProvider } from '@context';
import { breadcrumbRoutes } from '@static';
import { ConferenceDetails } from './ConferencesDetails';
import { ConferencesFilters } from './ConferencesFilters';

export const ConferenceDetailsContainer = (): ReactElement => {
  const { pathname } = useLocation();

  const isOngoing = pathname.includes('ongoing');

  return (
    <ConferencesProvider isOngoing={isOngoing}>
      <DefaultLayout breadcrumbs={breadcrumbRoutes.conferences}>
        <Card className='dark:bg-dark dark:border dark:border-gray-700'>
          <CardBody>
            <ConferencesFilters />
            <ConferenceDetails />
          </CardBody>
        </Card>
      </DefaultLayout>
    </ConferencesProvider>
  );
};
