import { gatewayApi } from '@config';
import { DataAcquisitionModel, DataAcquisitionProps } from '@interfaces';
import { AxiosResponse } from 'axios';

export class DataAcquisitionApi implements DataAcquisitionProps {
  getStats = async (tenantId: string): Promise<DataAcquisitionModel> => {
    const { data }: AxiosResponse<DataAcquisitionModel> = await gatewayApi.get(`v1/frames/stats/${tenantId}`);

    return data;
  };

  getDataAcquisitionStats = async (tenantId: string): Promise<DataAcquisitionModel> => {
    const { data }: AxiosResponse<DataAcquisitionModel> = await gatewayApi.get(`v1/frames/daStats/${tenantId}`);

    return data;
  };
}
