import { gatewayApi } from '@config';
import { CallStatsApiProps, CallStatsParams, CallStatsResponseModel } from '@interfaces';
import { AxiosResponse } from 'axios';

export class CallStatsApi implements CallStatsApiProps {
  getStatsByTime = async ({ conferenceId, endTime, participantId, peerId, startTime }: CallStatsParams): Promise<CallStatsResponseModel[]> => {
    const { data }: AxiosResponse<CallStatsResponseModel[]> = await gatewayApi.get(
      `v1/callstats/conferences/${conferenceId}/participants/${participantId}/peer/${peerId}/metrics/${startTime}-${endTime}`
    );

    return data;
  };
}
