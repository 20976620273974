import { ReactElement } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ErrorProps } from './Error.props';

export const Error = ({ message, icon }: ErrorProps): ReactElement => (
  <div className='flex justify-center items-center dark:bg-dark'>
    {icon || <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:text-red-400' />}
    <p className='mb-0 ml-1 dark:text-red-200'>{message}</p>
  </div>
);
