import { ParsedQuery } from 'query-string';
import { BehaviorSubject } from 'rxjs';
import { Service } from '../Service';

export class DefaultLayoutState {
  constructor(narrow: boolean, visible: boolean, conferencesMobileMenu: boolean, investigateMobileMenu: boolean) {
    this.narrow = narrow;
    this.visible = visible;
    this.conferencesMobileMenu = conferencesMobileMenu;
    this.investigateMobileMenu = investigateMobileMenu;
  }

  narrow: boolean;

  visible: boolean;

  conferencesMobileMenu: boolean;

  investigateMobileMenu: boolean;
}

export class DefaultLayoutService extends Service<DefaultLayoutState> {
  constructor() {
    super({ conferencesMobileMenu: false, investigateMobileMenu: false, narrow: false, visible: true });
  }

  sidebar = new BehaviorSubject<{ narrow: boolean; visible: boolean }>({ narrow: false, visible: true });

  conferencesMobileMenu = new BehaviorSubject<boolean>(false);

  investigateMobileMenu = new BehaviorSubject<boolean>(false);

  params = new BehaviorSubject<ParsedQuery<string>>({});
}
