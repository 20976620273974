import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { DateRangePicker } from '@components';
import { ConferenceFeedbacksContext, ThemeContext } from '@context';
import { ConferenceFeedbacksService, ConferenceFeedbacksState, SubjectState, ThemeService, ThemeState, useService } from '@services';
import { ratingOptions } from '@static';
import moment from 'moment';
import queryString from 'query-string';
import { FeedbacksFiltersInputs } from './Feedbacks.props';

export const FeedbacksFilters = (): ReactElement => {
  const [, service] = useService<ConferenceFeedbacksState, ConferenceFeedbacksService>(ConferenceFeedbacksContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  const { register, handleSubmit, setValue } = useForm<FeedbacksFiltersInputs>({
    mode: 'onChange',
    defaultValues: {
      rating: { label: service.rate.value.label || 'All Ratings', value: service.rate.value.value || 'All' },
    },
  });

  const onSubmit = (data: FeedbacksFiltersInputs) => {
    service.conferenceId.next(data.conferenceId);
    service.pageIndex.next(0);
    navigate({
      search: `${queryString.stringify({
        start: data.dateRange.start.unix(),
        end: data.dateRange.end.unix(),
        conferenceId: data.conferenceId,
        rate: data.rating.value,
        pageIndex: service.pageIndex.value || 0,
      })}`,
    });
  };

  useEffect(() => {
    if (queryParams.start || queryParams.end || queryParams.rate || queryParams.conferenceId) {
      service.conferenceId.next(queryParams.conferenceId?.toString() || '');
      service.start.next(moment(Number(queryParams.start?.toString()) * 1000));
      service.end.next(moment(Number(queryParams.end?.toString()) * 1000));
      service.rate.next({
        label: ratingOptions.find(rating => rating.value === (queryParams.rate?.toString() || ''))?.label || '',
        value: queryParams.rate?.toString() || 'All',
      });
      setValue('conferenceId', service.conferenceId.value);
      setValue('rating', { value: queryParams.rate?.toString() || '', label: ratingOptions.find(r => r.value === queryParams.rate?.toString())?.label || '' });
      setValue('dateRange', { start: service.start.value, end: service.end.value });
    }
  }, [queryParams.start, queryParams.end, queryParams.rate, queryParams.conferenceId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className='flex items-center mt-2 mb-4 flex-events text-sm px-3 last:items-end' md='12'>
        <Col md='6' className='flex justify-between items-end space-x-10'>
          <div className='flex flex-col w-full dark:text-gray-400'>
            <Label>Date Range</Label>
            <SubjectState
              subject={service.start || service.end}
              render={() => (
                <DateRangePicker
                  dateRange={{ start: service.start.value, end: service.end.value }}
                  applyRange={data => {
                    service.start.next(data.start);
                    service.end.next(data.end);
                    setValue('dateRange', data);
                  }}
                />
              )}
            />
          </div>
        </Col>

        <Col md='3'>
          <Label className='dark:text-gray-400'>Conference ID</Label>
          <SubjectState
            subject={service.conferenceId}
            render={val => (
              <input
                id='conferneceId'
                className='px-3 py-2 border-[1px] rounded-lg w-full focus:outline-gray-200 dark:bg-dark dark:text-gray-400 dark:border-gray-700 dark:autofill:bg-dark'
                placeholder='Confernce ID'
                defaultValue={val}
                {...register('conferenceId')}
              />
            )}
          />
        </Col>

        <Col md='2'>
          <Label className='dark:text-gray-400'>Rating</Label>
          <SubjectState
            subject={service.rate}
            render={rate => (
              <Select
                styles={
                  themeService.theme.value === 'dark'
                    ? {
                        control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                        singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                        menu: styles => ({
                          ...styles,
                          background: '#1e2022',
                          color: '#9ca3af',
                          border: '1px solid #6B7280',
                        }),
                        option: (styles, { isSelected }) => ({
                          ...styles,
                          ':hover': { background: '#374151' },
                          backgroundColor: isSelected ? '#2684FF' : 'transparent',
                        }),
                        input: styles => ({ ...styles, color: themeService.theme.value === 'dark' ? '#9ca3af' : '#000' }),
                      }
                    : {}
                }
                defaultValue={rate ?? ratingOptions[0]}
                options={ratingOptions}
                value={ratingOptions.find(r => rate.value === r.value)}
                onChange={val => {
                  setValue('rating', { label: val?.label || '', value: val?.value || 'All' });
                  service.rate.next({ label: val?.label || '', value: val?.value || 'All' });
                }}
              />
            )}
          />
        </Col>
        <Col>
          <button
            type='submit'
            className='bg-[#20a8d8] dark:bg-sky-900 border-[1px] rounded-lg py-2 px-3 text-white dark:border-gray-700 dark:hover:bg-sky-800'>
            Apply
          </button>
        </Col>
      </Row>
    </form>
  );
};
