import { ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownMenuProps } from './Dropdown.props';

export const DropdownMenu = ({ isOpen, children, className, ...rest }: DropdownMenuProps): ReactElement => {
  const menu = {
    closed: {
      scale: 0,
      transition: {
        delay: 0.15,
      },
    },
    open: {
      scale: 1,
      transition: {
        type: 'spring',
        duration: 0.4,
        delayChildren: 0.1,
        staggerChildren: 0.05,
      },
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={classNames(
            'overflow-auto mt-1 bg-[#fff] dark:bg-dark absolute top-10 left-0 z-10 w-full rounded-md max-h-[150px] border border-gray-500 divide-gray-400 dark:border-gray-500 divide-y dark:divide-gray-500',
            className
          )}
          key='dropdown-menu'
          animate={isOpen ? 'open' : 'closed'}
          initial='closed'
          exit='closed'
          variants={menu}
          {...rest}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
