import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { EmptyState, Loader } from '@components';
import { DataAcquisitionContext, ThemeContext } from '@context';
import { DataAcquisitionLabels } from '@enums';
import { ExclamationCircleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import {
  DataAcquisitionService,
  DataAcquisitionState,
  DataAcquisitionSuccessState,
  ErrorState,
  LoadingState,
  SubjectState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { ChartOptions } from '@views-pages';
import { ChartData, PieControllerChartOptions } from 'chart.js';
import queryString from 'query-string';

export const DataAcquisition = () => {
  const [state, service] = useService<DataAcquisitionState, DataAcquisitionService>(DataAcquisitionContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();

  const isErrorState = state instanceof ErrorState;
  const isLoadingState = state instanceof LoadingState;
  const isSuccessState = state instanceof DataAcquisitionSuccessState;
  const isEmptyState =
    (isSuccessState && Object.values(state.dataAcquisitionStats).length === 0 && Object.values(state.frames).length === 0) ||
    state instanceof DataAcquisitionState;
  const successState = isSuccessState ? state : null;
  const isDarkMode = themeService.theme.value === 'dark';

  const handleSetSelectedOrganization = (newValue: SingleValue<{ label: string; value: string }>) => {
    if (!newValue?.value) {
      return;
    }
    service.selectedOrganization.next(newValue);
    navigate({
      search: queryString.stringify({
        tenantId: newValue?.value || '',
      }),
    });
  };

  const options: ChartOptions<'pie', PieControllerChartOptions> = {
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          generateLabels: chart => {
            const { data } = chart;
            if (data.labels?.length && data.datasets.length) {
              return data.labels?.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i, true);
                return {
                  text: `${label}: ${data.datasets[0].data[i]}`,
                  fillStyle: style.backgroundColor as string,
                  strokeStyle: style.borderColor as string,
                  lineWidth: style.borderWidth as number,
                  ...(themeService.theme.value === 'dark' && {
                    fontColor: '#d1d5db',
                  }),
                  hidden: !chart.getDataVisibility(i),
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
    },
    responsive: true,
  };

  const framesData: ChartData<'pie'> = {
    labels: Object.keys(successState?.frames || {}).map(key => DataAcquisitionLabels[key as keyof typeof DataAcquisitionLabels]),
    datasets: [
      {
        label: 'Frames',
        data: Object.values(successState?.frames || {}),
        borderWidth: 1,
        backgroundColor: ['#0ea5e9', '#fde047', '#10b981', '#f43f5e', '#f97316'],
      },
    ],
  };

  const dataAcquisitionData: ChartData<'pie'> = {
    labels: Object.keys(successState?.dataAcquisitionStats || {}).map(key => DataAcquisitionLabels[key as keyof typeof DataAcquisitionLabels]),
    datasets: [
      {
        label: 'Data Acquisition',
        data: Object.values(successState?.dataAcquisitionStats || {}),
        borderWidth: 1,
        backgroundColor: ['#0ea5e9', '#fde047', '#10b981', '#f43f5e', '#f97316'],
      },
    ],
  };

  const totalFramesData = Object.values(successState?.frames || {}).reduce((prev, curr) => prev + curr, 0);
  const totalDatAcquisitionData = Object.values(successState?.dataAcquisitionStats || {}).reduce((prev, curr) => prev + curr, 0);

  return (
    <div>
      <Card className='dark:bg-dark dark:border dark:border-gray-700'>
        <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
          Frames and Data Acquisition
        </CardHeader>
        <CardBody style={{ fontSize: 14 }}>
          <SubjectState
            subject={service.organizations}
            render={orgs => {
              const organizations = orgs.map(x => {
                return { value: x.id, label: x.name };
              });
              organizations.unshift({ value: '', label: 'Select an organization' });
              return (
                <SubjectState
                  subject={service.selectedOrganization}
                  render={selectedOrg => (
                    <Select
                      className='w-1/4'
                      styles={
                        isDarkMode
                          ? {
                              control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                              singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                              menu: styles => ({
                                ...styles,
                                background: '#1e2022',
                                color: '#9ca3af',
                                border: '1px solid #6B7280',
                              }),
                              option: (styles, { isSelected }) => ({
                                ...styles,
                                ':hover': { background: '#374151' },
                                backgroundColor: isSelected ? '#2684FF' : 'transparent',
                              }),
                              input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                            }
                          : {}
                      }
                      defaultValue={organizations[0]}
                      options={organizations}
                      isSearchable
                      value={organizations.find(organization => selectedOrg?.value === organization.value)}
                      onChange={handleSetSelectedOrganization}
                    />
                  )}
                />
              );
            }}
          />
          {isEmptyState && (
            <EmptyState className='text-center w-full bg-[#fff] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500 my-10'>
              <div className='bg-[#fff] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500'>
                <NewspaperIcon className='text-gray-600 dark:text-gray-300 w-10 h-10 mx-auto' />
                <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
              </div>
            </EmptyState>
          )}
          {isErrorState && (
            <div className='text-red-500 flex items-center space-x-2 justify-center my-10'>
              <ExclamationCircleIcon className='w-5 h-5 ' />
              <p className='mb-0'>{state.errorMessage || 'Something went wrong. Try again later.'}</p>
            </div>
          )}
          {isLoadingState && <Loader />}

          {isSuccessState && (
            <div className='my-10 flex items-center space-x-10 justify-center'>
              <Card className='dark:bg-dark dark:border dark:border-gray-700'>
                <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
                  Frames - {totalFramesData}
                </CardHeader>
                <CardBody>
                  <Pie data={framesData} options={options} width={500} />
                </CardBody>
              </Card>
              <Card className='dark:bg-dark dark:border dark:border-gray-700'>
                <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
                  Data Acquisition - {totalDatAcquisitionData}
                </CardHeader>
                <CardBody>
                  <Pie data={dataAcquisitionData} options={options} width={500} />
                </CardBody>
              </Card>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
