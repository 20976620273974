import { createContext, ReactElement, useMemo } from 'react';
import { AIStateApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { AIStateService } from '@services';

const aiStateAPi = new AIStateApi();

export const AIStateContext = createContext({} as AIStateService);

export const AIStateProvider = ({ children }: ContextProviderProps): ReactElement => {
  const aiStateService = useMemo(() => new AIStateService(aiStateAPi), []);
  return <AIStateContext.Provider value={aiStateService}>{children}</AIStateContext.Provider>;
};
