import { gatewayApi, signalingApi } from '@config';
import { ParticipantAndPeerProps, ParticipantModel, ParticipantsApiProps, PeerParticipantModel, PeerProps } from '@interfaces';
import { AxiosResponse } from 'axios';

export class ParticipantsApi implements ParticipantsApiProps {
  getConferenceParticipants = async (conferenceId: string): Promise<ParticipantModel[]> => {
    const { data }: AxiosResponse<{ participants: ParticipantModel[] }> = await gatewayApi.get(`v1/conferences/${conferenceId}/participants`);

    return data.participants;
  };

  getConferenceParticipant = async (participantId: string): Promise<ParticipantModel> => {
    const { data }: AxiosResponse<{ participant: ParticipantModel }> = await gatewayApi.get(`v1/participants/${participantId}`);

    return data.participant;
  };

  getParticipantPeers = async (participantId: string, conferenceId: string): Promise<PeerProps> => {
    const { data }: AxiosResponse<PeerProps> = await gatewayApi.get(`v1/participants/${participantId}/peers?conferenceId=${conferenceId}`);

    return data;
  };

  getParticipantsIcePairs = async (participantId: string, remoteParticipantId: string, conferenceId: string): Promise<PeerProps> => {
    const { data }: AxiosResponse<PeerProps> = await gatewayApi.get(
      `v1/participants/${participantId}/icecandidates/${remoteParticipantId}?conferenceId=${conferenceId}`
    );

    return data;
  };

  getOngoingConferenceParticipants = async (conferenceId: string): Promise<ParticipantModel[]> => {
    const { data }: AxiosResponse<{ participants: ParticipantModel[] }> = await gatewayApi.get(`v1/conferences/ongoing/${conferenceId}/participants`);

    return data.participants;
  };

  getOngoingConferenceParticipant = async (participantId: string, conferenceId: string): Promise<ParticipantModel> => {
    const { data }: AxiosResponse<ParticipantModel> = await gatewayApi.get(`v1/conferences/ongoing/${conferenceId}/participants/${participantId}`);

    return data;
  };

  getOngoingConferenceParticipantAndPeerName = async (
    conferenceId: string,
    participantId: string,
    peerParticipantId: string
  ): Promise<ParticipantAndPeerProps> => {
    const { data }: AxiosResponse<ParticipantAndPeerProps> = await gatewayApi.get(
      `v1/conferences/ongoing/${conferenceId}/participants/${participantId}/peers/${peerParticipantId}/names`
    );

    return data;
  };

  getParticipantAndPeerName = async (participantId: string, remoteParticipantId: string): Promise<ParticipantAndPeerProps> => {
    const { data }: AxiosResponse<ParticipantAndPeerProps> = await gatewayApi.get(`v1/participants/${participantId}/peers/${remoteParticipantId}/names`);

    return data;
  };

  getPeerParticipantModel = async (participantId: string, remoteParticipantId: string): Promise<PeerParticipantModel> => {
    const { data }: AxiosResponse<PeerParticipantModel> = await gatewayApi.get(`v1/participants/${participantId}/peers/${remoteParticipantId}/details`);

    return data;
  };

  getParticipantAndPeerDetails = async (participantId: string, remoteParticipantId: string): Promise<ParticipantAndPeerProps> => {
    const { data } = await gatewayApi.get(`v1/participants/${participantId}/peers/${remoteParticipantId}/details`);

    return data;
  };

  terminateParticipantCall = async (participantId: string): Promise<boolean> => {
    const { data }: AxiosResponse<boolean> = await signalingApi.post(`conferences/participants/${participantId}/terminate`);

    return data;
  };
}
