import { ReactElement, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { DateRangePicker, Error, Loader } from '@components';
import { failuresContext, failuresTimelineContext, ThemeContext } from '@context';
import {
  ErrorState,
  FailuresService,
  FailuresState,
  FailuresTimelineService,
  FailuresTimelineState,
  FailuresTimelineSuccessState,
  LoadingState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { Chart, Filler, LinearScale, LineControllerChartOptions, LineElement, PointElement, TooltipItem } from 'chart.js';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { ChartOptions } from '../../Dashboard/Dashboard.props';

Chart.register(LinearScale, PointElement, LineElement, Filler);

export const FailuresGraph = (): ReactElement => {
  const [state, service] = useService<FailuresTimelineState, FailuresTimelineService>(failuresTimelineContext);
  const [, fService] = useService<FailuresState, FailuresService>(failuresContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();

  const successState = state instanceof FailuresTimelineSuccessState ? state : null;
  const isDarkTheme = themeService.theme.value === 'dark';

  const getChartTitle = (tooltipItems: TooltipItem<'line'>[]) => {
    const test = successState?.failuresTimeline?.data.find((failure, index) => tooltipItems.find(item => item.dataIndex === index) && failure);
    return `Date: ${moment(test?.dateString).format('DD-MM-YYYY HH:mm')}`;
  };

  const options: ChartOptions<'line', LineControllerChartOptions> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        ...(isDarkTheme && { labels: { color: '#9ca3af' } }),
      },
      tooltip: {
        callbacks: {
          title: getChartTitle,
        },
      },
    },
    scales: { xAxes: { ticks: { color: isDarkTheme ? '#9ca3af' : 'black' } }, yAxes: { ticks: { color: isDarkTheme ? '#9ca3af' : 'black' } } },
  };

  const labels = successState?.failuresTimeline?.data.map(failures => moment(failures.date).format('HH:mm'));
  const datasets = [
    {
      fill: true,
      label: 'Failures',
      data: successState?.failuresTimeline?.data.map(failures => failures.count),
      borderColor: 'rgb(245, 40, 40)',
      backgroundColor: 'rgba(245, 40, 40, 0.5)',
    },
  ];
  const data = {
    labels,
    datasets,
  };

  const handleApplyRange = (dateRange: { start: Moment; end: Moment }) => {
    service.start.next(dateRange.start.startOf('day'));
    service.end.next(dateRange.end.endOf('day'));
    fService.start.next(dateRange.start.startOf('day'));
    fService.end.next(dateRange.end.endOf('day'));
  };

  useEffect(() => {
    if (service.start.getValue() && service.end.getValue()) {
      navigate({
        search: `${queryString.stringify({
          Start: service.start.getValue().unix(),
          End: service.end.getValue().unix(),
        })}`,
      });
    }
  }, [service.start.value, service.end.value]);

  if (state instanceof ErrorState) {
    return <Error message={state.errorMessage} />;
  }

  return (
    <Card className='dark:bg-dark dark:border dark:border-gray-700'>
      <CardHeader className='dark:bg-dark-body text-sm dark:text-gray-300 dark:border-b-gray-500'>Conference Reported Errors: </CardHeader>
      <CardBody>
        <Row>
          <Col md='5'>
            <DateRangePicker dateRange={{ start: service.start.getValue(), end: service.end.getValue() }} applyRange={handleApplyRange} />
          </Col>
        </Row>
        <Row>
          <Col>{state instanceof LoadingState ? <Loader /> : <Line data={data} options={options} height={70} />}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};
