import { ForwardedRef, forwardRef } from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';

export const ForwardedDiv = forwardRef(({ children, ...rest }: HTMLMotionProps<'div'>, ref: ForwardedRef<HTMLDivElement>) => (
  <motion.div ref={ref} {...rest}>
    {children}
  </motion.div>
));

ForwardedDiv.displayName = 'ForwardedDiv';
