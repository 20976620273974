import { createContext, ReactElement, useMemo } from 'react';
import { AIStatisticsApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { AIStatisticsService } from '@services';

const aiStatisticsApi = new AIStatisticsApi();

export const AIStatisticsContext = createContext({} as AIStatisticsService);

export const AIStatisticsProvider = ({ children }: ContextProviderProps): ReactElement => {
  const aiStatisticsServce = useMemo(() => new AIStatisticsService(aiStatisticsApi), []);

  return <AIStatisticsContext.Provider value={aiStatisticsServce}>{children}</AIStatisticsContext.Provider>;
};
