import { createContext, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EventsApi, ParticipantsApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { EventsService } from '@services';

const eventsApi = new EventsApi();

const participantsApi = new ParticipantsApi();

export const EventsContext = createContext<EventsService>({} as EventsService);

export const EventsProvider = ({ children, isOngoing }: ContextProviderProps): ReactElement => {
  const { conferenceId } = useParams();

  const eventsService = useMemo(() => new EventsService(eventsApi, participantsApi, isOngoing ?? false, conferenceId ?? ''), [isOngoing]);

  return <EventsContext.Provider value={eventsService}>{children}</EventsContext.Provider>;
};
