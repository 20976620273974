import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Table } from 'reactstrap';
import { EmptyState, Error, Loader, Pagination } from '@components';
import { failuresContext, paginationContext } from '@context';
import { ErrorState, FailuresService, FailuresState, FailuresSuccessState, LoadingState, PaginationService, PaginationState, useService } from '@services';
import queryString from 'query-string';

export const FailuresTable = (): ReactElement => {
  const [state, service] = useService<FailuresState, FailuresService>(failuresContext);
  const [, paginationService] = useService<PaginationState, PaginationService>(paginationContext);
  const navigate = useNavigate();
  const { search } = useLocation();

  const successState = state instanceof FailuresSuccessState ? state : null;
  const queryParams = queryString.parse(search);
  const errorTableHeaders = ['Method', 'Message', 'COUNT'];
  const maxNumberOfPages = Math.ceil((successState?.failures.total || 0) / 10);
  const isLoading = state instanceof LoadingState;

  const handleGoToDetails = (method: string, message: string) => () => {
    navigate({
      pathname: '/investigate/failures/details',
      search: `${queryString.stringify({
        Start: service.start.getValue().unix(),
        End: service.end.getValue().unix(),
        Method: method,
        Message: message,
      })}`,
    });
  };

  const handlePageChange = (pageSize: number, pageIndex: number) => {
    queryParams.pageIndex = String(pageIndex);
    paginationService.pageIndex.next(pageIndex);
    service.pageIndex.next(pageIndex);
    service.pageSize.next(pageSize);
    navigate({
      search: queryString.stringify({ ...queryParams }),
    });
  };

  useEffect(() => {
    paginationService.totalCount.next(successState?.failures?.total || 0);
  }, [successState, paginationService.totalCount.value, paginationService.totalCount]);

  if (state instanceof ErrorState) {
    return <Error message={state.errorMessage} />;
  }

  return (
    <Card className='dark:bg-dark dark:border dark:border-gray-700'>
      <Table responsive className={successState?.failures.data.length === 0 ? 'mb-0' : ''}>
        <thead className='bg-[#eef5f8] dark:bg-dark dark:text-gray-300 dark:border-b-gray-500 rounded-md'>
          <tr className='text-sm'>
            {errorTableHeaders.map((header, index) => (
              <th className={`fw-semibold ${index === errorTableHeaders.length - 1 ? 'text-center' : ''}`} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className={isLoading ? 'h-[410px]  w-full ' : 'dark:bg-dark dark:text-gray-300 dark:border-b-gray-500'}>
          {isLoading && (
            <tr>
              <td colSpan={12}>
                <Loader className='absolute left-[45%] top-[35%]' />
              </td>
            </tr>
          )}
          {successState?.failures?.data.length === 0 && (
            <EmptyState className='text-center' as='tr'>
              <td className='bg-[#fff] dark:bg-dark dark:text-gray-300' colSpan={12}>
                No data.
              </td>
            </EmptyState>
          )}
          {!isLoading &&
            successState?.failures?.data.map(({ count, method, message }) => (
              <tr className='cursor-pointer text-sm rounded-md dark:hover:bg-dark-body' key={message} onClick={handleGoToDetails(method, message)}>
                <td>{method}</td>
                <td>{message}</td>
                <td className='text-center'>{count}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {successState?.failures.data.length !== 0 && (
        <Pagination
          totalCount={successState?.failures?.total || 0}
          pageIndex={paginationService.pageIndex.getValue()}
          pageSize={paginationService.pageSize.getValue()}
          maxNumberOfPages={maxNumberOfPages}
          onChange={handlePageChange}
        />
      )}
    </Card>
  );
};
