import { createContext, ReactElement, useMemo } from 'react';
import { AIDetectionsApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { AIDetectionsService } from '@services';

const aiDetectionsApi = new AIDetectionsApi();

export const AIDetectionsContext = createContext<AIDetectionsService>({} as AIDetectionsService);

export const AIDetectionsProvider = ({ children }: ContextProviderProps): ReactElement => {
  const aiDetectionsService = useMemo(() => new AIDetectionsService(aiDetectionsApi), []);

  return <AIDetectionsContext.Provider value={aiDetectionsService}>{children}</AIDetectionsContext.Provider>;
};
