import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import authService from '../services/Authorization/AuthorizationService.service';

export const APP_CONFIG = {
  URL: {
    gatewayApiBasePath: '',
    signalingUrl: '',
    blobStorageUrl: '',
  },
};

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  APP_CONFIG.URL.gatewayApiBasePath = process.env.REACT_APP_GATEWAY_API_BASE_URL || '';
  APP_CONFIG.URL.signalingUrl = process.env.REACT_APP_SIGNALING_URL || '';
  APP_CONFIG.URL.blobStorageUrl = process.env.REACT_APP_BLOB_STORAGE_URL || '';
} else {
  APP_CONFIG.URL.gatewayApiBasePath = window.__env__.REACT_APP_GATEWAY_API_BASE_URL;
  APP_CONFIG.URL.signalingUrl = window.__env__.REACT_APP_SIGNALING_URL;
  APP_CONFIG.URL.blobStorageUrl = window.__env__.REACT_APP_BLOB_STORAGE_URL;
}

const opts = {
  baseURL: APP_CONFIG.URL.gatewayApiBasePath,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const signalingOpts = {
  ...opts,
  baseURL: APP_CONFIG.URL.signalingUrl,
};

export const gatewayApi = axios.create(opts);
export const signalingApi = axios.create(signalingOpts);

const successHandler = (response: AxiosResponse): Promise<AxiosResponse> => {
  return new Promise(resolve => {
    resolve(response);
  });
};
const errorHandler = async (error: AxiosError) => {
  if (!error.response) {
    return Promise.reject(error);
  }
  if (error.response.status !== 401) {
    return Promise.reject(error);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await authService.signIn();

  return Promise.reject(error);
};

const addAuth = async (request: AxiosRequestConfig) => {
  const { headers } = request;
  headers.Authorization = `Bearer ${await authService.getAccessToken()}`;
  return request;
};

gatewayApi.interceptors.request.use(request => addAuth(request));
signalingApi.interceptors.request.use(request => addAuth(request));

gatewayApi.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
signalingApi.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
