import { ReactElement } from 'react';
import { Bar } from 'react-chartjs-2';
import { Loader } from '@components';
import { DashboardContext, ThemeContext } from '@context';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  DashboardOverviewState,
  DashboardPreviousState,
  DashboardService,
  DashboardSuccessState,
  ErrorState,
  SubjectState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { ChartOptions } from '@views-pages';
import { BarControllerChartOptions, BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';

Chart.register(LinearScale, CategoryScale, BarElement);

export const Conferences = (): ReactElement => {
  const [state, service] = useService<DashboardOverviewState, DashboardService>(DashboardContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  if (state instanceof ErrorState) {
    return (
      <div className='flex justify-center items-center'>
        <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:text-red-400' />
        <p className='mb-0 ml-1 dark:text-gray-400'>{state.errorMessage}</p>
      </div>
    );
  }

  if (!(state instanceof DashboardSuccessState)) {
    return <Loader />;
  }

  const isDarkTheme = themeService.theme.value === 'dark';

  const options: ChartOptions<'bar', BarControllerChartOptions> = {
    color: isDarkTheme ? '#9ca3af' : 'black',

    scales: {
      xAxes: {
        ticks: { color: isDarkTheme ? '#9ca3af' : 'black' },
        ...(isDarkTheme && {
          grid: {
            color: '#374151',
          },
        }),
      },
      yAxes: {
        ticks: { color: isDarkTheme ? '#9ca3af' : 'black' },
        ...(isDarkTheme && {
          grid: {
            color: '#374151',
          },
        }),
      },
    },
  };
  const labels = ['Successful', 'Partialy Successful', 'Totally Failed', 'Dropped', 'Missed', 'Aborted', 'Disrupted', 'Partially Disrupted'];

  return (
    <SubjectState
      subject={service.previous}
      render={value => {
        const datasets = [
          {
            label: 'Current',
            backgroundColor: `rgba(255,99,132,${isDarkTheme ? '0.4' : '0.2'})`,
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: `rgba(255,99,132,${isDarkTheme ? '0.6' : '0.4'})`,
            hoverBorderColor: 'rgba(255,99,132,1)',
            data:
              !(state.conferences instanceof ErrorState) &&
              Object.values([
                state.conferences.successful,
                state.conferences.partiallySuccessful,
                state.conferences.failed,
                state.conferences.dropped,
                state.conferences.missed,
                state.conferences.aborted,
                state.conferences.disrupted,
                state.conferences.partiallyDisrupted,
              ]),
          },
          ...(value && state instanceof DashboardPreviousState
            ? [
                {
                  label: 'Previous',
                  backgroundColor: 'rgba(200, 67, 105, 0.4)',
                  borderColor: 'rgba(255,99,132,1)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                  hoverBorderColor: 'rgba(255,99,140,1)',
                  data:
                    !(state.previous.conferences instanceof ErrorState) &&
                    Object.values([
                      state.previous.conferences.successful,
                      state.previous.conferences.partiallySuccessful,
                      state.previous.conferences.failed,
                      state.previous.conferences.dropped,
                      state.previous.conferences.missed,
                      state.previous.conferences.aborted,
                      state.previous.conferences.disrupted,
                      state.previous.conferences.partiallyDisrupted,
                    ]),
                },
              ]
            : []),
        ];

        const data = { labels, datasets };

        return <Bar data={data} options={options} />;
      }}
    />
  );
};
