export enum LastActivity {
  ACKNOWLEGDE = 5,
  FALSE = 6,
  FORWARDED = 7,
}

export const AiAlertTypeCounts = {
  noAlert: -1,
  railDown: 1,
  patientSitting: 2,
  patientStanding: 3,
  patientFaceDown: 4,
  patientLyingSide: 5,
  patientLyingFront: 6,
  patientLyingRightSide: 7,
  fallDetected: 8,
  patientInactive: 9,
  patientGettingOutOfBed: 10,
  patientStandingWalking: 11,
  patientLyingLeftSide: 12,
  handsDisinfected: 13,
  lowIvBagFluidLevel: 14,
  patientGettingOutOfChair: 15,
  patientInactiveInformation: 16,
  handsNotDisinfected: 17,
  // Audio Analysis
  sneezing: 18,
  snoring: 19,
  coughing: 20,
  cryingBaby: 21,
  screaming: 22,
  help: 23,
  lowPriorityAlarm: 24,
  mediumPriortyAlarm: 25,
  highPriorityAlarm: 26,
  alarmOff: 27,
  vitalSignAlarm: 28,
};

export const LastActivityType = {
  1: 'railDown',
  2: 'patientSitting',
  3: 'patientStanding',
  4: 'patientFaceDown',
  5: 'patientLyingSide',
  6: 'patientLyingFront',
  7: 'patientLyingRightSide',
  8: 'fallDetected',
  9: 'patientInactive',
  10: 'patientGettingOutOfBed',
  11: 'patientStandingWalking',
  12: 'patientLyingLeftSide',
  13: 'handsDisinfected',
  14: 'lowIvBagFluidLevel',
  15: 'patientGettingOutOfChair',
  16: 'patientInactiveInformation',
  17: 'handsNotDisinfected',
  18: 'sneezing',
  19: 'snoring',
  20: 'coughing',
  21: 'cryingBaby',
  22: 'screaming',
  23: 'help',
  24: 'lowPriorityAlarm',
  25: 'mediumPriortyAlarm',
  26: 'highPriorityAlarm',
  27: 'alarmOff',
  28: 'vitalSignAlarm',
};

export const AiAlertTypes = {
  [AiAlertTypeCounts.noAlert]: 'No Alert',
  [AiAlertTypeCounts.railDown]: 'Rail Down',
  [AiAlertTypeCounts.patientSitting]: 'Patient Sitting',
  [AiAlertTypeCounts.patientStanding]: 'Patient Standing',
  [AiAlertTypeCounts.patientFaceDown]: 'Patient Face Down',
  [AiAlertTypeCounts.patientLyingSide]: 'Patient Lying Side',
  [AiAlertTypeCounts.patientLyingFront]: 'Patient Lying Front',
  [AiAlertTypeCounts.patientLyingRightSide]: 'Patient Lying Right Side',
  [AiAlertTypeCounts.fallDetected]: 'Fall Detected',
  [AiAlertTypeCounts.patientInactive]: 'Patient Inactive',
  [AiAlertTypeCounts.patientGettingOutOfBed]: 'Patient Getting Out Of Bed',
  [AiAlertTypeCounts.patientStandingWalking]: 'Patient Standing Walking',
  [AiAlertTypeCounts.patientLyingLeftSide]: 'Patient Lying Left Side',
  [AiAlertTypeCounts.handsDisinfected]: 'Hands Disinfected',
  [AiAlertTypeCounts.lowIvBagFluidLevel]: 'IV Liquid Level',
  [AiAlertTypeCounts.patientGettingOutOfChair]: 'Patient Getting Out Of Chair',
  [AiAlertTypeCounts.patientInactiveInformation]: 'Patient Inactive Information',
  [AiAlertTypeCounts.handsNotDisinfected]: 'Hands Not Disinfected',
  [AiAlertTypeCounts.sneezing]: 'Sneezing',
  [AiAlertTypeCounts.snoring]: 'Snoring',
  [AiAlertTypeCounts.coughing]: 'Coughing',
  [AiAlertTypeCounts.cryingBaby]: 'Crying Baby',
  [AiAlertTypeCounts.screaming]: 'Screaming',
  [AiAlertTypeCounts.help]: 'Help',
  [AiAlertTypeCounts.lowPriorityAlarm]: 'Low Priority Alarm',
  [AiAlertTypeCounts.mediumPriortyAlarm]: 'Medium Priority Alarm',
  [AiAlertTypeCounts.highPriorityAlarm]: 'High Priority Alarm',
  [AiAlertTypeCounts.alarmOff]: 'Alarm Off',
  [AiAlertTypeCounts.vitalSignAlarm]: 'Vital Sign Alarm',
};

export const AlertTypes = {
  false: 'false',
  acknowledge: 'acknowledge',
  forwarded: 'forwaded',
};

export const AlertStatus = {
  ACKNOWLEDGED: 1,
  FALSE: 2,
  NEEDS_REVIEW: 3,
};

export const LastActivityStatus = {
  ACKNOWLEDGED: 5,
  FALSE: 6,
  FORWARDED: 7,
};

export const AiAlertsLabels = {
  noAlert: 'No Alert',
  railDown: 'Rail Down',
  patientSitting: 'Patient Sitting',
  patientStanding: 'Patient Standing',
  patientFaceDown: 'Patient Face Down',
  patientLyingSide: 'Patient Lying Side',
  patientLyingFront: 'Patient Lying Front',
  babyFaceDown: 'Baby Face Down',
  false: 'False',
  forwarded: 'Forwarded',
  acknowledge: 'Acknowledge',
  patientLyingRightSide: 'Patient Lying Right Side',
  fallDetected: 'Fall Detected',
  patientInactive: 'Patient Inactive',
  patientGettingOutOfBed: 'Patient Getting Out Of Bed',
  patientStandingWalking: 'Patient Standing Walking',
  patientLyingLeftSide: 'Patient Lying Left Side',
  handsDisinfected: 'Hands Disinfected',
  lowIvBagFluidLevel: 'Low IV Bag Fluid Level',
  patientGettingOutOfChair: 'Patient Getting Out Of Chair',
  patientInactiveInformation: 'Patient Inactive Information',
  handsNotDisinfected: 'Hands Not Disinfected',
  sneezing: 'Sneezing',
  snoring: 'Snoring',
  coughing: 'Coughing',
  cryingBaby: 'Crying Baby',
  screaming: 'Screaming',
  help: 'Help',
  lowPriorityAlarm: 'Low Priority Alarm',
  mediumPriorityAlarm: 'Medium Priority Alarm',
  highPriorityAlarm: 'High Priority Alarm',
  alarmOff: 'Alarm Off',
  vitalSignAlarm: 'Vital Sign Alarm',
};

export const SensitivityType = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
};

export const RailType = {
  TOP_LEFT: 'TL',
  TOP_RIGHT: 'TR',
  BOTTOM_LEFT: 'BL',
  BOTTOM_RIGHT: 'BR',
};

export const sensitivityTypes = [
  {
    label: 'Low',
    value: SensitivityType.LOW,
  },
  {
    label: 'Medium',
    value: SensitivityType.MEDIUM,
  },
  {
    label: 'High',
    value: SensitivityType.HIGH,
  },
];

export const BodyPositionType = {
  SITTING: 1,
  LYING_LEFT_SIDE: 2,
  LYING_RIGHT_SIDE: 3,
  LYING_FRONT: 4,
  LYING_DOWN: 5,
};

export const bodyPosition = [
  {
    label: 'Up in a Chair',
    value: BodyPositionType.SITTING,
  },
  {
    label: 'Left Lateral',
    value: BodyPositionType.LYING_LEFT_SIDE,
  },
  {
    label: 'Right Lateral',
    value: BodyPositionType.LYING_RIGHT_SIDE,
  },
  {
    label: 'Supine',
    value: BodyPositionType.LYING_FRONT,
  },
  {
    label: 'Prone',
    value: BodyPositionType.LYING_DOWN,
  },
];

export const railOptions = [
  { label: 'Left Head Rail', value: RailType.TOP_LEFT },
  { label: 'Right Head Rail', value: RailType.TOP_RIGHT },
  { label: 'Left Foot Rail', value: RailType.BOTTOM_LEFT },
  { label: 'Right Foot Rail', value: RailType.BOTTOM_RIGHT },
];

export const PatientAiSetting = {
  RAILS: 1,
  PATIENT_SITTING: 2,
  PATIENT_STANDING: 3,
  PATIENT_FACE_DOWN: 4,
  PATIENT_LYING_SIDE: 5,
  PATIENT_LYING_FRONT: 6,
  PATIENT_LYING_RIGHT_SIDE: 7,
  FALL_DETECTED: 8,
  PRESSURE_INJURY: 9,
  PATIENT_GETTING_OUT_OF_BED: 10,
  SKELETON_PRIVATE_MODE: 11,
  PATIENT_MOBILITY: 12,
  PATIENT_LYING_LEFT_SIDE: 13,
  VITAL_SIGNS_AI: 14,
  HAND_WASHING: 15,
  IV_BAG: 16,
  AI_DRAWING: 17,
  SOUND_ON_AI_ALERT: 18,
  FALL_PREVENTION: 999,
};
