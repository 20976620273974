// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ReactElement, useState } from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { Col, InputGroupText } from 'reactstrap';
import { ThemeContext } from '@context';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { ThemeService, ThemeState, useService } from '@services';
import moment, { Moment } from 'moment';
import { DateRangeComponentProps, DateRangePickerProps } from './DateRangePicker.props';

export const DateRangePicker = ({ dateRange, timezone, applyRange }: DateRangeComponentProps): ReactElement => {
  const [range, setRange] = useState<DateRangePickerProps>({ start: moment().startOf('day'), end: moment().endOf('day') });
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const applyCallback = (startDate: Moment, endDate: Moment) => {
    setRange({ start: startDate.utc(true), end: endDate.utc(true) });
    applyRange({ start: startDate.utc(true), end: endDate.utc(true) }); // the library does not support timezone, hence the conversion to utc
  };

  const getPreviousQuarter = (subtratQuarter: number) => moment().quarter(moment().quarter()).subtract(subtratQuarter, 'quarter').quarter();

  const getDatePickerPlaceholder = (): string => {
    if (timezone) {
      return `${moment(range.start).format('ddd MMM DD YYYY HH:mm:ss UTC')} - ${moment(range.end).format('ddd MMM DD YYYY HH:mm:ss UTC')}`;
    }
    if (dateRange) {
      return `${dateRange.start.toString()} - ${dateRange.end.toString()}`;
    }

    return `${range.start} - ${range.end}`;
  };

  const getQuarterLabel = (quarter: number) => {
    if (quarter === 1) {
      return 'st';
    }
    if (quarter === 2) {
      return 'nd';
    }
    if (quarter === 3) {
      return 'rd';
    }

    return 'th';
  };
  const getPreviousQuarterYear = (subtractQuarter: number) => moment().quarter(moment().quarter()).subtract(subtractQuarter, 'quarter').year();

  const getQuarterRangesPropertyName = (subtractQuarter: number) => {
    const quarter = getPreviousQuarter(subtractQuarter);
    const quarterYear = getPreviousQuarterYear(subtractQuarter);
    return `${quarter}${getQuarterLabel(quarter)} quarter ${quarterYear}`;
  };

  const getRanges = () => {
    const now = new Date();
    const start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    const end = moment(start).add(1, 'days').subtract(1, 'seconds');

    const ranges = {
      Today: [moment(start), moment(end)],
      Yesterday: [moment(start).subtract(1, 'days'), moment(end).subtract(1, 'days')],
      'Last 7 days': [moment(start).subtract(7, 'days'), moment(end)],
      'Last 30 days': [moment(start).subtract(30, 'days'), moment(end)],
      'Last 90 days': [moment(start).subtract(90, 'days'), moment(end)],
      'Last month': [moment(start).subtract(1, 'month').startOf('month'), moment(start).subtract(1, 'month').endOf('month')],
      'Last Year': [moment(start).subtract(1, 'year').startOf('year'), moment(start).subtract(1, 'year').endOf('year')],
      'Month to date': [moment(start).startOf('month'), moment(end)],
      'Quarter to date': [moment().quarter(moment().quarter()).startOf('quarter'), moment(end)],
      'Year to date': [moment(start).startOf('year'), moment(end)],
    };

    ranges[getQuarterRangesPropertyName(0)] = [moment().quarter(moment().quarter()).startOf('quarter'), moment().quarter(moment().quarter()).endOf('quarter')];
    ranges[getQuarterRangesPropertyName(1)] = [
      moment().quarter(getPreviousQuarter(1)).startOf('quarter'),
      moment().quarter(getPreviousQuarter(1)).endOf('quarter'),
    ];
    ranges[getQuarterRangesPropertyName(2)] = [
      moment().quarter(getPreviousQuarter(2)).startOf('quarter'),
      moment().quarter(getPreviousQuarter(2)).endOf('quarter'),
    ];
    ranges[getQuarterRangesPropertyName(3)] = [
      moment().quarter(getPreviousQuarter(3)).startOf('quarter'),
      moment().quarter(getPreviousQuarter(3)).endOf('quarter'),
    ];
    return ranges;
  };

  const isDarkMode = themeService.theme.value === 'dark';
  const ranges = getRanges();
  const local = {
    format: 'DD-MM-YYYY HH:mm',
    sundayFirst: false,
  };

  return (
    <DateTimeRangeContainer
      key={isDarkMode ? 'dark' : 'light'}
      darkMode={isDarkMode}
      ranges={ranges}
      start={dateRange ? dateRange.start : range?.start}
      end={dateRange ? dateRange.end : range?.end}
      local={local}
      applyCallback={applyCallback}>
      <FormGroup>
        <Col md='12' className='date-picke'>
          <InputGroup>
            <InputGroupText className='dark:bg-dark dark:border-gray-700'>
              <CalendarIcon className='text-gray-500 dark:text-gray-400 w-4 h-4' />
            </InputGroupText>

            <FormControl
              style={{ fontSize: '14px' }}
              id='formControlsTextB'
              type='text'
              label='Text'
              placeholder={getDatePickerPlaceholder()}
              className='dark:bg-dark dark:text-gray-400 dark:border-gray-500 dark:focus:bg-dark'
            />
          </InputGroup>
        </Col>
      </FormGroup>
    </DateTimeRangeContainer>
  );
};
