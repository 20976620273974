import { ReactElement } from 'react';
import { IconProps } from './Icon.props';

export const PauseIcon = ({ className, ...rest }: IconProps): ReactElement => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 16 16' fill='none' {...rest}>
    <path
      d='M3.33334 4.33334C3.33334 3.78106 3.78106 3.33334 4.33334 3.33334H5.66668C6.21896 3.33334 6.66668 3.78106 6.66668 4.33334V11.6667C6.66668 12.219 6.21896 12.6667 5.66668 12.6667H4.33334C3.78106 12.6667 3.33334 12.219 3.33334 11.6667V4.33334Z'
      stroke='white'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.33334 4.33334C9.33334 3.78106 9.78106 3.33334 10.3333 3.33334H11.6667C12.219 3.33334 12.6667 3.78106 12.6667 4.33334V11.6667C12.6667 12.219 12.219 12.6667 11.6667 12.6667H10.3333C9.78106 12.6667 9.33334 12.219 9.33334 11.6667V4.33334Z'
      stroke='white'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
