import { SingleValue } from 'react-select';
import { AIStateApi, getOrganizations } from '@api';
import { OrganizationLevels } from '@enums';
import { CompanyLevelAiSettings, HealthSystemsModel, OrganizationProps, RoomModel, SingleValueProps } from '@interfaces';
import { startOfDay } from '@static';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, from, interval, merge, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

interface SelectedOrganizationsParams {
  value: string;
  label: string;
}

interface PaginationProps {
  pageSize: number;
  pageIndex: number;
  total: number;
}

export class AIState {}

export class AISuccessState {
  constructor(companyLevelAiSettings: CompanyLevelAiSettings[], pagination: PaginationProps) {
    this.companyLeveAiSettings = companyLevelAiSettings;
    this.pagination = pagination;
  }

  companyLeveAiSettings: CompanyLevelAiSettings[];

  pagination: PaginationProps;
}

export class AIStateService extends Service<AIState> {
  constructor(aiStateApi: AIStateApi) {
    super(new AIState());
    this.aiStateApi = aiStateApi;

    getOrganizations()
      .then(organization => this.organizations.next(organization))
      .catch((error: unknown) => (error as AxiosError).message);

    this.setValuesFromParams();

    merge(this.selectedOrganization)
      .pipe(switchMap(this.collectHealthSystems))
      .subscribe(healthSystems => {
        const transformedHealthSystems = (healthSystems as HealthSystemsModel).healthSystems?.map(hs => ({ value: hs.id, label: hs.name })) || [];
        this.healthSystems.next(transformedHealthSystems);
      });

    merge(this.selectedHealthSystem)
      .pipe(switchMap(this.collectRooms))
      .subscribe(rooms => {
        const transformedRooms = (rooms as RoomModel).rooms?.map(({ room }) => ({ value: room.id, label: room.name })) || [];
        this.rooms.next(transformedRooms);
      });

    combineLatest([this.refreshCounter, this.pageIndex, this.refetchInterval])
      .pipe(
        skipWhile(() => !this.selectedOrganization.value?.value && [...this.queryParams.values()].length === 0),
        switchMap(this.collect)
      )
      .subscribe(state => this.next(state));
  }

  aiStateApi: AIStateApi;

  organizations = new BehaviorSubject<OrganizationProps[]>([]);

  selectedOrganization = new BehaviorSubject<SingleValue<SelectedOrganizationsParams>>({ label: 'Select an Organization', value: '' });

  start = new BehaviorSubject<Moment>(moment(startOfDay).startOf('day'));

  end = new BehaviorSubject<Moment>(moment(startOfDay).endOf('day'));

  healthSystems = new BehaviorSubject<SingleValueProps[]>([]);

  selectedHealthSystem = new BehaviorSubject<SingleValue<SelectedOrganizationsParams>>({ label: 'Select a health system', value: '' });

  rooms = new BehaviorSubject<SingleValueProps[]>([]);

  selectedRoom = new BehaviorSubject<SingleValue<SelectedOrganizationsParams>>({ label: 'Select a room', value: '' });

  levelType = new BehaviorSubject<number>(0);

  levelId = new BehaviorSubject<string>('');

  refreshCounter = new BehaviorSubject<number>(0);

  pageIndex = new BehaviorSubject<number>(0);

  pageSize = new BehaviorSubject<number>(20);

  refetchInterval = interval(15000);

  queryParams = new URLSearchParams(window.location.search);

  collect = (): Observable<AIState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        const response = await this.aiStateApi.getAIState(this.selectedOrganization.value?.value || '', this.levelId.value, {
          pageIndex: this.pageIndex.value,
          pageSize: this.pageSize.value,
        });

        return new AISuccessState(response.companyLevelAiSettings, response.pagination);
      } catch (error) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  collectHealthSystems = () => {
    const getHealthSystems = async () => {
      try {
        if (this.selectedOrganization.value?.value) {
          const healthSystems = await this.aiStateApi.getHealthSystems(this.selectedOrganization.value?.value || '');

          return healthSystems;
        }

        return [];
      } catch (error) {
        return (error as AxiosError).message;
      }
    };

    return from(getHealthSystems());
  };

  collectRooms = () => {
    const getRooms = async (): Promise<RoomModel | string> => {
      try {
        if (this.selectedHealthSystem.value?.value) {
          const rooms = await this.aiStateApi.getRoomsByLevelId(this.selectedOrganization.value?.value || '', this.selectedHealthSystem.value?.value || '');

          return rooms;
        }

        return { rooms: [] };
      } catch (error) {
        return (error as AxiosError).message;
      }
    };

    return from(getRooms());
  };

  setValuesFromParams = () => {
    if ([...this.queryParams.values()].length) {
      this.start.next(moment(this.queryParams.get('start')));
      this.end.next(moment(this.queryParams.get('end')));
      this.selectedOrganization.next({ label: this.selectedOrganization.value?.label || '', value: this.queryParams.get('companyId') || '' });
      this.selectedHealthSystem.next({ value: this.queryParams.get('healthSystemId') || '', label: this.selectedHealthSystem.value?.label || '' });
      this.selectedRoom.next({ value: this.queryParams.get('roomId') || '', label: this.selectedRoom.value?.label || '' });
      this.pageIndex.next(Number(this.queryParams.get('pageIndex')));
      const levelType = this.queryParams.get('levelType');
      if (Number(levelType) === OrganizationLevels.HEALTHSYSTEM) {
        this.levelType.next(Number(this.queryParams.get('levelType')));
        this.levelId.next(this.queryParams.get('levelId') || '');
      } else {
        this.levelType.next(Number(this.queryParams.get('levelType')));
        this.levelId.next(this.queryParams.get('levelId') || '');
      }
    }
    this.refreshCounter.next(this.refreshCounter.value + 1);
  };
}
