import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '@context';
import { StarIcon } from '@heroicons/react/24/solid';
import { ThemeService, ThemeState, useService } from '@services';
import { getRatingColor } from '@utils';
import { ConferenceFeedbacksItemProps } from './Feedbacks.props';

export const FeedbacksItem = ({ feedback: { comment, conferenceId, conferenceParticipantId, rate } }: ConferenceFeedbacksItemProps): ReactElement => {
  const [, service] = useService<ThemeState, ThemeService>(ThemeContext);

  const isDarkMode = service.theme.value === 'dark' ? 'rgba(31, 41, 55, 1)' : '#fff';

  return (
    <>
      <td>
        <Link className='no-underline text-sky-400 dark:text-sky-600 dark:hover:text-sky-500' to={`/conferences/past/${conferenceId}`}>
          {conferenceId}
        </Link>
      </td>
      <td>
        <Link
          className='no-underline text-sky-400 dark:text-sky-600 dark:hover:text-sky-500'
          to={`/conferences/past/${conferenceId}/participants/${conferenceParticipantId}`}>
          {conferenceParticipantId}
        </Link>
      </td>
      <td className='dark:text-gray-300'>{comment || 'N/A'}</td>
      <td>
        <div className='flex items-start justify-start'>
          {[1, 2, 3, 4, 5]
            .map(item => <StarIcon key={item} className='w-5 h-5' fill={rate >= item ? getRatingColor(rate) : isDarkMode} stroke={getRatingColor(rate)} />)
            .reverse()}
        </div>
      </td>
    </>
  );
};
