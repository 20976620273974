import { ReactElement } from 'react';
import { IconProps } from './Icon.props';

export const GettingOutOfBedIcon = ({ className, ...rest }: IconProps): ReactElement => (
  <svg width='24' height='24' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path d='M10.6667 13H4.26666V14H2.66666V10.3333H2.93332H9.66666' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2.93335 10.3333L3.93335 6.33333V2H12.6V3.53333' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M3.86664 8.6H3.99997H4.39997C4.9333 8.6 5.39997 8.53333 5.86664 8.33333L6.06664 8.26666C6.53331 8.13333 7.06664 8 7.53331 8H8.73331C9.06664 8 9.33331 7.93332 9.66665 7.99999'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10 6.53333H3.93335' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M10.6643 7.8112V10.6099C10.6644 10.674 10.6523 10.7375 10.6285 10.7968C10.6046 10.8561 10.5696 10.91 10.5255 10.9554C10.4813 11.0008 10.4288 11.0368 10.371 11.0613C10.3133 11.0859 10.2513 11.0985 10.1888 11.0985C10.1262 11.0985 10.0643 11.0859 10.0065 11.0613C9.94876 11.0368 9.89628 11.0008 9.8521 10.9554C9.80793 10.91 9.77293 10.8561 9.74911 10.7968C9.7253 10.7375 9.71313 10.674 9.71332 10.6099V7.55734C9.71332 7.2227 9.84297 6.90177 10.0737 6.66515C10.3045 6.42853 10.6175 6.29559 10.9439 6.29559H12.0202H13.0789C13.4053 6.29559 13.7183 6.42853 13.9491 6.66515C14.1798 6.90177 14.3095 7.2227 14.3095 7.55734V10.6099C14.3095 10.6739 14.2973 10.7373 14.2734 10.7965C14.2495 10.8557 14.2145 10.9094 14.1703 10.9547C14.1262 11 14.0737 11.0359 14.016 11.0604C13.9583 11.0848 13.8965 11.0974 13.834 11.0974C13.7716 11.0974 13.7097 11.0848 13.652 11.0604C13.5943 11.0359 13.5419 11 13.4977 10.9547C13.4536 10.9094 13.4185 10.8557 13.3947 10.7965C13.3708 10.7373 13.3585 10.6739 13.3586 10.6099V7.8112'
      stroke='white'
      strokeMiterlimit='10'
    />
    <path d='M10.6642 10.6109V14.6667' stroke='white' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M13.3585 10.6109V14.6667' stroke='white' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M11.9829 10.6109V14.6667' stroke='white' strokeMiterlimit='10' strokeLinecap='round' />
    <path
      d='M11.9815 5.61993C12.5973 5.61993 13.0965 5.10806 13.0965 4.47663C13.0965 3.84521 12.5973 3.33334 11.9815 3.33334C11.3656 3.33334 10.8664 3.84521 10.8664 4.47663C10.8664 5.10806 11.3656 5.61993 11.9815 5.61993Z'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>
);
