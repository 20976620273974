import { ReactElement } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { EmptyState, Loader } from '@components';
import { DashboardContext, ThemeContext } from '@context';
import { ExclamationCircleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { DashboardOverviewState, DashboardService, DashboardSuccessState, ErrorState, ThemeService, ThemeState, useService } from '@services';
import { ArcElement, Chart, DoughnutControllerChartOptions, Legend, Tooltip } from 'chart.js';
import { ChartOptions } from './Dashboard.props';

Chart.register(ArcElement, Tooltip, Legend);

export const CallRating = (): ReactElement => {
  const [state] = useService<DashboardOverviewState, DashboardService>(DashboardContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  if (state instanceof DashboardSuccessState && state.feedbacks instanceof ErrorState) {
    return (
      <div className='flex justify-center items-center'>
        <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:tet-red-400' />
        <p className='mb-0 ml-1 dark:text-gray-400'>{state.feedbacks.errorMessage}</p>
      </div>
    );
  }

  if (!(state instanceof DashboardSuccessState)) {
    return <Loader />;
  }

  const ratingsFromState = state.feedbacks;
  const options: ChartOptions<'doughnut', DoughnutControllerChartOptions> = {
    plugins: {
      legend: {
        position: 'right',
        ...(themeService.theme.value === 'dark' && {
          labels: {
            color: '#9ca3af',
          },
        }),
      },
    },
    responsive: true,
    layout: { padding: 30 },
    cutout: 70,
  };
  const labels = ['Bad', 'Poor', 'Good', 'Very Good', 'Excellent'];

  const datasets = [
    {
      data: Object.entries(ratingsFromState)
        .sort((a, b) => +a[0] - +b[0])
        .map(v => v[1]),
      backgroundColor: ['#ff4545', '#ffa534', '#ffe234', '#b7dd29', '#57e32c'],
      hoverBackgroundColor: [
        'rgba(255, 69, 69, 0.5)',
        'rgba(255, 165, 52, 0.5)',
        'rgba(255, 226, 52, 0.5)',
        'rgba(183, 221, 41, 0.5)',
        'rgba(87, 227, 44, 0.5)',
      ],
    },
  ];
  const data = {
    labels,
    datasets,
  };

  if (!(state.feedbacks instanceof ErrorState) && !state.feedbacks) {
    return (
      <EmptyState className='flex flex-col items-center  w-full bg-[#fff] dark:bg-dark'>
        <NewspaperIcon className='text-gray-600 dark:text-gray-400 w-10 h-10 mx-auto' />
        <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
      </EmptyState>
    );
  }

  return (
    <div className='w-3/4 mx-auto'>
      <Doughnut options={options} data={data} />
    </div>
  );
};
