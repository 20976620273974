import { ConferencesApi } from '@api';
import { AxiosError } from 'axios';
import { BehaviorSubject, debounceTime, from, merge, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class DiagnosisState {}

export class DiagnosisSuccessState extends DiagnosisState {
  constructor(summary: string) {
    super();
    this.summary = summary;
  }

  summary: string;
}

export class DiagnosisService extends Service<DiagnosisState> {
  constructor(conferencesApi: ConferencesApi) {
    super(new LoadingState());

    this.conferencesApi = conferencesApi;

    merge(this.conferenceId)
      .pipe(debounceTime(200))
      .pipe(switchMap(this.collect))
      .subscribe((state: DiagnosisState) => this.next(state));
  }

  conferencesApi: ConferencesApi;

  conferenceId = new BehaviorSubject<string>('');

  private collect = (): Observable<DiagnosisState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        if (this.conferenceId.value) {
          const state = await this.conferencesApi.getAutomaticDiagnosis(this.conferenceId.value);

          return new DiagnosisSuccessState(state.summary);
        }
        return new DiagnosisState();
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };
}
