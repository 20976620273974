import { ReactElement, useState } from 'react';
import { Data } from 'react-csv/components/CommonPropTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { CSVExport, GeneratePDF, LoaderSpinner } from '@components';
import { EventsContext } from '@context';
import { ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { EventsService, EventsState, EventsSuccessState, useService } from '@services';
import { utcToDateTime } from '@utils';
import { AxiosError } from 'axios';
import moment from 'moment';

export const EventHeaderActions = (): ReactElement => {
  const [csvData, setCsvData] = useState<Data>([]);
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>(false);
  const [csvError, setCsvError] = useState<string>('');
  const [state, service] = useService<EventsState, EventsService>(EventsContext);

  const isSuccessState = state instanceof EventsSuccessState;

  const getCSVData = async () => {
    setCsvError('');
    setIsCsvLoading(true);
    try {
      const pageSize = isSuccessState ? state.events.total : 50;
      const eventCSV = await service.getCSVData(pageSize);
      const data = eventCSV.events.map(event => {
        const timestamp = `${utcToDateTime(event.timestamp, service.timezone.value.value)}.${Number(moment(event.timestamp).milliseconds())}`;
        const user = service.participants.value.find(u => u.id === event.participantId);
        const description = `${event.description}${event.data && event.data.length > 0 ? event.data.map(d => d && ` ${d.item1}: ${d.item2}`) : ''}`;
        const row = {
          Participant: user ? user.fullName : 'Conference Event',
          Timestamp: timestamp,
          Description: description,
        };
        Object.assign(row, { 'Event Key': event.eventKey });

        return row;
      });
      setIsCsvLoading(false);
      return data;
    } catch (error) {
      setIsCsvLoading(false);
      setCsvError((error as AxiosError).message);
      return [];
    }
  };

  const handleCollect = () => {
    service.collect().subscribe((eventsState: EventsState) => service.next(eventsState));
  };

  return (
    <div className='flex items-center space-x-6'>
      <button type='button' onClick={handleCollect} id='refetch-events' style={{ width: '24px' }}>
        <ArrowPathIcon className='w-6 h-6 text-[#000] dark:text-gray-300' />
      </button>
      <GeneratePDF
        selectedParticipantIds={service.participants.value.map(p => p.id)}
        selectedTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        events={isSuccessState ? state.events.events : []}
        participants={service.participants.value}
      />

      <CSVExport
        data={csvData}
        setCsvData={setCsvData}
        getCSVData={getCSVData}
        filename={`Events of conference ${service.conferenceId}.csv`}
        disable={isSuccessState ? state.events.total === 0 || state.events.total > 50000 : false}>
        <button
          id='csv'
          type='button'
          disabled={isSuccessState ? state.events.total === 0 || state.events.total > 50000 : false}
          className='bg-gray-200 dark:bg-transparent dark:border-gray-700 dark:border p-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed'>
          {isCsvLoading && <LoaderSpinner />}
          {!isCsvLoading && <ArrowDownTrayIcon className='text-[#20a8d8] w-5 h-5 ' />}
          <UncontrolledTooltip target='csv'>Export as CSV</UncontrolledTooltip>
        </button>
      </CSVExport>
      {csvError && <p className='mb-0 text-red-500 text-sm'>{csvError}</p>}
    </div>
  );
};
