import { ReactElement } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { PaginationProps } from './Pagination.props';

export const Pagination = ({ totalCount, maxNumberOfPages, onChange, pageIndex, pageSize, className }: PaginationProps): ReactElement => {
  const goToPrevPage = () => {
    if (pageIndex > 0) {
      onChange(pageSize, pageIndex - 1);
    }
  };

  const goToClickedPage = (index: number) => () => {
    onChange(pageSize, index);
  };

  const totalNumberOfPages = () => {
    return Math.ceil(totalCount / pageSize);
  };
  const goToNextPage = () => {
    if (pageIndex < totalNumberOfPages()) onChange(pageSize, pageIndex + 1);
  };

  const getPageNumbers = () => {
    const total = totalNumberOfPages();
    if (total <= maxNumberOfPages) {
      return Array.from({ length: total }, (_v, k) => k + 1);
    }

    if (pageIndex <= maxNumberOfPages / 2) {
      return Array.from({ length: maxNumberOfPages }, (_v, k) => k + 1);
    }

    const start = pageIndex + 1 - maxNumberOfPages / 2;
    const end = pageIndex + 1 + maxNumberOfPages / 2 < total ? pageIndex + 1 + maxNumberOfPages / 2 : total;

    return Array.from({ length: end - start }, (v, k) => k + start + 1);
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className={classNames('flex justify-center', className)}>
      <ul className='flex items-center space-x-1 dark:text-white'>
        <li className='hover:border-gray-300 border-[1px] border-transparent rounded-md mr-3'>
          <button type='button' disabled={pageIndex === 0} className='py-2 px-3 disabled:cursor-not-allowed disabled:opacity-50' onClick={goToClickedPage(0)}>
            First
          </button>
        </li>
        <li className={`flex items-center ${pageIndex - 1 === -1 ? 'disabled' : ''}`}>
          <button
            type='button'
            disabled={pageIndex === 0}
            className='border-[1px] border-transparent hover:border-gray-300 py-2.5 px-2.5 rounded-md mr-3 disabled:cursor-not-allowed disabled:opacity-50'
            tabIndex={-1}
            onClick={goToPrevPage}>
            <ChevronLeftIcon className='w-4 h-4 text-gray-[#20a8d8]' />
          </button>
        </li>

        {pageNumbers.length === 0 && (
          <li className='-custom active' aria-current='page'>
            <button type='button' className='' onClick={goToClickedPage(1)}>
              {pageIndex + 1}
            </button>
          </li>
        )}

        {pageNumbers.map(item => (
          <li
            key={item}
            className={`hover:border-gray-300 border-transparent border-[1px] rounded-md ${item - 1 === pageIndex ? 'bg-[#5390FF] text-white ' : ''}`}
            aria-current='page'>
            <button type='button' className='px-[13px] py-1.5' onClick={goToClickedPage(item - 1)}>
              {item}
            </button>
          </li>
        ))}

        <li className={`flex items-center pr-4 ${pageIndex === totalNumberOfPages() ? 'disabled' : ''}`}>
          <button
            type='button'
            disabled={pageIndex === pageNumbers[pageNumbers.length - 1] - 1}
            className='border-[1px] border-transparent hover:border-gray-300 py-2.5 px-2.5 rounded-md ml-3 disabled:cursor-not-allowed disabled:opacity-50'
            onClick={goToNextPage}>
            <ChevronRightIcon className='w-4 h-4 text-gray-[#20a8d8]' />
          </button>
        </li>

        <li className='hover:border-gray-300 border-[1px] border-transparent rounded-md'>
          <button
            type='button'
            disabled={pageIndex === pageNumbers[pageNumbers.length - 1] - 1}
            className='py-2 px-3 disabled:cursor-not-allowed disabled:opacity-50'
            onClick={goToClickedPage(totalNumberOfPages() - 1)}>
            Last
          </button>
        </li>
      </ul>
    </div>
  );
};
