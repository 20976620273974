import { SingleValue } from 'react-select';
import { getOrganizations, PatientReportApi } from '@api';
import { OrganizationProps, PatientReportModel } from '@interfaces';
import { AxiosError } from 'axios';
import { BehaviorSubject, from, merge, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class PatientReportState {}

export class PatientReportSuccessState extends PatientReportState {
  constructor(response: PatientReportModel) {
    super();
    this.response = response;
  }

  response: PatientReportModel;
}

export class PatientReportService extends Service<PatientReportState> {
  constructor(patientReportApi: PatientReportApi) {
    super(new PatientReportState());
    this.patientReportApi = patientReportApi;

    getOrganizations()
      .then(organizations => {
        this.organizations.next(organizations);
        this.setValuesFromParams();
      })
      .catch((error: unknown) => (error as AxiosError).message);

    merge(this.downloadCounter)
      .pipe(
        skipWhile(() => !this.selectedOrganization.value?.value || !this.patientMrn.value),
        switchMap(this.collect)
      )
      .subscribe((state: PatientReportState) => this.next(state));
  }

  patientReportApi: PatientReportApi;

  organizations = new BehaviorSubject<OrganizationProps[]>([]);

  queryParams = new URLSearchParams(window.location.search);

  selectedOrganization = new BehaviorSubject<SingleValue<{ value: string; label: string }>>({ label: 'Select an organization', value: '' });

  patientMrn = new BehaviorSubject<string>('');

  downloadCounter = new BehaviorSubject<number>(0);

  private collect = (): Observable<PatientReportState> => {
    this.next(new LoadingState());
    const getState = async (): Promise<PatientReportState | ErrorState> => {
      try {
        const response = await this.patientReportApi.getCsvReport({
          tenantId: this.selectedOrganization.value?.value || '',
          patientMrn: this.patientMrn.value,
        });

        return new PatientReportSuccessState(response);
      } catch (error) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  setValuesFromParams = () => {
    if ([...this.queryParams.values()].length !== 0) {
      const organization = this.organizations.value.find(org => org.id === this.queryParams.get('tenantId'));
      this.selectedOrganization.next({ label: organization?.name || '', value: organization?.id || '' });
      this.patientMrn.next(this.queryParams.get('patientMrn') || '');
    }
  };
}
