import { ReactElement } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { NavLinkProps } from './NavLink.props';

export const NavLink = ({ to, children, className }: NavLinkProps): ReactElement => {
  const { pathname } = useLocation();

  return (
    <Link
      to={to}
      className={classNames(
        'no-underline text-gray-300 pl-4 w-full hover:bg-sky-400 hover:text-gray-400 py-2.5',
        pathname.includes(to) && 'bg-[#3A4248] dark:bg-gray-700 text-gray-400',
        className
      )}>
      {children}
    </Link>
  );
};
