import { ReactElement } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { CallStatsLogTypeLabels } from '@enums';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { utcToDateTime } from '@utils';
import classNames from 'classnames';
import moment from 'moment';
import { v4 } from 'uuid';
import { EventCellProps } from './Events.props';

export const EventCell = ({ event, handleShowEventDetails, service }: EventCellProps): ReactElement => (
  <td className='pr-3 py-1.5'>
    <div
      className={classNames(
        'rounded-t-lg text-gray-700  flex flex-wrap text-sm items-center p-1.5 border-t border-x dark:border-gray-700 bg-gray-100 dark:bg-dark-body justify-between',
        event.logType !== 0 && `rounded-b-none border-b-0 border-${CallStatsLogTypeLabels[event.logType]}`
      )}>
      <div className='flex'>
        <p className='mb-0 mr-2 font-semibold dark:text-gray-300'>{event.eventKey}</p>
        {event.detail && (
          <div id={`${event.eventKey.replaceAll('.', '-')}`} onClick={() => handleShowEventDetails(event)}>
            <InformationCircleIcon className='w-6 h-6 text-sky-400' />
            <UncontrolledTooltip target={`${event.eventKey.replaceAll('.', '-')}`}>Show details</UncontrolledTooltip>
          </div>
        )}
      </div>

      <span className='event-timestamp dark:text-gray-300'>{`${utcToDateTime(event.timestamp, service.timezone.value.value)}.${moment(
        event.timestamp
      ).milliseconds()}`}</span>
    </div>
    <div
      className={classNames(
        'text-sm border-[1px] p-2 space-y-2 rounded-b-lg dark:text-gray-300 dark:border-gray-700',
        event.logType !== 0 && `border-b-[1px] rounded-t-none border-t-0  border-${CallStatsLogTypeLabels[event.logType]}`
      )}>
      {event.description}
      {event.data && event.data.length && (
        <>
          {event.data.map(data => (
            <p key={v4()} className='mb-0'>
              <strong>{data.item1}</strong> : {data.item2}
              <br />
            </p>
          ))}
        </>
      )}
    </div>
  </td>
);
