import { UserApiProps, UserModel } from '@interfaces';
import { AuthorizationService } from '@services';

const authService = new AuthorizationService();

export class UserApi implements UserApiProps {
  getUser = async (): Promise<UserModel> => {
    const result = authService.getUser();

    return result;
  };
}
