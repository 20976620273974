export enum UserRoles {
  ADMIN = 'callstats.admin',
  VIEWER = 'callstats.viewer',
  BANYAN_USER = 'callstats.banyan',
}

export enum CompanyRoles {
  PATIENT = 3,
  ADMIN = 2,
  MEMBER = 3,
  VIRTUAL_CARE_PROVIDER = 6,
  VIRTUAL_SITTER = 7,
  SUPER_USER = 8,
  SUPER_ADMIN = 9,
  DIGITAL_CLINICIAN = 10,
  DOCTOR = 11,
  VISITOR = 12,
}

export enum HealthcareRole {
  DOCTOR = 1,
  NURSE = 2,
  PATIENT = 3,
  ADMIN = 4,
  SUPER_USER = 5,
  SUPER_ADMIN = 6,
  OWNER = 7,
  DIGITAL_CLINICIAN = 8,
  FAMILY_MEMBER = 9,
  GUEST = 99,
}
