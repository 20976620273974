import { createContext, ReactElement, useMemo } from 'react';
import { ConferencesFeedbackApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { ConferenceFeedbacksService } from '@services';

const conferenceFeedbacks = new ConferencesFeedbackApi();

export const ConferenceFeedbacksContext = createContext({} as ConferenceFeedbacksService);

export const ConferenceFeedbacksProvider = ({ children }: ContextProviderProps): ReactElement => {
  const ratingsService = useMemo(() => new ConferenceFeedbacksService(conferenceFeedbacks), []);

  return <ConferenceFeedbacksContext.Provider value={ratingsService}>{children}</ConferenceFeedbacksContext.Provider>;
};
