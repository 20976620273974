import { ReactElement } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { EmptyState, Loader } from '@components';
import { DashboardContext, ThemeContext } from '@context';
import { ExclamationCircleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { DashboardOverviewState, DashboardService, DashboardSuccessState, ErrorState, ThemeService, ThemeState, useService } from '@services';
import { ArcElement, Chart, DoughnutControllerChartOptions, Legend, Tooltip } from 'chart.js';
import { ChartOptions } from './Dashboard.props';

Chart.register(ArcElement, Tooltip, Legend);

export const CallsByPartner = (): ReactElement => {
  const [state] = useService<DashboardOverviewState, DashboardService>(DashboardContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  if (state instanceof DashboardSuccessState && state.appType instanceof ErrorState) {
    return (
      <div className='flex justify-center items-center'>
        <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:text-red-400' />
        <p className='mb-0 ml-1 text-sm dark:text-gray-400'>{state.appType.errorMessage}</p>
      </div>
    );
  }

  if (!(state instanceof DashboardSuccessState)) {
    return <Loader />;
  }

  const options: ChartOptions<'doughnut', DoughnutControllerChartOptions> = {
    cutout: '70%',
    plugins: {
      legend: {
        position: 'right',
        ...(themeService.theme.value === 'dark' && {
          labels: {
            color: '#9ca3af',
          },
        }),
      },
    },
    responsive: true,
    layout: { padding: 30 },
  };

  const labels = Object.keys(state.appType).map(type => type.charAt(0).toUpperCase() + type.substring(1).toLowerCase());

  const datasets = [
    {
      data: Object.values(state.appType),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#595bd7', '#4ed1ff', '#17a2b8'],
      hoverBackgroundColor: ['#ff87be', '#2f98e9', '#ffc107', '#3b53d7', '#50a3f0', '#3884a4'],
    },
  ];

  const data = {
    labels,
    datasets,
  };

  if (!(state.appType instanceof ErrorState) && !state.appType) {
    return (
      <EmptyState className='flex flex-col items-center  w-full bg-[#fff] dark:bg-dark'>
        <NewspaperIcon className='text-gray-600 dark:text-gray-400 w-10 h-10 mx-auto' />
        <span className='font-medium dark:text-gray-400'>No data to be shown.</span>
      </EmptyState>
    );
  }

  return (
    <div className='w-3/4 mx-auto'>
      <Doughnut options={options} data={data} />
    </div>
  );
};
