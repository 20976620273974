import { ReactElement } from 'react';
import { DefaultRoutes } from './routes/index';

const App = (): ReactElement => (
  <div className='App'>
    <DefaultRoutes />
  </div>
);

export default App;
