import { ReactElement } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { DefaultLayout } from '@containers';
import { DashboardProvider } from '@context';
import { CallRating } from './CallRating';
import { Calls } from './Calls';
import { CallsByPartner } from './CallsByPartner';
import { CallsByType } from './CallsByType';
import { Conferences } from './Conferences';
import { DashboardFilters } from './DashboardFilters';
import { DashboardOverview } from './DashboardOverview';
import { Duration } from './Duration';

export const Dashboard = (): ReactElement => (
  <DashboardProvider>
    <DefaultLayout>
      <Col className='space-y-10 '>
        <Calls />
        <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700'>
          <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Dashboard Filters</CardHeader>
          <CardBody>
            <DashboardFilters />
          </CardBody>
        </Card>
        <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700'>
          <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Dashboard Overview</CardHeader>
          <CardBody>
            <DashboardOverview />
          </CardBody>
        </Card>
        <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700'>
          <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Call Duration</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Duration />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col>
            <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700' style={{ height: '100%' }}>
              <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Calls By Partner</CardHeader>
              <CardBody className='flex items-center justify-center'>
                <CallsByPartner />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700' style={{ height: '100%' }}>
              <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Calls by Type</CardHeader>
              <CardBody className='flex items-center justify-center'>
                <CallsByType />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700' style={{ height: '100%' }}>
              <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Call Rating</CardHeader>
              <CardBody className='flex items-center justify-center'>
                <CallRating />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700'>
              <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>Conferences</CardHeader>
              <CardBody>
                <Conferences />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </DefaultLayout>
  </DashboardProvider>
);
