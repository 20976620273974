import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@components';
import { defaultLayoutServiceContext } from '@context';
import { DefaultLayoutService, DefaultLayoutState, SubjectState, useService } from '@services';
import classNames from 'classnames';
import queryString from 'query-string';
import { DefaultAside } from './DefaultAside';
import { DefaultFooter } from './DefaultFooter';
import { DefaultHeader } from './DefaultHeader';
import { DefaultLayoutProps } from './DefaultLayout.props';

export const DefaultLayout = ({ children, breadcrumbs }: DefaultLayoutProps): ReactElement => {
  const [, service] = useService<DefaultLayoutState, DefaultLayoutService>(defaultLayoutServiceContext);

  const { search } = useLocation();

  const queryParams = queryString.parse(search);

  const handleShowSidebar = (value: boolean) => () => service.sidebar.next({ narrow: false, visible: !value });
  const handleSetNarrow = (value: boolean) => () => service.sidebar.next({ narrow: !value, visible: true });

  useEffect(() => {
    service.params.next(queryParams);
  }, [queryParams, service.params]);

  return (
    <SubjectState
      subject={service.sidebar}
      render={({ narrow, visible }) => {
        const getMargin = () => {
          if (!visible) return 'ml-0';
          if (!narrow) return 'pl-[240px]';
          return 'pl-[90px]';
        };

        return (
          <div className='flex flex-col min-h-screen'>
            <DefaultHeader onToggle={handleShowSidebar(visible)} />
            <div className='flex flex-grow mt-[25px]'>
              <DefaultAside showSidebar={visible} narrow={narrow} setNarrow={handleSetNarrow(narrow)} />
              <main className={classNames('bg-gray-100 dark:bg-dark w-full min-h-full py-10 transition duration-300 px-8', getMargin())}>
                <Breadcrumbs breadcrumbs={breadcrumbs || []} propsParams={service.params.value} />
                {children}
              </main>
            </div>
            <DefaultFooter />
          </div>
        );
      }}
    />
  );
};
