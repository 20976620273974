import { gatewayApi } from '@config';
import { FailuresApiProps, FailuresDetailsProps, FailuresProps, FailuresTimelineProps } from '@interfaces';
import { AxiosResponse } from 'axios';
import { Moment } from 'moment';

export class FailuresApi implements FailuresApiProps {
  async getFailures(start: Moment, end: Moment, pageSize: number, pageIndex: number): Promise<FailuresProps> {
    const { data }: AxiosResponse<FailuresProps> = await gatewayApi.get('v1/callstats/failures', {
      params: {
        start: start.unix(),
        end: end.unix(),
        pageSize,
        pageIndex,
      },
    });

    return data;
  }

  async getFailuresDetails(start: Moment, end: Moment, message: string, method: string): Promise<FailuresDetailsProps> {
    const { data }: AxiosResponse<FailuresDetailsProps> = await gatewayApi.get('v1/callstats/failures-details', {
      params: {
        start: start.unix(),
        end: end.unix(),
        method,
        message,
      },
    });

    return data;
  }

  async getFailuresTimeline(start: Moment, end: Moment): Promise<FailuresTimelineProps> {
    const { data }: AxiosResponse<FailuresTimelineProps> = await gatewayApi.get('v1/callstats/failures-timeline', {
      params: {
        start: start.unix(),
        end: end.unix(),
      },
    });

    return data;
  }
}
