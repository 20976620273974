import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { Error, Loader, Pagination } from '@components';
import { EventsContext } from '@context';
import { CallStatsLogTypeLabels } from '@enums';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { EventModel, ParticipantModel } from '@interfaces';
import { ErrorState, EventsService, EventsState, EventsSuccessState, LoadingState, SubjectState, useService } from '@services';
import { utcToDateTime } from '@utils';
import classNames from 'classnames';
import moment from 'moment';
import queryString from 'query-string';
import { v4 } from 'uuid';
import { EventCell } from './EventCell';
import { EventDetails } from './EventDetails';

export const Events = (): ReactElement => {
  const [state, service] = useService<EventsState, EventsService>(EventsContext);
  const [details, setDetails] = useState<string>('');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const successState = state instanceof EventsSuccessState ? state.events : null;

  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = queryString.parse(search);
  const condition = service.participantIds.value.length === service.participants.value.length;
  const participants = service.participants.value;
  const participantIds = service.participantIds.value;

  const parseJson = (event: EventModel) => {
    try {
      const json = JSON.stringify(JSON.parse(event.detail || ''), null, 2);
      if (!json) {
        return 'No details found.';
      }

      return json;
    } catch (error: unknown) {
      return `Unexpected error occurred: ${(error as { message: string }).message}`;
    }
  };

  const getParticipants = () => {
    if (service.groupByUser.value) {
      const newParticipantArray: ParticipantModel[] = [];

      if (participantIds.length !== 0) {
        const groupedSelectedParticipants: ParticipantModel[] = [];
        participantIds.forEach(id => {
          const selectedParticipant = participants.find(participant => participant.id === id);
          if (!groupedSelectedParticipants.find(participant => participant.objectId === selectedParticipant?.objectId) && selectedParticipant) {
            groupedSelectedParticipants.push(selectedParticipant);
          }
        });

        service.groupedParticipants.next(groupedSelectedParticipants);
        return groupedSelectedParticipants;
      }

      participants.forEach(p => {
        const newParticipant = { ...p };
        if (!newParticipantArray.find(participant => participant.objectId === newParticipant.objectId)) {
          newParticipantArray.push(newParticipant);
        }
      });
      service.groupedParticipants.next(newParticipantArray);
      return service.groupedParticipants.value;
    }
    return service.participants.value.filter(participant => service.participantIds.value.includes(participant.id));
  };

  const getParticipantsLength = () => {
    if (service.groupByUser.value) {
      return service.groupedParticipants.value.length;
    }
    if (condition) {
      return participants.length;
    }
    return participantIds.length;
  };

  const getParticipantsByCondition = (event: EventModel, index: number) => {
    if (service.groupByUser.value) {
      return event.objectId !== +service.groupedParticipants.value[index].objectId;
    }
    if (condition) {
      return event.participantId !== participants[index].id;
    }
    return event.participantId !== participantIds[index];
  };

  const handleShowDetails = () => setShowDetails(!showDetails);
  const handleShowEventDetails = (event: EventModel) => () => {
    service.selectedEvent.next(event);
    const detailsJSON = parseJson(event);
    setDetails(detailsJSON);
    handleShowDetails();
  };

  const handlePageChange = (_pageSize: number, pageIndex: number) => {
    queryParams.pageIndex = String(pageIndex);
    service.pageIndex.next(pageIndex);
    navigate({
      search: queryString.stringify({ ...queryParams }),
    });
  };

  const getEvents = (event: EventModel) => {
    const cells = [];
    for (let i = 0; i < getParticipantsLength(); i += 1) {
      if (getParticipantsByCondition(event, i)) {
        if (event.participantId) {
          cells.push(<td className='pr-3 py-1.5' />);
        }
      } else {
        cells.push(<EventCell event={event} handleShowEventDetails={handleShowEventDetails(event)} service={service} />);
      }
    }

    return cells;
  };

  if (state instanceof LoadingState) {
    return <Loader />;
  }

  if (state instanceof ErrorState) {
    return <Error message={state.errorMessage} />;
  }

  return (
    <>
      <div className='shadow-[0px 0px 3px rgba(0, 0, 0, 0.9)] w-full'>
        <div className='overflow-auto h-fit max-h-[800px] margin-[15px] pb-5'>
          <SubjectState
            subject={service.groupByUser}
            render={() => (
              <table className={`${getParticipantsLength() > 4 ? '' : 'table-fixed'} w-full h-full`}>
                <thead className='sticky top-0 z-[30]'>
                  {getParticipants().map(scrollParticipant => (
                    <th key={scrollParticipant.id} className='text-sm bg-[#fff] dark:bg-dark text-left font-normal pr-3 align-top w-fit'>
                      <div
                        className={classNames(
                          'items-start rounded-lg px-[15px] py-[10px] border-b border-x border-t-sky-400 dark:border-t-sky-700 border-t h-full dark:border-gray-700 dark:text-gray-300 dark:bg-dark-body',
                          getParticipantsLength() > 4 ? 'w-[370px]' : 'w-full'
                        )}>
                        <span>
                          <strong>Participant: </strong>
                          {scrollParticipant.fullName}
                        </span>
                        <div className='flex flex-col'>
                          <span className='mt-1'>
                            <strong>ID: </strong>
                            {scrollParticipant.id}
                          </span>
                          <span className='mt-1'>
                            <strong>Device: </strong>
                            {scrollParticipant.device ?? scrollParticipant.deviceSerialNumber}
                          </span>
                        </div>
                      </div>
                    </th>
                  ))}
                </thead>
                <tbody>
                  {successState?.events.map(event => (
                    <>
                      <tr key={v4()}>{getEvents(event)}</tr>
                      {!event.participantId && (
                        <tr>
                          <td className='py-1.5 pr-3' colSpan={getParticipantsLength()}>
                            <div
                              className={classNames(
                                'rounded-t-lg text-gray-700 dark:text-gray-300 dark:bg-dark-body dark:border-gray-700 flex flex-wrap text-sm items-center p-1.5 border-t border-x  bg-gray-100 justify-between',
                                event.logType > 0 && `rounded-md border-${CallStatsLogTypeLabels[event.logType]} border-b-0 rounded-b-none`
                              )}>
                              <div className='flex items-center'>
                                <p className='mb-0 mr-2 font-semibold'>{event.eventKey}</p>
                                {event.detail && (
                                  <div id={`${event.eventKey.replaceAll('.', '-')}`} onClick={handleShowEventDetails(event)}>
                                    <InformationCircleIcon className='w-6 h-6 text-sky-400' />
                                    <UncontrolledTooltip target={`${event.eventKey.replaceAll('.', '-')}`}>Show details</UncontrolledTooltip>
                                  </div>
                                )}
                              </div>
                              <span className='event-timestamp text-gray-400'>{`${utcToDateTime(event.timestamp, service.timezone.value.value)}.${moment(
                                event.timestamp
                              ).milliseconds()}`}</span>
                            </div>
                            <div
                              className={classNames(
                                'text-sm border-[1px] p-2 space-y-2 rounded-b-lg dark:border-gray-700 dark:text-gray-300',
                                event.logType > 0 && `border-b-[1px] rounded-t-none border-t-0 border-${CallStatsLogTypeLabels[event.logType]}`
                              )}>
                              {event.description}
                              {event.data && event.data.length && (
                                <>
                                  {event.data.map(data => (
                                    <p key={v4()} className='mb-0'>
                                      <strong>{data.item1}</strong> : {data.item2}
                                      <br />
                                    </p>
                                  ))}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            )}
          />
        </div>
      </div>

      {(successState?.total || 0) > service.pageSize.value && (
        <Pagination
          totalCount={successState?.total || 0}
          pageIndex={service.pageIndex.value || 0}
          pageSize={service.pageSize.value || 50}
          maxNumberOfPages={10}
          onChange={handlePageChange}
        />
      )}

      <EventDetails
        details={details}
        showDetails={showDetails}
        handleToggleDetails={handleShowDetails}
        eventKey={service.selectedEvent.value?.eventKey || ''}
      />
    </>
  );
};
