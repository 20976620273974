import { UserApi } from '@api';
import { UserModel } from '@interfaces';
import { AxiosError } from 'axios';
import { BehaviorSubject, from, merge, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class UserState {}

export class UserSuccessState {
  constructor(user: UserModel) {
    this.user = user;
  }

  user: UserModel;
}

export class UserService extends Service<UserState> {
  constructor(userApi: UserApi) {
    super(new UserState());
    this.userApi = userApi;

    merge(this.user)
      .pipe(switchMap(this.collect))
      .subscribe(state => this.next(state));
  }

  userApi: UserApi;

  user = new BehaviorSubject<UserModel | null>(null);

  private collect = (): Observable<UserState> => {
    this.next(new LoadingState());
    return from(
      new Promise<UserState>(resolve => {
        this.getUser()
          .then(({ user }) => resolve(new UserSuccessState(user)))
          .catch((err: AxiosError) => resolve(new ErrorState(err.message)));
      })
    );
  };

  getUser = async () => {
    const result = await this.userApi.getUser();

    return new UserSuccessState(result);
  };
}
