import { BehaviorSubject } from 'rxjs';
import { Service } from '../Service';

export interface PaginationProps {
  onChange: (pageSize: number, pageIndex: number) => void;
  pageSize: number;
  pageIndex: number;
  totalCount: number;
  maxNumberOfPages: number;
}

export class PaginationState {
  constructor(pageIndex: number, pageSize: number, totalCount: number) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.totalCount = totalCount;
    this.maxNumberOfPages = this.totalCount / 10;
  }

  pageIndex: number;

  pageSize: number;

  maxNumberOfPages: number;

  totalCount: number;
}

export class PaginationService extends Service<PaginationState> {
  constructor() {
    super(new PaginationState(0, 10, 0));
  }

  pageIndex = new BehaviorSubject<number>(0);

  pageSize = new BehaviorSubject<number>(10);

  maxNumberOfPages = new BehaviorSubject<number>(0);

  totalCount = new BehaviorSubject<number>(0);

  totalNumberOfPages = () => {
    const pages = parseInt(String(Math.ceil(parseFloat(String(this.totalCount.getValue() / this.pageSize.getValue())))), 10);

    return pages - 1;
  };

  getPageNumbers = () => {
    const total = this.totalNumberOfPages() + 1;
    if (total <= this.maxNumberOfPages.getValue()) {
      return Array.from({ length: total }, (_, k) => k + 1);
    }

    if (this.pageIndex.getValue() <= this.maxNumberOfPages.getValue() / 2) {
      return Array.from({ length: this.maxNumberOfPages.getValue() }, (_, k) => k + 1);
    }

    const start = this.pageIndex.getValue() + 1 - this.maxNumberOfPages.getValue() / 2;
    const end =
      this.pageIndex.getValue() + 1 + this.maxNumberOfPages.getValue() / 2 < total
        ? this.pageIndex.getValue() + 1 + this.maxNumberOfPages.getValue() / 2
        : total;

    return Array.from({ length: end - start }, (_, k) => k + start);
  };

  goToNextPage = (pageSize: number, pageIndex: number) => {
    this.pageIndex.next(pageIndex);
    this.pageSize.next(pageSize + 1);
  };

  goToPreviousPage = (pageSize: number, pageIndex: number) => {
    if (pageIndex > 0) {
      this.pageIndex.next(pageIndex);
      this.pageSize.next(pageSize - 1);
    }
  };

  goToClickedPage = (index: number) => {
    this.pageIndex.next(index);
  };

  pageNumbers: number[] = this.getPageNumbers();
}
