import { ReactElement } from 'react';
import { Loader } from '@components';
import { DashboardContext } from '@context';
import { ConferenceStatus } from '@enums';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { DashboardOverviewState, DashboardPreviousState, DashboardService, DashboardSuccessState, ErrorState, LoadingState, useService } from '@services';
import { DashboardOverviewItem } from './DashboardOverviewItem';

export const DashboardOverview = (): ReactElement => {
  const [state] = useService<DashboardOverviewState, DashboardService>(DashboardContext);

  if (state instanceof ErrorState) {
    return (
      <div className='flex justify-center items-center'>
        <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:text-red-400' />
        <p className='mb-0 ml-1 text-sm dark:text-gray-400'>{state.errorMessage}</p>
      </div>
    );
  }

  if (state instanceof LoadingState) {
    return <Loader />;
  }

  const previous = state instanceof DashboardPreviousState ? state.previous : null;
  const previousSuccessState = !(previous?.conferences instanceof ErrorState) ? previous?.conferences : null;
  const currentState = state instanceof DashboardSuccessState ? state : null;
  const currentSuccessState = !(currentState?.conferences instanceof ErrorState) ? currentState?.conferences : null;

  return (
    <div className='grid grid-cols-4 gap-4'>
      <DashboardOverviewItem
        color='success'
        type={ConferenceStatus.SUCCESSFUL}
        description='Successful conferences'
        previousCount={previousSuccessState?.successful}
        count={currentSuccessState?.successful || 0}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='warning'
        type={ConferenceStatus.PARTIALLY_SUCCESSFUL}
        description='Partially successful conferences'
        previousCount={previousSuccessState?.partiallySuccessful}
        count={currentSuccessState?.partiallySuccessful || 0}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='danger'
        type={ConferenceStatus.FAILED}
        description='Totally failed conferences'
        previousCount={previousSuccessState?.failed}
        count={currentSuccessState?.failed || 0}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='info'
        type={ConferenceStatus.DROPPED}
        description='Dropped conferences'
        previousCount={previousSuccessState?.dropped}
        count={currentSuccessState?.dropped || 0}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='secondary'
        type={ConferenceStatus.MISSED}
        description='Missed conferences'
        previousCount={previousSuccessState?.missed}
        count={currentSuccessState?.missed || 0}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='primary'
        type={ConferenceStatus.ABORTED}
        description='Aborted conferences'
        count={currentSuccessState?.aborted || 0}
        previousCount={previousSuccessState?.aborted}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='dark'
        type={ConferenceStatus.DISRUPTED}
        description='Disrupted conferences'
        previousCount={previousSuccessState?.disrupted}
        count={currentSuccessState?.disrupted || 0}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
      <DashboardOverviewItem
        color='muted'
        type={ConferenceStatus.PARTIALLY_DISRUPTED}
        description='Partially disrupted conferences'
        count={currentSuccessState?.partiallyDisrupted || 0}
        previousCount={previousSuccessState?.partiallyDisrupted}
        totalCount={currentState?.conferencesTotalCount || 0}
      />
    </div>
  );
};
