import { createContext, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ParticipantsApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { ParticipantsService, SingleParticipantService } from '@services';

const participantsApi = new ParticipantsApi();

export const ParticipantsContext = createContext<ParticipantsService>({} as ParticipantsService);

export const ParticipantsProvider = ({ children, isOngoing }: ContextProviderProps): ReactElement => {
  const { conferenceId } = useParams();
  const participantsService = useMemo(() => new ParticipantsService(participantsApi, isOngoing ?? false, conferenceId ?? ''), [isOngoing]);

  return <ParticipantsContext.Provider value={participantsService}>{children}</ParticipantsContext.Provider>;
};

export const SingleParticipantContext = createContext({} as SingleParticipantService);

export const SingleParticipantProvider = ({ children, isOngoing }: ContextProviderProps): ReactElement => {
  const { conferenceId, participantId } = useParams();
  const singleParticipantService = useMemo(
    () => new SingleParticipantService(participantsApi, isOngoing ?? false, conferenceId ?? '', participantId ?? ''),
    [isOngoing]
  );

  return <SingleParticipantContext.Provider value={singleParticipantService}>{children}</SingleParticipantContext.Provider>;
};
