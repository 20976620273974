import { ReactElement } from 'react';
import { Col, Progress, UncontrolledTooltip } from 'reactstrap';
import { DashboardContext } from '@context';
import { ConferenceStatus } from '@enums';
import { ArrowDownRightIcon, ArrowUpRightIcon } from '@heroicons/react/24/solid';
import { DashboardOverviewState, DashboardPreviousState, DashboardService, SubjectState, useService } from '@services';
import classNames from 'classnames';
import { DashboardOverviewItemProps } from './Dashboard.props';

export const DashboardOverviewItem = ({ description, type, count, previousCount, color, totalCount }: DashboardOverviewItemProps): ReactElement => {
  const [state, { previous }] = useService<DashboardOverviewState, DashboardService>(DashboardContext);

  const currentPercentage = Math.round((totalCount || 0) > 0 ? ((count || 0) / (totalCount || 0)) * 100 : 0);
  const previousPercentage = Math.round(
    previousCount === 0 ? (((count || 0) - previousCount) / 1) * 100 : (((count || 0) - (previousCount || 0)) / (previousCount || 0)) * 100
  );

  const isPositiveChange = (previousPercentage < 0 && type !== ConferenceStatus.SUCCESSFUL) || (type === ConferenceStatus.SUCCESSFUL && previousPercentage > 0);

  return (
    <SubjectState
      subject={previous}
      render={value => (
        <Col sm={12} md className='mb-0 text-center text-sm'>
          <div>
            <p className='text-gray-500 mb-0 dark:text-[#989082]'>{description}</p>
            <span id={`CallStatusDescription_${type}`} className=' fa fa-fw fa-info-circle text-info' />
            <UncontrolledTooltip placement='top' target={`CallStatusDescription_${type}`}>
              {type}
            </UncontrolledTooltip>
          </div>
          <strong className='dark:text-gray-400'>{count} </strong>
          <strong
            className={classNames({
              'inline-flex items-center text-[13px]': value,
              'text-green-700': previousPercentage !== 0 && isPositiveChange,
              'text-red-500': previousPercentage !== 0 && !isPositiveChange,
            })}>
            {value && state instanceof DashboardPreviousState && `(${previousPercentage}%`}
            {value &&
              state instanceof DashboardPreviousState &&
              previousPercentage !== 0 &&
              (previousPercentage > 0 ? <ArrowUpRightIcon className='d-inline w-4 h-4 ml-1' /> : <ArrowDownRightIcon className='d-inline w-4 h-4 ml-1' />)}
            {value && state instanceof DashboardPreviousState && `) ${previousCount}`}
          </strong>

          <Progress className='xs mt-2 dark:border-gray-700 dark:border' style={{ height: '6px' }} color={color} value={currentPercentage} />
        </Col>
      )}
    />
  );
};
