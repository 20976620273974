import { createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ConferencesApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { ConferencesService, DiagnosisService, SingleConferenceService } from '@services';

const conferencesApi = new ConferencesApi();

export const ConferenceContext = createContext<ConferencesService>({} as ConferencesService);

export const ConferencesProvider = ({ children, isOngoing }: ContextProviderProps) => {
  const conferencesService = useMemo(() => new ConferencesService(conferencesApi, isOngoing ?? false), [isOngoing]);

  return <ConferenceContext.Provider value={conferencesService}>{children}</ConferenceContext.Provider>;
};

export const SingleConferenceContext = createContext<SingleConferenceService>({} as SingleConferenceService);

export const SingleConferenceProvider = ({ children, isOngoing }: ContextProviderProps) => {
  const { conferenceId } = useParams();
  const singleConferenceService = useMemo(() => new SingleConferenceService(conferencesApi, isOngoing ?? false, conferenceId ?? ''), [isOngoing]);

  return <SingleConferenceContext.Provider value={singleConferenceService}>{children}</SingleConferenceContext.Provider>;
};

export const DiagnosisContext = createContext<DiagnosisService>({} as DiagnosisService);

export const DiagnosisProvider = ({ children }: ContextProviderProps) => {
  const diagnosisService = useMemo(() => new DiagnosisService(conferencesApi), []);

  return <DiagnosisContext.Provider value={diagnosisService}>{children}</DiagnosisContext.Provider>;
};
