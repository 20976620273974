import { SingleValue } from 'react-select';
import { DataAcquisitionApi, getOrganizations } from '@api';
import { DataAcquisitionModel, OrganizationProps } from '@interfaces';
import { AxiosError } from 'axios';
import { BehaviorSubject, from, merge, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class DataAcquisitionState {}

export class DataAcquisitionSuccessState {
  constructor(frames: DataAcquisitionModel, dataAcquisitionStats: DataAcquisitionModel) {
    this.frames = frames;
    this.dataAcquisitionStats = dataAcquisitionStats;
  }

  frames: DataAcquisitionModel;

  dataAcquisitionStats: DataAcquisitionModel;
}

export class DataAcquisitionService extends Service<DataAcquisitionState> {
  constructor(dataAcquisitionApi: DataAcquisitionApi) {
    super(new DataAcquisitionState());

    this.dataAcquisitionApi = dataAcquisitionApi;

    getOrganizations()
      .then(organizations => {
        this.organizations.next(organizations);
        this.setValuesFromParams();
      })
      .catch((error: unknown) => (error as AxiosError).message);

    merge(this.selectedOrganization)
      .pipe(
        skipWhile(value => !value?.value),
        switchMap(this.collect)
      )
      .subscribe(state => this.next(state));
  }

  dataAcquisitionApi: DataAcquisitionApi;

  organizations = new BehaviorSubject<OrganizationProps[]>([]);

  queryParams = new URLSearchParams(window.location.search);

  selectedOrganization = new BehaviorSubject<SingleValue<{ value: string; label: string }>>({ label: 'Select an Organization', value: '' });

  private collect = (): Observable<DataAcquisitionState> => {
    this.next(new LoadingState());
    const getState = async (): Promise<DataAcquisitionState | ErrorState> => {
      try {
        const [stats, dataAcquisitionStats] = await Promise.all([
          this.dataAcquisitionApi.getStats(this.selectedOrganization.value?.value || ''),
          this.dataAcquisitionApi.getDataAcquisitionStats(this.selectedOrganization.value?.value || ''),
        ]);

        return new DataAcquisitionSuccessState(stats, dataAcquisitionStats);
      } catch (error) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  setValuesFromParams = () => {
    if ([...this.queryParams.values()].length !== 0) {
      const organization = this.organizations.value.find(org => org.id === this.queryParams.get('tenantId'));
      this.selectedOrganization.next({ label: organization?.name || '', value: organization?.id || '' });
    }
  };
}
