import { CallStatsApi } from '@api';
import { GraphModel, StatsModel } from '@interfaces';
import { AxiosError } from 'axios';
import moment from 'moment';
import { BehaviorSubject, combineLatest, debounceTime, from, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class PeerConnectionStatsState {}

export class PeerConnectionStatsSuccessState extends PeerConnectionStatsState {
  constructor(stats: StatsModel[]) {
    super();

    this.stats = stats;
  }

  stats: StatsModel[];
}

export class PeerConnectionStatsService extends Service<PeerConnectionStatsState> {
  constructor(callStatsApi: CallStatsApi, isOngoing: boolean, conferenceId: string, participantId: string, peerId: string) {
    super(new PeerConnectionStatsState());

    this.peerId = peerId;

    this.isOngoing = isOngoing;

    this.conferenceId = conferenceId;

    this.participantId = participantId;

    this.callStatsApi = callStatsApi;

    combineLatest([this.startTime, this.endTime])
      .pipe(debounceTime(200))
      .pipe(skipWhile(([start]) => start === 0))
      .pipe(switchMap(this.collect))
      .subscribe((state: PeerConnectionStatsState) => this.next(state));
  }

  callStatsApi: CallStatsApi;

  isOngoing: boolean;

  peerId: string;

  conferenceId: string;

  participantId: string;

  divisions = 1000;

  min = 0;

  max = 1000;

  startTime = new BehaviorSubject<number>(0);

  endTime = new BehaviorSubject<number>(0);

  private collect = (): Observable<PeerConnectionStatsState> => {
    this.next(new LoadingState());

    const getState = async (): Promise<PeerConnectionStatsState | ErrorState> => {
      try {
        const et = this.endTime.value;
        const startingTime = this.startTime.value;
        const endingTime = this.isOngoing ? moment.utc() : et;

        const startTime = startingTime + Math.round(((+endingTime - startingTime) / this.divisions) * this.min);
        const endTime = startingTime + Math.round(((+endingTime - startingTime) / this.divisions) * this.max);

        const stats = await this.callStatsApi.getStatsByTime({
          peerId: this.peerId,
          conferenceId: this.conferenceId,
          participantId: this.participantId,
          startTime: Math.round(startTime / 1000),
          endTime: Math.round(endTime / 1000),
        });

        stats.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
        const combined: GraphModel = {} as GraphModel;

        stats.forEach(({ startTime: stt, endTime: ett, stats: statistics }) => {
          Object.entries(statistics).forEach(([graphName, series]) => {
            if (!combined[graphName]) {
              combined[graphName] = {};
            }
            Object.entries(series).forEach(([serieName, seriePoints]) => {
              const st = new Date(stt).getTime();
              const delta = (new Date(ett).getTime() - st) / (seriePoints.length > 1 ? seriePoints.length - 1 : 1);
              if (combined[graphName][serieName]) {
                combined[graphName][serieName].push(
                  ...seriePoints.map((p, i) => {
                    if (serieName === 'qualityLimitationDurations') {
                      Object.entries(p).forEach(([k, v]) => {
                        if (!combined[graphName][`${serieName}.${k}`]) {
                          combined[graphName][`${serieName}.${k}`] = [];
                        }
                        combined[graphName][`${serieName}.${k}`].push({ x: st + i * delta, y: v });
                      });
                    }
                    const pointYFromHello = p.toString().match(/^\d+$/);

                    return { x: st + i * delta, y: pointYFromHello ? +pointYFromHello[0] : p };
                  })
                );
              } else {
                combined[graphName][serieName] = seriePoints.map((p, i) => {
                  if (serieName === 'qualityLimitationDurations') {
                    Object.entries(p).forEach(([k, v]) => {
                      if (!combined[graphName][`${serieName}.${k}`]) {
                        combined[graphName][`${serieName}.${k}`] = [];
                      }
                      combined[graphName][`${serieName}.${k}`].push({ x: st + i * delta, y: v });
                    });
                  }
                  const pointYFromHello = p.toString().match(/^\d+$/);

                  return {
                    x: st + i * delta,
                    y: pointYFromHello ? +pointYFromHello[0] : p,
                  };
                });
              }
            });
          });
        });

        if (Object.keys(combined).length) {
          const convertedGraphs = this.loadCombinedGraphs(combined);

          if (!convertedGraphs.length) {
            return new ErrorState('No graphs are found through this interval...');
          }

          return new PeerConnectionStatsSuccessState(convertedGraphs);
        }

        return new PeerConnectionStatsSuccessState([]);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  private loadCombinedGraphs = (combined: GraphModel) => {
    const convertedGraphs = Object.entries(combined).map(([graphName, series]) => {
      return {
        name: graphName,
        series: Object.entries(series).map(([serieName, points]) => ({
          serieName,
          points,
        })),
      };
    });

    return convertedGraphs;
  };
}
