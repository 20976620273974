/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from '@components';
import { ApplicationPaths } from '@config';
import { LogoutActions, QueryParameterNames } from '@enums';
import authService, { AuthenticationResultStatus } from '../../services/Authorization/AuthorizationService.service';

export const Logout = ({ logoutAction }: { logoutAction: LogoutActions }) => {
  const [message, setMessage] = useState<string>('');
  const [isReady, setIsReady] = useState<boolean>(false);

  const populateAuthenticationState = async () => {
    await authService.isAuthenticated();
    setIsReady(true);
  };

  const getReturnUrl = (state: { returnUrl: string }) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.RETURN_URL);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationPaths.LOGGED_OUT}`;
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    return window.location.replace(returnUrl);
  };

  const logout = async (returnUrl: string) => {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const result = await authService.signOut(returnUrl);
      switch (result?.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMessage(result.message);
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    } else {
      setMessage('You successfully logged out!');
    }
  };

  const processLogoutCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        navigateToReturnUrl(getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(result.message);
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  };

  useEffect(() => {
    switch (logoutAction) {
      case LogoutActions.LOGOUT:
        logout(getReturnUrl({ returnUrl: '' }));
        break;
      case LogoutActions.LOGOUT_CALLBACK:
        processLogoutCallback();
        break;
      case LogoutActions.LOGGED_OUT:
        setMessage('You successfully logged out!');
        setIsReady(true);
        break;
      default:
        throw new Error(`Invalid action '${logoutAction}'`);
    }
    populateAuthenticationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) {
    return <div />;
  }

  if (message) {
    return <Navigate to='/' />;
  }

  switch (logoutAction) {
    case LogoutActions.LOGOUT:
      return <Loader className='absolute top-[40%] left-[48%]' />;
    case LogoutActions.LOGOUT_CALLBACK:
      return <Loader className='absolute top-[40%] left-[48%]' />;
    case LogoutActions.LOGGED_OUT:
      return <div>{message}</div>;
    default:
      throw new Error(`Invalid action '${logoutAction}'`);
  }
};
