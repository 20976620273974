import { ReactElement, useState } from 'react';
import { LineSeries } from 'react-jsx-highcharts';
import { ClickTypes } from '@enums';
import { SerieProps } from './Graphs.props';

export const GraphSerie = ({ serie }: SerieProps): ReactElement => {
  const [name, setName] = useState<string>('');
  const [seriePoints, setPoints] = useState<{ x: number; y: number | string }[]>();

  const handleSeriesClick = (event: { browserEvent: { detail: ClickTypes } }) => {
    if (event.browserEvent.detail === ClickTypes.DOUBLE_CLICK) {
      if (name.endsWith('/s')) {
        setPoints(serie.points);
        setName(serie.serieName);
      } else {
        setPoints(
          serie.points.map((point, index) => {
            const prev = serie.points[index === 0 ? 0 : index - 1];
            return { x: point.x, y: (+point.y - +prev.y) / ((point.x - prev.x) / 1000) };
          })
        );
        setName(`${serie.serieName}/s`);
      }
    }
  };

  return (
    <LineSeries
      className='dark:bg-transparent '
      visible={false}
      onLegendItemClick={handleSeriesClick}
      name={name || serie.serieName}
      data={[
        ...(seriePoints?.map(p => ({
          x: p.x,
          y: +p.y,
        })) || serie.points.map(s => ({ x: s.x, y: +s.y }))),
      ]}
    />
  );
};
