import { ReactElement, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';
import { Button, Col, Label, Row } from 'reactstrap';
import { Modal } from '@components';
import { EventsContext, ThemeContext } from '@context';
import { EventFiltersInputs } from '@interfaces';
import { EventsService, EventsState, SubjectsState, ThemeService, ThemeState, useService } from '@services';
import queryString from 'query-string';
import { EventsParticipant } from './EventsParticipant';

export const EventFilters = (): ReactElement => {
  const [modal, setModal] = useState<boolean>(false);
  const [, service] = useService<EventsState, EventsService>(EventsContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const navigate = useNavigate();

  const isDarkMode = themeService.theme.value === 'dark';

  const { register, handleSubmit, setValue, control, getValues } = useForm<EventFiltersInputs>({
    mode: 'onSubmit',
    defaultValues: { excludeWebRtcEvents: service.excludeWebRtcEvents.value, excludeLifesignalsEvents: service.excludeLifesignalsEvents.value },
  });
  const { timezone: timezoneVal } = useWatch<EventFiltersInputs>({ control });

  const handleApplyFilters = (data: EventFiltersInputs) => {
    if (getValues('searchQuery')) {
      service.pageIndex.next(0);
      navigate({
        search: queryString.stringify({
          pageIndex: service.pageIndex.value,
        }),
      });
    }
    service.applyFilters(data);
  };

  const handleShowParticipant = (participantId: string) => () => service.toggleParticipant(participantId);

  const handleChangeTimeZone = (timezone: ITimezoneOption) => setValue('timezone', { label: timezone.label, value: timezone.value });

  const handleSetModal = () => setModal(!modal);

  const getParticipantColums = () => {
    const numberOfParticipants = service.participants.value.length;
    if (numberOfParticipants === 1) {
      return 'repeat(1, minmax(0, 1fr))';
    }
    if (numberOfParticipants < 4) {
      return 'repeat(2, minmax(0, 1fr))';
    }
    return 'repeat(4, minmax(0, 1fr))';
  };

  return (
    <form onSubmit={handleSubmit(handleApplyFilters)}>
      <Row className='flex items-center justify-between mb-4 text-sm' md='12'>
        <Col md='2'>
          <button
            onClick={handleSetModal}
            type='button'
            className='w-full border-[1px] rounded-lg bg-sky-500 dark:bg-sky-900 py-2 text-white border-sky-400 dark:border-gray-700'>
            Select participants
          </button>
        </Col>
        <Col md='3'>
          <input
            type='search'
            id='text-input'
            placeholder='Search by event name, e.g. conference.participantJoined'
            className='px-3 py-2 border-[1px] rounded-lg w-full focus:outline-none focus:outline-1 dark:focus:outline-gray-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-700'
            {...register('searchQuery')}
          />
        </Col>
        <Col md='3'>
          <TimezoneSelect
            styles={
              isDarkMode
                ? {
                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                    menu: styles => ({
                      ...styles,
                      background: '#1e2022',
                      color: '#9ca3af',
                      border: '1px solid #6B7280',
                    }),
                    option: (styles, { isSelected }) => ({
                      ...styles,
                      ':hover': { background: '#374151' },
                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                    }),
                  }
                : {}
            }
            className='z-[40]'
            onChange={handleChangeTimeZone}
            value={!timezoneVal ? Intl.DateTimeFormat().resolvedOptions().timeZone : { label: timezoneVal.label || '', value: timezoneVal.value || '' }}
          />
        </Col>
        <Col md='3' className='flex items-center justify-evenly space-x-4'>
          <div className='flex flex-col space-y-1'>
            <div className='space-x-1 flex items-center'>
              <input type='checkbox' id='excludeWebRtcEvents' {...register('excludeWebRtcEvents')} />
              <Label check className='form-check-label dark:text-gray-400' htmlFor='excludeWebRtcEvents'>
                Hide WebRTC events
              </Label>
            </div>
            <div className='space-x-1 flex items-center'>
              <input type='checkbox' id='excludeGeneralEvents' {...register('excludeGeneralEvents')} />
              <Label check className='form-check-label dark:text-gray-400' htmlFor='excludeGeneralEvents'>
                Hide General events
              </Label>
            </div>
          </div>
          <div className='flex flex-col space-y-1'>
            <div className='space-x-1 flex items-center'>
              <input type='checkbox' id='excludeLifesignalsEvents' {...register('excludeLifesignalsEvents')} />
              <Label check className='form-check-label dark:text-gray-400' htmlFor='excludeLifesignalsEvents'>
                Hide Lifesignals events
              </Label>
            </div>
            <div className='space-x-1 flex items-center'>
              <input type='checkbox' id='groupByUser' {...register('groupByUser')} />
              <Label check className='form-check-label dark:text-gray-400' htmlFor='groupByUser'>
                Group by user
              </Label>
            </div>
          </div>
        </Col>
        <Col>
          <Button
            type='submit'
            color={isDarkMode ? '' : 'success'}
            className='w-full text-white text dark:bg-sky-900 dark:border-gray-700 dark:hover:bg-sky-700 dark:hover:border-gray-500'
            style={{ fontSize: '14px' }}>
            Apply Filters
          </Button>
        </Col>
      </Row>

      <Modal
        size='lg'
        modalClass='flex flex-col items-center'
        isOpen={modal}
        closeButtonText='Close'
        submitButtonText={service.participantIds.value.length === 0 ? 'Show All' : 'Hide All'}
        submitModal={service.toggleAllParticipants}
        closeModal={handleSetModal}>
        <h5 className='mb-3'>Select participants</h5>
        <SubjectsState
          subjects={[service.participants, service.participantIds]}
          render={() => (
            <Row style={{ display: 'grid', gridTemplateColumns: getParticipantColums(), gap: 16 }}>
              {service.participants.value.map(participant => {
                return (
                  <EventsParticipant
                    key={participant.id}
                    participant={participant}
                    handleShowParticipant={handleShowParticipant(participant.id)}
                    checked={service.participantIds.value.includes(participant.id)}
                  />
                );
              })}
            </Row>
          )}
        />
      </Modal>
    </form>
  );
};
