import { BehaviorSubject, merge } from 'rxjs';
import { Service } from '../Service';

type Themes = 'dark' | 'light';

export class ThemeState {}

export class ThemeService extends Service<ThemeState> {
  constructor() {
    super(new ThemeState());

    if (this.savedTheme) {
      window.localStorage.setItem('theme', this.savedTheme);
      this.theme.next(this.savedTheme as Themes);
      document.documentElement.classList.add(this.savedTheme === 'dark' ? 'dark' : 'light');
      document.documentElement.classList.remove(this.savedTheme === 'dark' ? 'light' : 'dark');
    }

    merge(this.theme).subscribe(state => this.next(state));
  }

  savedTheme = window.localStorage.getItem('theme');

  theme = new BehaviorSubject<Themes>(this.savedTheme as Themes);

  changeTheme = () => {
    if (this.theme.value === 'dark') {
      this.theme.next('light');
      window.localStorage.setItem('theme', 'light');
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
    } else {
      this.theme.next('dark');
      window.localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
    }
  };
}
