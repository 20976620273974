/**
 * @description Used to remove underscores and return the first letter as a capital letter when getting text from enums with index positions
 */

export const getLowerCaseString = (value: string) => {
  const result = `${value?.substring(0, 1).toUpperCase()}${value?.slice(1).toLowerCase()}`;

  return result.replaceAll('_', ' ');
};

/**
 * @description Used to remove underscores and return words with capital initials when getting text from enums with index positions
 */

export const getCapitalizedWords = (value: string) => {
  if (!value) {
    return '';
  }
  const words = value?.split('_').map(word => `${word?.substring(0, 1).toUpperCase()}${word?.slice(1).toLowerCase()}`);

  return words.join(' ');
};
