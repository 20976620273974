import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { Input, Label, Row } from 'reactstrap';
import { Slideover, SlideoverPosition } from '@components';
import { SelectValuesProps } from '@interfaces';
import { SubjectState } from '@services';
import { appTypeOptions, callTypeOptions, clientTypeOptions, conferenceStatus, endReasonOptions } from '@static';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { AdditionalFiltersProps } from './Conferences.props';

export const AdditionalFilters = ({
  service,
  slideover,
  setSlideover,
  handleApplyFilters,
  setIsOpen,
  isDarkMode,
  isOngoing,
  isOpen,
}: AdditionalFiltersProps): ReactElement => {
  const [error, setError] = useState<boolean>(false);
  const [slideoverPosition, setSlideoverPosition] = useState<SlideoverPosition>(window.localStorage.getItem('slideoverPosition') as SlideoverPosition);

  const sp = window.localStorage.getItem('slideoverPosition');

  const handleSetAppType = (values: SelectValuesProps) => {
    const val = Number(values?.value);
    service.appType.next(val === 0 ? 'All' : val);
  };

  const handleSetCallType = (values: SelectValuesProps) => {
    const val = Number(values?.value);
    service.callType.next(val === 0 ? 'All' : val);
  };
  const handleSetClientType = (values: SingleValue<{ value: number | 'All'; label: string }>) => service.clientType.next(values?.value ?? 'All');

  const handleSetConferenceStatus = (values: SelectValuesProps) => {
    const val = Number(values?.value);
    service.status.next(val === 0 ? 'All' : val);
  };

  const handleSetEndReason = (values: SingleValue<{ value: number | 'All'; label: string }>) => service.endReason.next(values?.value || 'All');
  const handleReset = () => {
    setIsOpen(!isOpen);
    setSlideover(!slideover);
  };
  const handleSetParticipantLength = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (!value.match(/^[0-9]*$/)) {
      setError(true);
    } else {
      setError(false);
    }
    service.participantLength.next(+value);
  };
  const handleSetSlideoverPosition = (value: SlideoverPosition) => {
    setSlideover(!slideover);
    setTimeout(() => {
      setSlideoverPosition(value);
      window.localStorage.setItem('slideoverPosition', slideoverPosition);
      setSlideover(true);
    }, 400);
  };

  useEffect(() => {
    if (sp) {
      window.localStorage.setItem('slideoverPosition', slideoverPosition);
    } else {
      window.localStorage.setItem('slideoverPosition', 'right');
    }
  }, [slideover, slideoverPosition, sp]);

  return (
    <Slideover
      onReset={handleReset}
      position={(slideoverPosition as SlideoverPosition) || 'right'}
      title='Conferences Filters'
      className='space-y-4 text-sm'
      isOpen={slideover}
      isSubmitButtonDisabled={error}
      setSlideoverPosition={handleSetSlideoverPosition}
      onClose={() => setSlideover(!slideover)}
      onApply={() => {
        handleApplyFilters();
        setSlideover(!slideover);
      }}>
      <Row>
        <SubjectState
          subject={service.appType}
          render={value => (
            <>
              <Label className='dark:text-gray-200'>App Types: </Label>
              <Select
                styles={
                  isDarkMode
                    ? {
                        control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                        singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                        menu: styles => ({
                          ...styles,
                          background: '#1e2022',
                          color: '#9ca3af',
                          border: '1px solid #6B7280',
                        }),
                        option: (styles, { isSelected }) => ({
                          ...styles,
                          ':hover': { background: '#374151' },
                          backgroundColor: isSelected ? '#2684FF' : 'transparent',
                        }),
                        input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                      }
                    : {}
                }
                defaultValue={appTypeOptions[0]}
                options={appTypeOptions}
                value={appTypeOptions.find(appType => value === +appType.value)}
                onChange={handleSetAppType}
              />
            </>
          )}
        />
      </Row>
      <Row>
        <SubjectState
          subject={service.callType}
          render={value => (
            <>
              <Label className='dark:text-gray-200'>Call Types: </Label>
              <Select
                styles={
                  isDarkMode
                    ? {
                        control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                        singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                        menu: styles => ({
                          ...styles,
                          background: '#1e2022',
                          color: '#9ca3af',
                          border: '1px solid #6B7280',
                        }),
                        option: (styles, { isSelected }) => ({
                          ...styles,
                          ':hover': { background: '#374151' },
                          backgroundColor: isSelected ? '#2684FF' : 'transparent',
                        }),
                        input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                      }
                    : {}
                }
                defaultValue={callTypeOptions[0]}
                options={callTypeOptions}
                value={callTypeOptions.find(callType => value === +callType.value)}
                onChange={handleSetCallType}
              />
            </>
          )}
        />
      </Row>
      {!isOngoing && (
        <Row>
          <SubjectState
            subject={service.status}
            render={value => (
              <>
                <Label className='dark:text-gray-200'>Status: </Label>
                <Select
                  styles={
                    isDarkMode
                      ? {
                          control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                          singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                          menu: styles => ({
                            ...styles,
                            background: '#1e2022',
                            color: '#9ca3af',
                            border: '1px solid #6B7280',
                          }),
                          option: (styles, { isSelected }) => ({
                            ...styles,
                            ':hover': { background: '#374151' },
                            backgroundColor: isSelected ? '#2684FF' : 'transparent',
                          }),
                          input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                        }
                      : {}
                  }
                  defaultValue={conferenceStatus[0]}
                  options={conferenceStatus}
                  value={conferenceStatus.find(status => value === +status.value)}
                  onChange={handleSetConferenceStatus}
                />
              </>
            )}
          />
        </Row>
      )}
      {!isOngoing && (
        <Row>
          <SubjectState
            subject={service.clientType}
            render={value => (
              <>
                <Label className='dark:text-gray-200'>Client Type: </Label>
                <Select
                  styles={
                    isDarkMode
                      ? {
                          control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                          singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                          menu: styles => ({
                            ...styles,
                            background: '#1e2022',
                            color: '#9ca3af',
                            border: '1px solid #6B7280',
                          }),
                          option: (styles, { isSelected }) => ({
                            ...styles,
                            ':hover': { background: '#374151' },
                            backgroundColor: isSelected ? '#2684FF' : 'transparent',
                          }),
                          input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                        }
                      : {}
                  }
                  placeholder='Client Type'
                  options={clientTypeOptions}
                  defaultValue={clientTypeOptions.find(ct => ct.value === 'All')}
                  value={clientTypeOptions.find(client => client.value !== null && value === +client.value)}
                  onChange={handleSetClientType}
                />
              </>
            )}
          />
        </Row>
      )}
      {!isOngoing && (
        <Row>
          <SubjectState
            subject={service.endReason}
            render={value => (
              <>
                <Label className='dark:text-gray-200'>End Reason: </Label>
                <Select
                  styles={
                    isDarkMode
                      ? {
                          control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                          singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                          menu: styles => ({
                            ...styles,
                            background: '#1e2022',
                            color: '#9ca3af',
                            border: '1px solid #6B7280',
                          }),
                          option: (styles, { isSelected }) => ({
                            ...styles,
                            ':hover': { background: '#374151' },
                            backgroundColor: isSelected ? '#2684FF' : 'transparent',
                          }),
                          input: styles => ({ ...styles, color: isDarkMode ? '#9ca3af' : '#000' }),
                        }
                      : {}
                  }
                  placeholder='End Reason'
                  options={endReasonOptions}
                  defaultValue={endReasonOptions[0]}
                  value={endReasonOptions.find(reason => value === reason.value)}
                  onChange={handleSetEndReason}
                />
              </>
            )}
          />
        </Row>
      )}
      {!isOngoing && (
        <Row className='!px-[36px]'>
          <SubjectState
            subject={service.participantLength}
            render={value => (
              <>
                <div className='px-0'>
                  <Label className='dark:text-gray-200 px-0'>Number of participants: </Label>
                  <Input
                    id='search'
                    className={classNames('dark:bg-dark dark:border-gray-500 w-full dark:text-gray-400', isDarkMode ? 'search-input' : '')}
                    style={{ fontSize: '14px' }}
                    onChange={handleSetParticipantLength}
                    defaultValue={value && value !== 0 ? value : ''}
                    type='text'
                    invalid={value !== null && !value?.toString().match(/^[0-9]*$/)}
                    placeholder='Number of participants'
                  />
                </div>
                <AnimatePresence>
                  {value !== null && !value?.toString().match(/^[0-9]*$/) && (
                    <motion.p
                      key='number-of-participants'
                      className='text-sm font-normal text-red-400 mt-2 px-0'
                      initial='hidden'
                      exit='exit'
                      whileInView='visible'
                      variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 }, exit: { opacity: 0 } }}
                      transition={{ duration: 0.3 }}>
                      Number of participants must be a number
                    </motion.p>
                  )}
                </AnimatePresence>
              </>
            )}
          />
        </Row>
      )}
      {!isOngoing && (
        <Row>
          <SubjectState
            subject={service.isUsingMediaServer}
            render={value => (
              <>
                <Label className='dark:text-gray-200 mb-0'>Is Using Media Server:</Label>
                <div className='flex items-center space-x-2 mt-2'>
                  <div className='flex items-center space-x-2'>
                    <Label className='dark:text-gray-200 px-0 mb-0'>True:</Label>
                    <Input className='dark:checked:bg-sky-500' type='checkbox' onChange={() => service.isUsingMediaServer.next(true)} checked={!!value} />
                  </div>
                  <div className='flex items-center space-x-2'>
                    <Label className='dark:text-gray-200 px-0 mb-0'>False:</Label>
                    <Input
                      className='dark:checked:bg-sky-500'
                      type='checkbox'
                      onChange={() => service.isUsingMediaServer.next(false)}
                      checked={value === false}
                    />
                  </div>
                  <div className='flex items-center space-x-2'>
                    <Label className='dark:text-gray-200 px-0 mb-0'>None:</Label>
                    <Input
                      className='dark:checked:bg-sky-500'
                      type='checkbox'
                      onChange={() => service.isUsingMediaServer.next(null)}
                      checked={value === null}
                    />
                  </div>
                </div>
              </>
            )}
          />
        </Row>
      )}
    </Slideover>
  );
};
