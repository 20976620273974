/* eslint-disable @typescript-eslint/no-explicit-any */

export const findLabelByValue = (array: any[], value: string) => {
  const found = array.find(item => item.value?.toString() === value);
  return found?.label ?? null;
};

const parseArrayString = (array: any[], inputStr: string) => {
  const itemIds = JSON.parse(inputStr);
  if (itemIds.length === 0) {
    return 'No';
  }
  const mappedItems = itemIds.map((id: string) => {
    const found = array.find(item => item.value === id);
    return found?.label ?? null;
  });
  return mappedItems.join(', ');
};

const transformCustomString = (inputStr: string, array: any[]) => {
  const mapping = array.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  return inputStr
    .split('-')
    .map(item => mapping[item] || item)
    .join(', ');
};

export const parseInputString = (array: any[], inputStr: string) => {
  if (inputStr.startsWith('[') && inputStr.endsWith(']')) {
    return parseArrayString(array, inputStr);
  }
  if (inputStr.includes('-')) {
    return transformCustomString(inputStr, array);
  }
  return findLabelByValue(array, inputStr);
};
