import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Loader } from '@components';
import { PatientReportContext, ThemeContext } from '@context';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { joiResolver } from '@hookform/resolvers/joi';
import { PatientReportPayload } from '@interfaces';
import {
  ErrorState,
  LoadingState,
  PatientReportService,
  PatientReportState,
  PatientReportSuccessState,
  SubjectState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { PatientReportSchema } from '@validators';
import queryString from 'query-string';

export const PatientReport = () => {
  const [state, service] = useService<PatientReportState, PatientReportService>(PatientReportContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const isDarkMode = themeService.theme.value === 'dark';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PatientReportPayload>({
    defaultValues: {
      tenantId: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(PatientReportSchema()),
  });

  const handleSetSelectedOrganization = (newValue: SingleValue<{ label: string; value: string }>) => {
    setValue('tenantId', newValue?.value || '');
    service.selectedOrganization.next(newValue);
  };

  const onSubmit = (data: PatientReportPayload) => {
    service.patientMrn.next(data.patientMrn);
    service.downloadCounter.next(service.downloadCounter.getValue() + 1);
    navigate({
      search: `${queryString.stringify({
        tenantId: data.tenantId,
        patientMrn: data.patientMrn,
      })}`,
    });
  };

  useEffect(() => {
    if (queryParams.tenantId || queryParams.patientMrn) {
      setValue('tenantId', queryParams.tenantId?.toString() || '');
      setValue('patientMrn', queryParams.patientMrn?.toString() || '');
    }
  }, [queryParams.tenantId, queryParams.patientMrn]);

  const isFinishedState = state instanceof ErrorState || state instanceof PatientReportSuccessState;

  return (
    <Card className='dark:bg-dark dark:border dark:border-gray-700'>
      <CardHeader className='flex items-center dark:bg-dark-body dark:text-gray-300 dark:border-b-gray-700' style={{ fontSize: '14px' }}>
        Patient Report
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SubjectState
            subject={service.organizations}
            render={orgs => {
              const organizations = orgs.map(x => {
                return { value: x.id, label: x.name };
              });
              organizations.unshift({ value: '', label: 'Select an organization' });
              return (
                <>
                  <Row className='flex items-center flex-events text-sm last:items-end' md='12'>
                    <Col md='3'>
                      <SubjectState
                        subject={service.selectedOrganization}
                        render={selectedOrg => (
                          <Select
                            styles={
                              isDarkMode
                                ? {
                                    control: styles => ({ ...styles, background: '#1e2022', color: '#9ca3af', borderColor: '#6B7280' }),
                                    singleValue: styles => ({ ...styles, color: '#9ca3af' }),
                                    menu: styles => ({
                                      ...styles,
                                      background: '#1e2022',
                                      color: '#9ca3af',
                                      border: '1px solid #6B7280',
                                    }),
                                    option: (styles, { isSelected }) => ({
                                      ...styles,
                                      ':hover': { background: '#374151' },
                                      backgroundColor: isSelected ? '#2684FF' : 'transparent',
                                    }),
                                  }
                                : {}
                            }
                            defaultValue={selectedOrg ?? organizations[0]}
                            options={organizations}
                            isSearchable
                            value={organizations.find(organization => selectedOrg?.value === organization.value)}
                            onChange={handleSetSelectedOrganization}
                          />
                        )}
                      />
                    </Col>
                    <Col md='3'>
                      <SubjectState
                        subject={service.patientMrn}
                        render={val => (
                          <input
                            id='patientMrn'
                            className='px-3 py-2 border-[1px] rounded-lg w-full focus:outline-gray-200 dark:bg-dark dark:text-gray-400 dark:border-gray-700 dark:autofill:bg-dark'
                            placeholder='Patient MRN'
                            defaultValue={val}
                            {...register('patientMrn')}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <button
                        type='submit'
                        className='bg-[#20a8d8] dark:bg-sky-900 border-[1px] rounded-lg py-2 px-3 text-white dark:border-gray-700 dark:hover:bg-sky-800'>
                        Download CSV
                      </button>
                    </Col>
                  </Row>
                  {errors && (
                    <Row className='flex items-center mt-1 last:items-end'>
                      <Col md='3' className='w-[400px]'>
                        {errors.tenantId && <p className='mb-0 text-sm text-red-500 px-1'>{errors.tenantId.message}</p>}
                      </Col>
                      <Col md='3'>{errors.patientMrn && <p className='mb-0 text-sm text-red-500 px-1'>{errors.patientMrn.message}</p>}</Col>
                    </Row>
                  )}
                  <Row>
                    {state instanceof LoadingState && (
                      <Col>
                        <Loader />
                      </Col>
                    )}
                    {isFinishedState && (
                      <Col>
                        <div className='flex items-center space-x-2 justify-center my-10'>
                          {state instanceof ErrorState && (
                            <>
                              <ExclamationCircleIcon className='w-5 h-5 text-red-500' />
                              <p className='mb-0 text-red-500'>{state.errorMessage || 'Something went wrong. Try again later.'}</p>
                            </>
                          )}
                          {state instanceof PatientReportSuccessState && (
                            <>
                              <ExclamationCircleIcon className='w-5 h-5 dark:text-gray-400' />
                              <p className='mb-0 dark:text-gray-400'>To access and download the report, please continue on your email address.</p>
                            </>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              );
            }}
          />
        </form>
      </CardBody>
    </Card>
  );
};
