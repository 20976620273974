import { gatewayApi } from '@config';
import {
  AiAlerts,
  AIDetectionsApiProps,
  AIDetectionsTokenProps,
  AIStatisticsApiProps,
  DeviceFromRoomModel,
  DeviceIdResponse,
  Frame,
  HealthSystemsModel,
  RoomModel,
  UpdateAlertStatusResponse,
} from '@interfaces';
import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import qs from 'query-string';

export class AIDetectionsApi implements AIDetectionsApiProps, Pick<AIStatisticsApiProps, 'getHealthSystems' | 'getRoomsByLevelId'> {
  private getToken = async (): Promise<AIDetectionsTokenProps> => {
    const { data }: AxiosResponse<AIDetectionsTokenProps> = await gatewayApi.get(`v1/azure-storage/data-acquisition/sas-token?isAnonymizerBlob=true`);
    return {
      containerUri: data.containerUri,
      sasToken: data.sasToken.substring(1), // remove ? from token
    };
  };

  private createFileListUrl = (prefix: string, blobUrl: string, token: string): string => {
    const encodedPrefix = encodeURIComponent(prefix);
    const listUrl = `${blobUrl}?restype=container&comp=list&prefix=${encodedPrefix}&${token}`;
    return listUrl;
  };

  private createFileDownloadUrl = (filepath: string, blobUrl: string, token: string): string => `${blobUrl}/${filepath}?${token}`;

  private createImageUrl = (imagepath: string, blobUrl: string, token: string): string => `${blobUrl}/${imagepath}?${token}`;

  makeRequestsInBatches = async (files: string[], batchSize: number, token: AIDetectionsTokenProps) => {
    const results = [];
    for (let i = 0; i < files?.length; i += batchSize) {
      const batchUrls = files?.slice(i, i + batchSize);
      const batchPromises = batchUrls.map(url => axios.get(this.createFileDownloadUrl(url || '', token.containerUri, token.sasToken)));
      try {
        // eslint-disable-next-line no-await-in-loop
        const batchResulsts = await Promise.all(batchPromises);
        results.push(...batchResulsts.map(response => response.data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({ error });
      }
    }

    return results;
  };

  getFilesList = async (
    from: Date,
    to: Date,
    tenantId: string,
    helloSN: string
  ): Promise<{ matchingFiles: string[]; availableImages: Map<string, string> }> => {
    const token = await this.getToken();
    const daysDiff = Math.ceil((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
    const filesListPromise = [];

    for (let day = 0; day < daysDiff; day += 1) {
      const date = new Date(from);
      date.setDate(date.getDate() + day);
      const dateFolderName = moment(date).format('YYYY-MM-DD');
      const prefixLogs = `AiLogs/${tenantId}/${helloSN}/logs/${dateFolderName}`;
      const prefixFrames = `AiLogs/${tenantId}/${helloSN}/blurred/${dateFolderName}`;
      const listUrlLogs = this.createFileListUrl(prefixLogs, token.containerUri, token.sasToken);
      const listUrlFrames = this.createFileListUrl(prefixFrames, token.containerUri, token.sasToken);
      filesListPromise.push(axios.get(listUrlLogs));
      filesListPromise.push(axios.get(listUrlFrames));
    }

    const filesListResults = await Promise.all(filesListPromise);
    const parser = new DOMParser();
    const availableImages = new Map<string, string>();
    const resultingFiles = filesListResults
      .map((xml: { data: string }) => {
        const doc = parser.parseFromString(xml.data, 'text/xml');
        const blobs = doc.getElementsByTagName('Name');
        const jsonArr = [];
        const jsonMap = new Map();
        for (let i = 0; i < blobs.length; i += 1) {
          const it = blobs.item(i)?.textContent;
          if (it && it.endsWith('.json')) {
            jsonArr.push(blobs.item(i)?.textContent);
          } else if (it && it.endsWith('.json.gz')) {
            jsonArr.push(blobs.item(i)?.textContent?.replace('.gz', ''));
            jsonMap.set(it, blobs.item(i)?.textContent);
          } else if (it && it.endsWith('.jpg')) {
            const imageUrl = it;
            const imageFile = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            availableImages.set(imageFile, imageUrl);
          }
        }
        return jsonArr.map(f => jsonMap.get(`${f}.gz`) ?? f);
      })
      .reduce((sum, blobs) => {
        blobs.forEach(b => sum.push(b));
        return sum;
      }, []);

    const matchingFiles = resultingFiles.filter(f => {
      const parts = f?.split('/');
      if (parts) {
        const day = parts[parts.length - 2];
        const time = parts[parts.length - 1].replace('.json', '').replace('.gz', '');
        const sampleTime = moment.utc(`${day} ${time}`, 'YYYY-MM-DD HH-mm-ss.SSS').toDate();
        return sampleTime.getTime() > from.getTime() && sampleTime.getTime() < to.getTime();
      }
      return [];
    });

    return { matchingFiles, availableImages };
  };

  getDeviceId = async (helloDeviceSerialNumber: string, companyId: string): Promise<DeviceIdResponse> => {
    const { data }: AxiosResponse<DeviceIdResponse> = await gatewayApi.get(`v1/devices/${helloDeviceSerialNumber}/companies/${companyId}`);

    return data;
  };

  getAlerts = async (
    solHelloDeviceId: number,
    from: string,
    to: string,
    pageIndex: number,
    pageSize?: number | undefined,
    alertTypes?: string[]
  ): Promise<AiAlerts> => {
    const { data }: AxiosResponse<AiAlerts, AxiosError> = await gatewayApi.get(`/v1/alerts/devices/${solHelloDeviceId}/ai`, {
      params: {
        'from-date': from,
        'to-date': to,
        'page-index': pageIndex,
        'page-size': pageSize,
        'alert-types': alertTypes,
      },
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params);
        },
      },
    });

    return data;
  };

  updateAlertStatus = async (aiAlertId: string, status: number): Promise<UpdateAlertStatusResponse> => {
    const { data } = await gatewayApi.put(`/v1/alerts/ai/${aiAlertId}/status`, {
      status,
    });

    return data;
  };

  getFrames = async (files: string[], availableImages: Map<string, string>, batchSize = 200) => {
    const token = await this.getToken();
    const newFiles = await this.makeRequestsInBatches(files, batchSize, token);
    const frames = newFiles.reduce((arr, f) => {
      arr.push(
        ...f.frames.map((fr: Frame) => ({
          ...fr,
          ...{
            imageUrl: availableImages.has(fr.imageFile) ? this.createImageUrl(availableImages.get(fr.imageFile) || '', token.containerUri, token.sasToken) : '',
          },
          info: f.info,
        }))
      );
      return arr;
    }, []);

    return frames;
  };

  getHealthSystems = async (companyId: string): Promise<HealthSystemsModel> => {
    const { data }: AxiosResponse<HealthSystemsModel> = await gatewayApi.get(`v1/organizations/${companyId}/health-systems?roleId=2`);

    return data;
  };

  getRoomsByLevelId = async (companyId: string, levelId: string, level = 0): Promise<RoomModel> => {
    const { data }: AxiosResponse<RoomModel> = await gatewayApi.get(`v1/organizations/${companyId}/level/${level}/id/${levelId}/rooms`);

    return data;
  };

  getDeviceFromRoom = async (companyId: string, levelType: number, levelId: string): Promise<DeviceFromRoomModel> => {
    const { data }: AxiosResponse<DeviceFromRoomModel> = await gatewayApi.get(`v1/organizations/${companyId}/level/${levelType}/id/${levelId}/devices`);

    return data;
  };
}
