import { ReactElement } from 'react';
import { matchPath, NavLink, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import classNames from 'classnames';
import { BreadcrumbsProps } from './Breadcrumbs.props';

export const Breadcrumbs = ({ className, breadcrumbs, propsParams }: BreadcrumbsProps): ReactElement => {
  const { pathname } = useLocation();
  const params = useParams();

  const matchRoutes = (path: string) => {
    const match = matchPath({ path, caseSensitive: true, end: false }, pathname);

    return !!match;
  };

  const getCrumbs = () => {
    const crumbs: { path: string; label: string }[] = [];
    // Get all routes that contain the current one.
    // Swap out any dynamic routes with their param values.
    // E.g. "/conferences/:conferenceId" will become "/conferences/e32501d4-8621-4a9c-b8ef-27ba26509fa0"
    breadcrumbs.reduce(
      (_, cur) => {
        if (matchPath({ path: cur.path, caseSensitive: true, end: false }, pathname)) {
          crumbs.push({
            path: Object.keys(params).length
              ? Object.keys(params).reduce((newPath, param) => `${newPath.replace(`:${param}`, params[param] || '')}`, cur.path)
              : cur.path,
            label: cur.label,
          });
        }
        return cur;
      },
      { path: '', label: '' }
    );

    return crumbs;
  };

  const propsParamsArray = Object.entries(propsParams || {}).map(([key, value]) => {
    return `${key}=${value}`;
  });

  return (
    <Breadcrumb className={className}>
      {getCrumbs().map(
        ({ path, label }) =>
          matchRoutes(path) && (
            <BreadcrumbItem active={pathname === path} key={path}>
              <NavLink
                className={classNames(
                  'text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 no-underline text-sm',
                  path === pathname ? 'text-blue-600 dark:text-sky-500 dark:hover:text-sky-600' : ''
                )}
                to={`${path}${propsParams ? `?${propsParamsArray.join('&')}` : ''}`}>
                {label}
              </NavLink>
            </BreadcrumbItem>
          )
      )}
    </Breadcrumb>
  );
};
