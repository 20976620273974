import { ReactElement } from 'react';
import { Modal } from '@components';
import { EventDetailsProps } from './Events.props';

export const EventDetails = ({ details, eventKey, handleToggleDetails, showDetails }: EventDetailsProps): ReactElement => (
  <Modal size='lg' isOpen={showDetails} closeModal={handleToggleDetails} closeButtonText='Close'>
    <div className='w-full'>
      <p className='text-sm text-center'>
        Details for event <strong>{eventKey || ''}</strong>
      </p>
      <pre className='w-full text-sm'>{details}</pre>
    </div>
  </Modal>
);
