import { ParticipantsApi } from '@api';
import { ParticipantModel, PeerProps } from '@interfaces';
import { AxiosError } from 'axios';
import { from, Observable } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class SingleParticipantState {}

export class SingleParticipantSucessState extends SingleParticipantState {
  constructor(participant: ParticipantModel, peerParticipants: PeerProps) {
    super();
    this.participant = participant;
    this.peerParticipants = peerParticipants;
  }

  participant: ParticipantModel;

  peerParticipants: PeerProps;
}

export class SingleParticipantService extends Service<SingleParticipantState> {
  constructor(participantsApi: ParticipantsApi, isOngoing: boolean, conferenceId: string, participantId: string) {
    super(new LoadingState());

    this.participantsApi = participantsApi;

    this.isOngoing = isOngoing;

    this.participantId = participantId;

    this.conferenceId = conferenceId;

    this.collect().subscribe((state: SingleParticipantState) => this.next(state));
  }

  isOngoing: boolean;

  participantsApi: ParticipantsApi;

  participantId: string;

  conferenceId: string;

  collect = (): Observable<SingleParticipantState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        const peerParticipants = await this.participantsApi.getParticipantPeers(this.participantId, this.conferenceId);
        if (this.isOngoing) {
          const ongoingSingleParticipant = await this.participantsApi.getOngoingConferenceParticipant(this.participantId, this.conferenceId);

          return new SingleParticipantSucessState(ongoingSingleParticipant, peerParticipants);
        }
        const singleParticipant = await this.participantsApi.getConferenceParticipant(this.participantId);

        return new SingleParticipantSucessState(singleParticipant, peerParticipants);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };
}
