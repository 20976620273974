import { FallDetectonIcon, GettingOutOfBedIcon, RailsIcon } from '@components';
import { AiAlertTypeCounts } from '@enums';
import { PhotoIcon } from '@heroicons/react/24/solid';

export const getAlertIcon = (alertType: number) => {
  switch (alertType) {
    case AiAlertTypeCounts.fallDetected:
      return <FallDetectonIcon />;
    case AiAlertTypeCounts.railDown:
      return <RailsIcon />;
    case AiAlertTypeCounts.patientGettingOutOfBed:
      return <GettingOutOfBedIcon />;
    default:
      return <PhotoIcon className='w-6 h-6 text-white' />;
  }
};
