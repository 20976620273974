export const getAIConfigDuration = (value: string) => {
  const numValue = Number(value);

  if (numValue < 60) {
    return `Configured for ${numValue} seconds`;
  }
  if (numValue >= 60 && numValue <= 3600) {
    return `Configured from ${numValue} minutes`;
  }

  return `Configured for ${numValue} hours`;
};
