import { ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Table } from 'reactstrap';
import { EmptyState, Error, Loader, Modal } from '@components';
import { ParticipantsContext } from '@context';
import { CheckCircleIcon, UsersIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { ErrorState, LoadingState, ParticipantsService, ParticipantsState, ParticipantsSuccessState, SubjectState, useService } from '@services';
import { pastParticipantsHeaders } from '@static';
import { ParticipantsItem } from './ParticipantItem';

export const Participants = (): ReactElement => {
  const [state, service] = useService<ParticipantsState, ParticipantsService>(ParticipantsContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { conferenceId } = useParams();

  const isOngoing = pathname.includes('ongoing');
  const isSuccessState = state instanceof ParticipantsSuccessState;
  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;
  const isEmpty = !isLoading && !isError && isSuccessState && state.participants.length === 0;

  const handleOpenTerminateModal = (participantId: string) => () => {
    service.participantId.next(participantId);
    service.isTerminating.next(true);
  };
  const handleGoToParticipant = (id: string) => () => navigate(`/conferences/${service.isOngoing ? 'ongoing' : 'past'}/${conferenceId}/participants/${id}`);
  const handleCloseModal = () => {
    service.participantId.next('');
    service.isTerminating.next(false);
  };
  const handleCloseTerminatedModal = () => {
    service.participantId.next('');
    service.isTerminated.next(false);
    service.collect().subscribe((participantsState: ParticipantsState) => service.next(participantsState));
  };
  const handleTerminate = () => service.terminateConferenceCall();

  return (
    <>
      <Card className='dark:bg-dark dark:border dark:border-gray-700' style={{ border: 0 }}>
        <CardBody>
          <Table responsive>
            <thead className='dark:border-gray-700 dark:text-[#989082]'>
              <tr className='text-sm'>
                {isOngoing
                  ? pastParticipantsHeaders.map(header => <th key={header}>{header}</th>)
                  : pastParticipantsHeaders.map(header => <th key={header}>{header}</th>).slice(0, -1)}
              </tr>
            </thead>
            <tbody className='dark:text-gray-300 dark:border-gray-700'>
              {isEmpty && (
                <EmptyState className='text-center w-full' as='tr'>
                  <td className='bg-[#fff] dark:bg-dark' colSpan={14}>
                    <UsersIcon className='text-gray-600 dark:text-gray-400 w-8 h-8 mx-auto' />
                    <span className='font-medium text-sm dark:text-gray-400'>No participants in this conference.</span>
                  </td>
                </EmptyState>
              )}
              {isLoading && (
                <tr>
                  <td colSpan={11}>
                    <Loader />
                  </td>
                </tr>
              )}
              {isError && (
                <tr>
                  <td colSpan={11}>
                    <Error message={state.errorMessage} />
                  </td>
                </tr>
              )}
              {isSuccessState &&
                state.participants.map(participant => (
                  <ParticipantsItem
                    key={participant.id}
                    participant={participant}
                    conferenceId={conferenceId || ''}
                    isOngoing={isOngoing}
                    handleOpenTerminateModal={handleOpenTerminateModal(participant.id)}
                    handleGoToParticipant={handleGoToParticipant(participant.id)}
                  />
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <SubjectState
        subject={service.isTerminating}
        render={isTerminating => (
          <Modal
            isOpen={isTerminating}
            isLoading={service.isLoading.value}
            icon={
              <div className='bg-red-200 rounded-full p-2'>
                <ExclamationTriangleIcon className='w-10 h-10 text-red-600' />
              </div>
            }
            closeModal={handleCloseModal}
            submitModal={handleTerminate}
            submitButtonText='End Call'
            closeButtonText='Cancel'
            color='danger'>
            <div className='flex flex-col'>
              <p className='text-lg font-semibold mb-0'>End Call?</p>
              <p className='mb-0'>This will end the call for the participant.</p>
            </div>
          </Modal>
        )}
      />

      <SubjectState
        subject={service.isTerminated}
        render={isTerminated => (
          <Modal
            isOpen={isTerminated}
            submitButtonText='Close'
            submitModal={handleCloseTerminatedModal}
            closeModal={handleCloseTerminatedModal}
            icon={
              <div className='bg-green-200 rounded-full p-2'>
                <CheckCircleIcon className='w-10 h-10 text-green-600' />
              </div>
            }>
            <div className='flex flex-col'>
              <p className='text-lg font-semibold mb-0 dark:text-gray-300'>End Call</p>
              <p className='mb-0'>Call ended successfully.</p>
            </div>
          </Modal>
        )}
      />
    </>
  );
};
