import { ReactElement, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { CSVExportProps } from './CSVExport.props';

export const CSVExport = ({ data, children, filename, getCSVData, disable, setCsvData }: CSVExportProps): ReactElement => {
  const csvLink = document.getElementById('csv-link');

  const exportData = async () => {
    if (disable) {
      return;
    }
    if (getCSVData) {
      const newCsvData = await getCSVData();
      setCsvData(newCsvData);
    }
  };

  useEffect(() => {
    if (data.length !== 0 && csvLink) {
      csvLink.click();
    }
  }, [data, csvLink]);

  return (
    <>
      <div onClick={exportData}>{children}</div>
      {data && <CSVLink id='csv-link' data={data} filename={filename} />}
    </>
  );
};
