import { forwardRef, ReactElement } from 'react';
import { useOnClickOutside } from '@hooks';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { DropdownProps } from './Dropdown.props';

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({ onClose, className, children, ...rest }, ref): ReactElement => {
  useOnClickOutside(ref, onClose);

  return (
    <motion.div {...rest} ref={ref} className={classNames('dropdown', className)}>
      {children}
    </motion.div>
  );
});

Dropdown.displayName = 'Dropdown';
