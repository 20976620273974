import { Context, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

export class Service<T> extends Subject<T> {
  state: T;

  constructor(state: T) {
    super();
    this.state = state;
    this.next(state);
  }

  next(state: T) {
    this.state = state;
    super.next(state);
  }
}

export const ServiceState = <T>({ service, render }: { service: Service<T>; render: (state: T) => ReactNode }) => {
  const [state, setState] = useState(service.state);

  useEffect(() => {
    const subscription = service.subscribe(setState);
    return () => subscription.unsubscribe();
  }, [service]);

  return render(state);
};

export const useService = <T, E extends Service<T>>(serviceContext: Context<E>): [T, E] => {
  const service = useContext(serviceContext);
  const [state, setState] = useState(service.state);
  useEffect(() => {
    const subscription = service.subscribe(setState);
    return () => subscription.unsubscribe();
  }, [service]);

  return [state, service];
};

export const SubjectState = <T>({ subject, render }: { subject: BehaviorSubject<T>; render: (state: T) => ReactElement }) => {
  const [state, setState] = useState(subject.getValue());

  useEffect(() => {
    const subscription = subject.subscribe(setState);
    return () => subscription.unsubscribe();
  }, [subject]);

  return render(state);
};

// In this case there's really no clear way of telling which type we'll pass into
// the array of dependencies. This is the onlt exception where it's always going to be any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SubjectsState = ({ subjects, render }: { subjects: BehaviorSubject<any>[]; render: () => ReactElement }) => {
  const [, setState] = useState(null);

  useEffect(() => {
    const subscriptions = subjects.map(s => s.subscribe(setState));
    return () => {
      subscriptions.map(s => s.unsubscribe());
    };
  }, [subjects]);

  return render();
};

export const useSubject = <T>(serviceContext: Context<BehaviorSubject<T>>): [T, BehaviorSubject<T>] => {
  const service = useContext(serviceContext);
  const [state, setState] = useState(service.getValue());
  useEffect(() => {
    const subscription = service.subscribe(setState);
    return () => subscription.unsubscribe();
  }, [service]);

  return [state, service];
};
