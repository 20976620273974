import { ConferencesFeedbackApi } from '@api';
import { ConferenceFeedbacksModel } from '@interfaces';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, debounceTime, from, merge, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../../Helpers/Error.service';
import { LoadingState } from '../../Helpers/Loading.service';
import { Service } from '../../Service';

export class ConferenceFeedbacksState {}

export class ConferenceFeedbacksSuccessState extends ConferenceFeedbacksState {
  constructor(feedbacks: ConferenceFeedbacksModel) {
    super();
    this.feedbacks = feedbacks;
  }

  feedbacks: ConferenceFeedbacksModel;
}

export class ConferenceFeedbacksService extends Service<ConferenceFeedbacksState> {
  constructor(confereConferencesFeedbackApi: ConferencesFeedbackApi) {
    super(new ConferenceFeedbacksState());
    this.confereConferencesFeedbackApi = confereConferencesFeedbackApi;

    merge(this.pageIndex)
      .pipe(debounceTime(200))
      .pipe(switchMap(this.collect))
      .subscribe((state: ConferenceFeedbacksState) => this.next(state));
  }

  confereConferencesFeedbackApi: ConferencesFeedbackApi;

  start = new BehaviorSubject<Moment>(moment().startOf('days'));

  end = new BehaviorSubject<Moment>(moment().endOf('days'));

  conferenceId = new BehaviorSubject<string>('');

  rate = new BehaviorSubject<{ label: string; value: string | 'All' }>({ label: 'All Ratings', value: 'All' });

  pageIndex = new BehaviorSubject<number>(0);

  collect = (): Observable<ConferenceFeedbacksState | ErrorState> => {
    this.next(new LoadingState());

    const getState = async () => {
      try {
        const feedbacks = await this.confereConferencesFeedbackApi.getConferencesFeedbacks({
          conferenceId: this.conferenceId.value,
          start: this.start.value,
          end: this.end.value,
          rate: this.rate.value.value || 'All',
          pageIndex: this.pageIndex.value,
          pageSize: 10,
        });

        return new ConferenceFeedbacksSuccessState(feedbacks);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };
}
