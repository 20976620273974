import { createContext, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CallStatsApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { PeerConnectionStatsService } from '@services';

const callStatsApi = new CallStatsApi();

export const PeerConnectionStatsContext = createContext<PeerConnectionStatsService>({} as PeerConnectionStatsService);

export const PeerConnectionStatsProvider = ({ children, isOngoing }: ContextProviderProps): ReactElement => {
  const { conferenceId, participantId, peerId } = useParams();
  const peerStatsService = useMemo(
    () => new PeerConnectionStatsService(callStatsApi, isOngoing ?? false, conferenceId || '', participantId || '', peerId || ''),
    [isOngoing]
  );

  return <PeerConnectionStatsContext.Provider value={peerStatsService}>{children}</PeerConnectionStatsContext.Provider>;
};
