import { ReactElement } from 'react';
import classNames from 'classnames';

export const LoaderSpinner = ({ className }: { className?: string }): ReactElement => (
  <div className='flex justify-center'>
    <svg
      className={classNames('animate-spin w-6 h-6 rounded-[50%] text-gray-700', className)}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle className='opacity-25' cx='12' cy='12' r='10' stroke='#fff' strokeWidth='4' />
      <path
        className='opacity-75'
        fill='#fff'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      />
      <span className='sr-only'>Loading...</span>
    </svg>
  </div>
);
