import { gatewayApi } from '@config';
import { PatientReportModel, PatientReportPayload, PatientReportProps } from '@interfaces';
import { AxiosResponse } from 'axios';

export class PatientReportApi implements PatientReportProps {
  getCsvReport = async (payload: PatientReportPayload): Promise<PatientReportModel> => {
    const { data }: AxiosResponse<PatientReportModel> = await gatewayApi.get(`v1/tasks/organizations/${payload.tenantId}/patient-report/${payload.patientMrn}`);

    return data;
  };
}
