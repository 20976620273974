import { ParticipantsApi } from '@api';
import { ParticipantModel } from '@interfaces';
import { AxiosError } from 'axios';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class ParticipantsState {}

export class ParticipantsSuccessState extends ParticipantsState {
  constructor(participants: ParticipantModel[]) {
    super();
    this.participants = participants;
  }

  participants: ParticipantModel[];
}

export class ParticipantsService extends Service<ParticipantsState> {
  constructor(participantsApi: ParticipantsApi, isOngoing: boolean, conferenceId: string) {
    super(new LoadingState());

    this.participantsApi = participantsApi;

    this.isOngoing = isOngoing;

    this.conferenceId = conferenceId;

    this.collect().subscribe((state: ParticipantsState) => this.next(state));
  }

  participantsApi: ParticipantsApi;

  isOngoing: boolean;

  conferenceId: string;

  participantId = new BehaviorSubject<string>('');

  isTerminating = new BehaviorSubject<boolean>(false);

  isLoading = new BehaviorSubject<boolean>(false);

  isTerminated = new BehaviorSubject<boolean>(false);

  terminateConferenceMessage = new BehaviorSubject<string>('');

  collect = (): Observable<ParticipantsState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        if (this.isOngoing) {
          const ongoingParticipants = await this.participantsApi.getOngoingConferenceParticipants(this.conferenceId);
          return new ParticipantsSuccessState(ongoingParticipants);
        }
        const participants = await this.participantsApi.getConferenceParticipants(this.conferenceId);
        return new ParticipantsSuccessState(participants);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  terminateConferenceCall = async () => {
    this.isTerminating.next(true);
    try {
      const isEnded = await this.participantsApi.terminateParticipantCall(this.participantId.value);
      if (isEnded) {
        this.isTerminating.next(false);
        this.isTerminated.next(true);
      }
    } catch (error: unknown) {
      this.terminateConferenceMessage.next((error as AxiosError).message);
    }
  };
}
