import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { DefaultLayout } from '@containers';
import { SingleConferenceProvider } from '@context';
import { breadcrumbRoutes } from '@static';
import { SingleConference } from './SingleConference';
import { SingleConferenceTabs } from './SingleConferenceTabs';

export const SingleConferenceContainer = (): ReactElement => {
  const { pathname } = useLocation();

  const isOngoing = pathname.includes('ongoing');

  return (
    <DefaultLayout breadcrumbs={breadcrumbRoutes.conferences}>
      <SingleConferenceProvider isOngoing={isOngoing}>
        <SingleConference />
        <SingleConferenceTabs />
      </SingleConferenceProvider>
    </DefaultLayout>
  );
};
