export const getRatingColor = (index: number): string => {
  switch (index) {
    case 1:
      return '#ff4545';
    case 2:
      return '#ffa534';
    case 3:
      return '#ffe234';
    case 4:
      return '#b7dd29';
    case 5:
      return '#57e32c';
    default:
      return '#fff';
  }
};
