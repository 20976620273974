import { ReactElement, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { SlideoverProps } from './Slideover.props';

export const Slideover = ({
  position = 'right',
  title,
  isOpen,
  className,
  children,
  submitButtonText = 'Apply',
  closeButtonText = 'Reset',
  onClose,
  onApply,
  onReset,
  setSlideoverPosition,
  isSubmitButtonDisabled,
  ...rest
}: SlideoverProps): ReactElement => {
  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            key='backdrop'
            className='fixed top-0 left-0 bottom-0 right-0 opacity-50 z-[1050]'
            initial={{ opacity: 0 }}
            whileInView={{ background: '#000', opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            onClick={onClose}
          />
          <motion.div
            layout
            key='slideover'
            initial='hidden'
            whileInView='visible'
            exit='exit'
            variants={{
              exit: { x: position === 'right' ? 460 : -460 },
              visible: { x: [position === 'right' ? 460 : -460, 0] },
              hidden: { x: position === 'right' ? 460 : -460 },
            }}
            viewport={{ once: true }}
            transition={{ staggerChildren: 0.2, delayChildren: 0.2, duration: 0.3, ease: 'easeInOut' }}
            className={classNames(
              'fixed top-0 bottom-0 bg-[#fff] z-[1060] dark:bg-dark pt-4 dark:text-gray-200 w-[460px] flex flex-col slideover overflow-y-auto overflow-x-hidden',
              position === 'right' ? 'right-0' : 'left-0',
              className
            )}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onApply();
              }
              if (event.key === 'Escape') {
                onClose();
              }
            }}
            {...rest}>
            <div className='flex items-center justify-between'>
              <h3>{title}</h3>
              <motion.button
                whileHover={{ boxShadow: '0 0 0 2px gray', borderRadius: '50%', transition: { duration: 0.2 } }}
                whileTap={{ scale: 0.9, transition: { duration: 0.1, staggerChildren: 0.2 } }}
                onClick={onClose}
                type='button'>
                <XMarkIcon className='text-gray-400 w-8 h-8' />
              </motion.button>
            </div>
            {children as ReactNode}
            <div className='flex items-center justify-between py-3 border-t border-gray-300 dark:border-gray-500 bg-gray-200 dark:bg-dark-body w-full'>
              <div className='flex items-center space-x-4'>
                <button
                  type='button'
                  disabled={isSubmitButtonDisabled}
                  style={{ fontSize: 14 }}
                  onClick={onApply}
                  className='bg-[#20a8d8] dark:bg-sky-900 border-[1px] dark:border-gray-700 rounded-lg py-2 px-3 text-[#fff] dark:text-gray-300 disabled:opacity-75'>
                  {submitButtonText}
                </button>
                <button
                  onClick={onReset}
                  style={{ fontSize: 14 }}
                  type='button'
                  className='bg-[#fff] dark:bg-transparent text-[#000] dark:text-gray-400 py-2 px-3 rounded-md border-gray-400 border-[1px] dark:border-gray-700'>
                  {closeButtonText}
                </button>
              </div>
              <motion.div
                className='cursor-pointer h-full flex items-center'
                transition={{ staggerChildren: 0.2, delayChildren: 0.2, duration: 0.3, ease: 'easeInOut' }}
                initial={{}}
                whileHover={{ transform: position === 'right' ? 'rotateY(180deg)' : 'rotateY(-180deg)' }}
                exit={{ transform: position === 'right' ? 'rotateY(-180deg)' : 'rotateY(180deg)' }}
                key='change-position'>
                {position === 'right' ? (
                  <ArrowRightOnRectangleIcon
                    id='slideover-button'
                    onClick={() => {
                      if (setSlideoverPosition) setSlideoverPosition('left');
                    }}
                    className='w-6 h-6 text-gray-400 '
                  />
                ) : (
                  <ArrowLeftOnRectangleIcon
                    id='slideover-button'
                    onClick={() => {
                      if (setSlideoverPosition) setSlideoverPosition('right');
                    }}
                    className='w-6 h-6 text-gray-400 '
                  />
                )}

                <UncontrolledTooltip target='slideover-button' delay={{ show: 0.1, hide: 0.1 }}>
                  Set slider to the {position === 'right' ? 'left' : 'right'}
                </UncontrolledTooltip>
              </motion.div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    document.getElementById('slideover') as HTMLElement
  );
};
