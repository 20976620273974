import { ReactElement } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { DropdownItemProps } from './Dropdown.props';

export const DropdownItem = ({ onItemClick, children, className, disabled, ...rest }: DropdownItemProps): ReactElement => {
  return (
    <motion.div
      className={classNames('px-2', { 'opacity-50 !cursor-not-allowed': disabled }, className)}
      variants={{
        closed: { x: -16, opacity: 0 },
        open: { x: 0, opacity: 1 },
      }}
      transition={{ opacity: { duration: 0.1 } }}
      onClick={() => {
        if (disabled) {
          return;
        }
        onItemClick();
      }}
      {...rest}>
      {children}
    </motion.div>
  );
};
