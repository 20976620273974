import { Error, Loader } from '@components';
import { SingleConferenceContext } from '@context';
import { CallTypeLabels, ConferenceEndReasonLabels, ConferenceStatus } from '@enums';
import { ArrowsPointingInIcon, CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { ArrowsPointingOutIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { ErrorState, LoadingState, SingleConferenceService, SingleConferenceState, SingleConferenceSuccessState, useService } from '@services';
import { singleOnoingConferenceTableHeaders, singlePastConferenceTableHeaders } from '@static';
import { getCapitalizedWords, getDurationBetween, getLowerCaseString, utcToLocalDateTime } from '@utils';
import { ReactElement, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Table, UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

export const SingleConference = (): ReactElement => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [state, service] = useService<SingleConferenceState, SingleConferenceService>(SingleConferenceContext);

  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;
  const isSuccessState = state instanceof SingleConferenceSuccessState;

  const handleSetCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Card className='dark:bg-dark dark:border dark:border-gray-700'>
      <CardHeader className='dark:bg-dark-body flex items-center justify-between space-x-3 dark:text-gray-300 dark:border-b-gray-700'>
        <div className='flex items-center'>
          <PhoneIcon className='w-4 h-4 text-gray-500 dark:text-gray-300 mr-3' />
          <p className='mb-0 text-sm'>Conferences</p>
        </div>
        <Button onClick={handleSetCollapsed} color='white'>
          {!collapsed && <ArrowsPointingInIcon className='text-gray-900 dark:text-gray-300 w-6 h-6' />}
          {collapsed && <ArrowsPointingOutIcon className='text-gray-900 dark:text-gray-300 w-6 h-6' />}
        </Button>
      </CardHeader>
      {!collapsed && (
        <CardBody>
          <Table responsive>
            <thead>
              <tr className='text-sm  dark:border-gray-700 dark:text-[#989082]'>
                {!service.isOngoing
                  ? singlePastConferenceTableHeaders.map(header => <th key={uuidv4()}>{header}</th>)
                  : singleOnoingConferenceTableHeaders.map(header => <th key={uuidv4()}>{header}</th>)}
              </tr>
            </thead>
            <tbody className='dark:text-gray-300 dark:border-gray-700'>
              <tr className='text-sm'>
                {isLoading && (
                  <td colSpan={14}>
                    <Loader />
                  </td>
                )}
                {isError && (
                  <td colSpan={14}>
                    <Error message={state.errorMessage} />
                  </td>
                )}
                {isSuccessState && typeof state.conference === 'undefined' && (
                  <td colSpan={14}>
                    <Error message="Couldn't load data for this conference." />
                  </td>
                )}
                {isSuccessState && !!state.conference && (
                  <>
                    <td>{state.conference.id}</td>
                    <td>
                      {state.conference.participants.find(participant => participant.isInitiator)?.name}{' '}
                      {state.conference.hasErrors && (
                        <>
                          <Badge pill id={`HasError_${state.conference.id}`} className='ml-2' color='danger'>
                            !
                          </Badge>
                          <UncontrolledTooltip placement='top' target={`HasError_${state.conference.id}`}>
                            This conference has errors
                          </UncontrolledTooltip>
                        </>
                      )}
                    </td>
                    <td>{getCapitalizedWords(CallTypeLabels[state.conference.callType])}</td>
                    <td>{utcToLocalDateTime(state.conference.startedAt)}</td>
                    {!service.isOngoing && <td>{utcToLocalDateTime(state.conference.endedAt)}</td>}
                    {!service.isOngoing && <td>{getDurationBetween(state.conference.startedAt, state.conference.endedAt)}</td>}
                    {!service.isOngoing && <td>{getLowerCaseString(ConferenceEndReasonLabels[state.conference.endReason])}</td>}
                    {!service.isOngoing && <td>{getLowerCaseString(ConferenceStatus[state.conference.status])}</td>}
                    <td align='center'>
                      {state.conference?.whiteboardId ? (
                        <CheckCircleIcon className='w-6 h-6 text-green-400 ' />
                      ) : (
                        <MinusCircleIcon className='w-6 h-6 text-gray-400' />
                      )}
                    </td>
                    <td align='center'>
                      {state.conference?.usedAudio ? (
                        <CheckCircleIcon className='w-6 h-6 text-green-400 ' />
                      ) : (
                        <MinusCircleIcon className='w-6 h-6 text-gray-400' />
                      )}
                    </td>
                    <td align='center'>
                      {state.conference?.usedVideo ? (
                        <CheckCircleIcon className='w-6 h-6 text-green-400' />
                      ) : (
                        <MinusCircleIcon className='w-6 h-6 text-gray-400' />
                      )}
                    </td>
                    <td align='center'>
                      {state.conference?.usedScreen ? (
                        <CheckCircleIcon className='w-6 h-6 text-green-400' />
                      ) : (
                        <MinusCircleIcon className='w-6 h-6 text-gray-400' />
                      )}
                    </td>
                    {!service.isOngoing && (
                      <td align='center'>
                        {state.conference?.isUsingMediaServer ? (
                          <CheckCircleIcon className='w-6 h-6 text-green-400 text-right flex' />
                        ) : (
                          <MinusCircleIcon className='w-6 h-6 text-gray-400 mr-0 flex justify-end items-end' />
                        )}
                      </td>
                    )}
                    {!service.isOngoing && <td align='center'>{state.conference.rate ? `${state.conference.rate} / 5` : 'N/A'}</td>}
                  </>
                )}
              </tr>
            </tbody>
          </Table>
        </CardBody>
      )}
    </Card>
  );
};
