import { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import { DefaultLayout } from '@containers';
import { FailuresProvider, FailuresTimelineProvider } from '@context';
import { breadcrumbRoutes } from '@static';
import { FailuresGraph } from './FailuresGraph';
import { FailuresTable } from './FailuresTable';

export const Failures = (): ReactElement => {
  return (
    <DefaultLayout breadcrumbs={breadcrumbRoutes.investigate}>
      <FailuresTimelineProvider>
        <FailuresProvider>
          <Row>
            <Col className='space-y-10'>
              <FailuresGraph />
              <FailuresTable />
            </Col>
          </Row>
        </FailuresProvider>
      </FailuresTimelineProvider>
    </DefaultLayout>
  );
};
