export const PatientAiSettingTypes = {
  rails: 1,
  patientSitting: 2,
  patientStanding: 3,
  patientFaceDown: 4,
  patientLayingSide: 5,
  patientLayingFront: 6,
  babyFaceDown: 7,
  fallDetection: 8,
  pressureInjury: 9,
  gettingOutOfBed: 10,
  privacyMode: 11,
  patientMobility: 12,
  babyCrying: 13,
  aiVitalSignsAlerts: 14,
  handWash: 15,
  ivBagFluidLevel: 16,
  aiDetectionsDrawings: 17,
  aiAlertNotificationSound: 18,
};

export const PatientAiSettingsTypesLables = {
  [PatientAiSettingTypes.rails]: 'Rails',
  [PatientAiSettingTypes.patientSitting]: 'Patient Sitting',
  [PatientAiSettingTypes.patientStanding]: 'Patient Standing',
  [PatientAiSettingTypes.patientFaceDown]: 'Patient Face Down',
  [PatientAiSettingTypes.patientLayingSide]: 'Patient Laying Side',
  [PatientAiSettingTypes.patientLayingFront]: 'Patient Laying Front',
  [PatientAiSettingTypes.babyFaceDown]: 'Baby Face Down',
  [PatientAiSettingTypes.fallDetection]: 'Fall Detection',
  [PatientAiSettingTypes.pressureInjury]: 'Pressure Injury',
  [PatientAiSettingTypes.gettingOutOfBed]: 'Getting Out of Bed',
  [PatientAiSettingTypes.privacyMode]: 'Privacy Mode',
  [PatientAiSettingTypes.patientMobility]: 'Patient Mobility',
  [PatientAiSettingTypes.babyCrying]: 'Baby Crying',
  [PatientAiSettingTypes.aiVitalSignsAlerts]: 'AI Vital Signs Alerst',
  [PatientAiSettingTypes.handWash]: 'Hand Wash',
  [PatientAiSettingTypes.ivBagFluidLevel]: 'IV Bag Fluid Level',
  [PatientAiSettingTypes.aiDetectionsDrawings]: 'AI Detection Drawings',
  [PatientAiSettingTypes.aiAlertNotificationSound]: 'AI Alert Notification Sound',
};
