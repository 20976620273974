import { createContext, ReactElement, useMemo } from 'react';
import { FailuresApi } from '@api';
import { ContextProviderProps } from '@interfaces';
import { FailuresDetailsService, FailuresService, FailuresTimelineService } from '@services';

const failuresApi = new FailuresApi();

export const failuresContext = createContext<FailuresService>({} as FailuresService);

export const failuresTimelineContext = createContext<FailuresTimelineService>({} as FailuresTimelineService);

export const failuresDetailsContext = createContext<FailuresDetailsService>({} as FailuresDetailsService);

export const FailuresProvider = ({ children }: ContextProviderProps): ReactElement => {
  const failuresService = useMemo(() => new FailuresService(failuresApi), []);

  return <failuresContext.Provider value={failuresService}>{children}</failuresContext.Provider>;
};

export const FailuresTimelineProvider = ({ children }: ContextProviderProps): ReactElement => {
  const failuresTimelineService = useMemo(() => new FailuresTimelineService(failuresApi), []);

  return <failuresTimelineContext.Provider value={failuresTimelineService}>{children}</failuresTimelineContext.Provider>;
};

export const FailuresDetailsProvider = ({ children }: ContextProviderProps): ReactElement => {
  const failuresDetailsService = useMemo(() => new FailuresDetailsService(failuresApi), []);

  return <failuresDetailsContext.Provider value={failuresDetailsService}>{children}</failuresDetailsContext.Provider>;
};
