import { gatewayApi } from '@config';
import { EventsApiProps, EventsPayload, EventsResponseModel } from '@interfaces';
import { AxiosResponse } from 'axios';

export class EventsApi implements EventsApiProps {
  getConferenceEvents = async (conferenceId: string, payload: Partial<EventsPayload>): Promise<EventsResponseModel> => {
    const { data }: AxiosResponse<EventsResponseModel> = await gatewayApi.post(`v1/conferences/${conferenceId}/events`, { ...payload });

    return data;
  };
}
