import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@components';
import { ParticipantStateEnums, SessionType } from '@enums';
import { CheckCircleIcon, MinusCircleIcon, StarIcon } from '@heroicons/react/24/outline';
import { PhoneXMarkIcon } from '@heroicons/react/24/solid';
import { customDateFormat, getDurationBetween, getLowerCaseString, getRatingColor } from '@utils';
import { ParticipantsItemProps } from './Participants.props';

export const ParticipantsItem = ({
  participant,
  conferenceId,
  isOngoing,
  handleOpenTerminateModal,
  handleGoToParticipant,
}: ParticipantsItemProps): ReactElement => {
  const [modal, setModal] = useState<boolean>(false);

  const handleSetModal = () => setModal(!modal);

  const getSessionType = (sessionType: SessionType) => {
    switch (sessionType) {
      case 0:
        return 'Video Monitoring';
      case 1:
        return 'Ambient Monitoring';
      case 2:
        return 'Video and Ambient Monitoring';
      default:
        return '';
    }
  };

  return (
    <>
      <tr className='text-sm hover:cursor-pointer dark:hover:bg-dark-body' key={participant.id} onClick={handleGoToParticipant}>
        <td>
          <Link
            className='no-underline text-sky-400 hover:text-sky-600'
            to={`/conferences/${isOngoing ? 'ongoing' : 'past'}/${conferenceId}/participants/${participant.id}`}>
            {participant.fullName}
          </Link>
        </td>
        <td>{getSessionType(participant.sessionType)}</td>
        <td>{participant.device}</td>
        <td>{participant.ipAddress}</td>
        <td>{getLowerCaseString(ParticipantStateEnums[participant.state])}</td>
        <td>{customDateFormat(participant.joinTime, 'MM/DD/YYYY - HH:mm:ss')}</td>
        <td>{participant.establishedTime ? customDateFormat(participant.establishedTime, 'MM/DD/YYYY - HH:mm:ss') : 'N/A'}</td>
        <td>{participant.endedTime ? customDateFormat(participant.endedTime, 'MM/DD/YYYY - HH:mm:ss') : 'N/A'}</td>
        <td>
          {participant.establishedTime && participant.endedTime
            ? getDurationBetween(new Date(participant.establishedTime), new Date(participant.endedTime))
            : 'N/A'}
        </td>
        <td>{participant.appVersion}</td>
        <td align='center'>
          {participant.usedAudio ? <CheckCircleIcon className='w-6 h-6 text-green-400 ' /> : <MinusCircleIcon className='w-6 h-6 text-gray-300' />}
        </td>
        <td align='center'>
          {participant.usedVideo ? <CheckCircleIcon className='w-6 h-6 text-green-400 ' /> : <MinusCircleIcon className='w-6 h-6 text-gray-300' />}
        </td>
        <td align='center'>
          {participant.usedScreen ? <CheckCircleIcon className='w-6 h-6 text-green-400 ' /> : <MinusCircleIcon className='w-6 h-6 text-gray-300' />}
        </td>
        <td align='center'>
          {participant.rate && (
            <div className='flex items-center'>
              {[1, 2, 3, 4, 5]
                .map(item => (
                  <StarIcon
                    key={item}
                    className='w-5 h-5'
                    fill={(participant.rate || 0) >= item ? getRatingColor(participant.rate || 0) : '#fff'}
                    stroke={getRatingColor(participant.rate || 0)}
                  />
                ))
                .reverse()}
              {participant.feedbackComment && (
                <button type='button' className='text-sm text-white ml-5 px-2 py-0 bg-sky-500 rounded-md' onClick={handleSetModal}>
                  Show comment
                </button>
              )}
            </div>
          )}
          {!participant.rate && 'N/A'}
        </td>
        {isOngoing && (
          <td align='center'>
            <button
              type='button'
              className='disabled:cursor-not-allowed disabled:opacity-50'
              onClick={event => {
                event.stopPropagation();
                handleOpenTerminateModal();
              }}>
              <PhoneXMarkIcon className='w-6 h-6 text-red-400' />
            </button>
          </td>
        )}
      </tr>

      <Modal isOpen={modal} closeModal={handleSetModal} closeButtonText='Close'>
        <div className='flex flex-col space-y-4'>
          <p className='text-lg font-semibold mb-0'>{participant.fullName}&apos;s comment</p>
          <p className='mb-0'>{participant.feedbackComment || ''}</p>
        </div>
      </Modal>
    </>
  );
};
