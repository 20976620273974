import { ReactElement } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { EventFilters } from './EventFilters';
import { EventHeaderActions } from './EventHeaderActions';
import { Events } from './Events';

export const EventsContainer = (): ReactElement => (
  <Card className='dark:bg-dark dark:border dark:border-gray-700'>
    <CardHeader className='dark:bg-dark-body flex items-center justify-between text-sm dark:text-gray-300 dark:border-gray-700'>
      Filters
      <EventHeaderActions />
    </CardHeader>
    <CardBody>
      <EventFilters />
      <Events />
    </CardBody>
  </Card>
);
