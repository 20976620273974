import moment, { Moment } from 'moment';

export const getDateRanges = (customRange: { start: Moment | string; end: Moment | string }) => {
  const queryParams = new URLSearchParams(window.location.search);

  return {
    start: moment.utc(queryParams.get('start') ?? customRange.start),
    end: moment.utc(queryParams.get('end') ?? customRange.end),
  };
};
