import { ReactElement } from 'react';
import { Button, Modal as RModal, ModalBody } from 'reactstrap';
import { LoaderSpinner } from '@components';
import classNames from 'classnames';
import { ModalProps } from './Modal.props';

export const Modal = ({
  isOpen,
  isLoading,
  size = 'md',
  children,
  closeModal,
  modalClass = '',
  closeButtonText,
  submitModal,
  icon,
  color = 'primary',
  submitButtonText,
}: ModalProps): ReactElement => (
  <RModal size={size} isOpen={isOpen} toggle={closeModal}>
    <ModalBody className='w-full dark:bg-dark dark:text-gray-400 dark:border dark:border-gray-700 dark:rounded-t-md'>
      <div className={classNames('flex items-start space-x-4 py-3', modalClass)}>
        {icon}
        {children}
      </div>
    </ModalBody>
    <div className='flex items-center justify-end rounded-b-md space-x-4 w-full bg-gray-100 dark:bg-dark dark:border-x dark:border-b dark:border-gray-700 p-2.5'>
      {closeButtonText && (
        <Button style={{ fontSize: '14px' }} className='bg-[#fff] dark:bg-transparent text-[#000] dark:text-gray-400' outline onClick={closeModal}>
          {closeButtonText}
        </Button>
      )}

      {submitButtonText && (
        <Button
          color={color}
          className={classNames(
            color !== 'danger' && 'dark:bg-sky-900 dark:border-gray-700 dark:hover:bg-sky-700 dark:hover:border-gray-500',
            isLoading ? 'py-0.5 px-3 text' : '',
            'text-sm '
          )}
          style={{
            color: color === 'danger' ? '#fff' : '',
          }}
          onClick={submitModal}>
          {!isLoading && submitButtonText}
          {isLoading && <LoaderSpinner />}
        </Button>
      )}
    </div>
  </RModal>
);
