import { createContext, ReactElement, useMemo } from 'react';
import { ContextProviderProps } from '@interfaces';
import { ThemeService } from '@services';

export const ThemeContext = createContext({} as ThemeService);

export const ThemeProvider = ({ children }: ContextProviderProps): ReactElement => {
  const themeService = useMemo(() => new ThemeService(), []);

  return <ThemeContext.Provider value={themeService}>{children}</ThemeContext.Provider>;
};
