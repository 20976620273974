import { ReactElement } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { utcToDateTime } from '@utils';
import classNames from 'classnames';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { NewEventsScrollItemProps } from '../Events.props';

export const NewEventsScrollItem = ({ event, handleShowDetails, service }: NewEventsScrollItemProps): ReactElement => {
  return (
    <ul className='text-sm space-y-2 p-3 mb-0'>
      <li className='flex justify-between space-x-2 items-center'>
        <button
          type='button'
          className={classNames('flex items-center space-x-3', event.detail ? 'cursor-pointer active:translate-y-1 active:shadow-md' : 'cursor-default')}
          onClick={handleShowDetails}>
          <span className='flex items-center font-semibold text-gray-900 dark:text-gray-300 border border-sky-400 rounded-lg py-1 px-2 bg-gray-100 dark:bg-dark-body'>
            {event.eventKey}
            {event.detail && (
              <div id={event.eventKey.replaceAll('.', '-')} className='ml-2'>
                <InformationCircleIcon className='w-6 h-6 text-sky-400' />
                <UncontrolledTooltip target={event.eventKey.replaceAll('.', '-')}>Show details</UncontrolledTooltip>
              </div>
            )}
          </span>
        </button>
        <span className='font-medium dark:text-gray-300'>{`${utcToDateTime(event.timestamp, service.timezone.value.value)}.${moment(
          event.timestamp
        ).milliseconds()}`}</span>
      </li>

      <li className='dark:text-gray-300'>{event.description}</li>
      {event.data && event.data.length && (
        <>
          {event.data.map(data => (
            <li key={uuidv4()} className='mb-0 dark:text-gray-300'>
              <strong>{data.item1}</strong> : {data.item2}
              <br />
            </li>
          ))}
        </>
      )}
    </ul>
  );
};
