import { ReactElement, ReactNode } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { DropdownTriggerProps } from './Dropdown.props';

export const DropdownTrigger = ({ onShow, className, children, icon, ...rest }: DropdownTriggerProps): ReactElement => {
  return (
    <motion.div className={classNames('cursor-pointer', className)} onClick={onShow} {...rest}>
      {children as ReactNode}
      {icon && <ChevronDownIcon className='w-4 h-4 ml-1 dark:text-[#9197a0] text-[#000]' />}
    </motion.div>
  );
};
