import { ReactElement } from 'react';
import { Loader } from '@components';
import { DashboardContext } from '@context';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { DashboardOverviewState, DashboardService, DashboardSuccessState, ErrorState, LoadingState, useService } from '@services';

export const Duration = (): ReactElement => {
  const [state] = useService<DashboardOverviewState, DashboardService>(DashboardContext);

  if (state instanceof DashboardSuccessState && state.duration instanceof ErrorState) {
    return (
      <div className='flex justify-center items-center'>
        <ExclamationCircleIcon className='w-10 h-10 text-red-600 dark:text-red-400' />
        <p className='mb-0 ml-1 text-sm dark:text-[#989082]'>{state.duration.errorMessage}</p>
      </div>
    );
  }

  if (state instanceof LoadingState) {
    return <Loader />;
  }

  return (
    <div>
      <div className='grid grid-cols-2 text-center text-sm dark:text-[#989082]'>
        {state instanceof DashboardSuccessState && !(state.duration instanceof ErrorState) && (
          <>
            <div>
              Total Duration
              <p className='font-semibold dark:text-gray-300'>{state.duration.duration}</p>
            </div>
            <div>
              Avg Duration
              <p className='font-semibold dark:text-gray-300'>{state.duration.avgDuration}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
