import { Data } from 'react-csv/components/CommonPropTypes';
import { ConferencesApi, getOrganizations } from '@api';
import { ConferenceSearchType } from '@enums';
import { ConferencesModel, Nullable, OrganizationProps } from '@interfaces';
import { startOfDay } from '@static';
import { utcToLocalDateTime } from '@utils';
import axios, { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, debounceTime, from, merge, Observable, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

export class ConferencesState {}

export class ConferencesSuccessState extends ConferencesState {
  constructor(conferences: ConferencesModel) {
    super();

    this.conferences = conferences;
  }

  conferences: ConferencesModel;
}

export class ConferencesService extends Service<ConferencesState> {
  constructor(conferencesApi: ConferencesApi, isOngoing: boolean) {
    super(new ConferencesState());

    this.conferencesApi = conferencesApi;

    this.isOngoing = isOngoing;

    getOrganizations()
      .then(organizations => this.organizations.next(organizations))
      .catch((error: unknown) => new ErrorState((error as AxiosError).message));

    merge(this.pageIndex)
      .pipe(debounceTime(50))
      .pipe(switchMap(this.collect))
      .subscribe((state: ConferencesState) => this.next(state));
  }

  conferencesApi: ConferencesApi;

  cancelToken = axios.CancelToken.source();

  isOngoing: boolean;

  isLoading = new BehaviorSubject<boolean>(false);

  isTerminating = new BehaviorSubject<boolean>(false);

  isTerminated = new BehaviorSubject<boolean>(false);

  showDropdown = new BehaviorSubject<boolean>(false);

  showFilters = new BehaviorSubject<boolean>(true);

  terminateConferenceMessage = new BehaviorSubject<string>('');

  conferenceId = new BehaviorSubject<string>('');

  searchBy = new BehaviorSubject<{ value: ConferenceSearchType; label: string }>({ value: 1, label: 'Conference Id' });

  searchValue = new BehaviorSubject<string>('');

  appType = new BehaviorSubject<number | 'All'>('All');

  callType = new BehaviorSubject<number | 'All'>('All');

  status = new BehaviorSubject<number | 'All'>('All');

  clientType = new BehaviorSubject<number | 'All'>('All');

  endReason = new BehaviorSubject<number | 'All'>(0);

  participantLength = new BehaviorSubject<Nullable<number>>(null);

  isUsingMediaServer = new BehaviorSubject<Nullable<boolean>>(null);

  start = new BehaviorSubject<Moment>(moment(startOfDay).startOf('day'));

  end = new BehaviorSubject<Moment>(moment(startOfDay).endOf('day'));

  pageIndex = new BehaviorSubject<number>(0);

  pageSize = new BehaviorSubject<number>(10);

  organizations = new BehaviorSubject<OrganizationProps[]>([]);

  selectedOrganization = new BehaviorSubject<string>('All');

  timezone = new BehaviorSubject<string>('');

  collect = (): Observable<ConferencesState> => {
    this.next(new LoadingState());
    const getState = async () => {
      try {
        if (this.isOngoing) {
          const state = await this.getOngoingConferences();

          return new ConferencesSuccessState(state.conferences);
        }
        const state = await this.getPastConferences();

        return new ConferencesSuccessState(state.conferences);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  terminateConferenceCall = async () => {
    this.isTerminating.next(true);
    this.isLoading.next(true);
    try {
      const isEnded = await this.conferencesApi.terminateConferenceCall(this.conferenceId.value);
      if (isEnded) {
        this.isTerminating.next(false);
        this.isTerminated.next(true);
        this.isLoading.next(false);
      }
    } catch (error: unknown) {
      this.terminateConferenceMessage.next((error as AxiosError).message);
    }
  };

  getPastConferences = async () => {
    const pastConferences = await this.conferencesApi.getPastConferences(
      {
        appType: this.appType.value,
        callType: this.callType.value,
        end: this.end.value,
        start: this.start.value,
        organizationId: this.selectedOrganization.value,
        pageIndex: this.pageIndex.value,
        pageSize: this.pageSize.value,
        searchBy: this.searchBy.value.value,
        searchVal: this.searchValue.value,
        status: this.status.value,
        endReason: this.endReason.value || 'All',
        ...(this.isUsingMediaServer.value !== null ? { isUsingMediaServer: this.isUsingMediaServer.value } : {}),
        ...(this.clientType.value !== 'All' ? { clientType: this.clientType.value } : {}),
        ...(this.participantLength.value && this.participantLength.value !== 0 ? { participantLength: this.participantLength.value } : {}),
      },
      this.cancelToken.token
    );

    return new ConferencesSuccessState(pastConferences);
  };

  getOngoingConferences = async () => {
    const ongoingConferences = await this.conferencesApi.getOngoingConferences({
      appType: this.appType.value,
      callType: this.callType.value,
      end: this.end.value,
      start: this.start.value,
      organizationId: this.selectedOrganization.value,
      pageIndex: this.pageIndex.value,
      pageSize: 10,
      searchBy: this.searchBy.value.value,
      searchVal: this.searchValue.value,
      ...(this.clientType.value !== null && this.clientType.value !== 'All' ? { clientType: this.clientType.value } : {}),
      ...(this.participantLength.value && this.participantLength.value !== 0 ? { participantLength: this.participantLength.value } : {}),
    });

    return new ConferencesSuccessState(ongoingConferences);
  };

  getCSVData = async (pageSize: number): Promise<Data> => {
    const csvData = await this.conferencesApi.getPastConferences(
      {
        appType: this.appType.value,
        callType: this.callType.value,
        end: this.end.value,
        start: this.start.value,
        organizationId: this.selectedOrganization.value || 'All',
        pageIndex: 0,
        pageSize,
        searchBy: this.searchBy.value.value,
        searchVal: this.searchValue.value,
        status: this.status.value,
        endReason: this.endReason.value || 'All',
        ...(this.isUsingMediaServer.value !== null ? { isUsingMediaServer: this.isUsingMediaServer.value } : {}),
        ...(this.clientType.value !== 'All' ? { clientType: this.clientType.value } : {}),
        ...(this.participantLength.value && this.participantLength.value !== 0 ? { participantLength: this.participantLength.value } : {}),
      },
      this.cancelToken.token
    );
    return csvData.conferences.map(conference => ({
      ...conference,
      startedAt: utcToLocalDateTime(conference.startedAt),
      endedAt: utcToLocalDateTime(conference.endedAt),
      participants: conference.participants?.length || 0,
    }));
  };

  resetFilters = () => {
    this.start.next(moment(startOfDay).startOf('day'));
    this.end.next(moment(startOfDay).endOf('day'));
    this.status.next('All');
    this.endReason.next('All');
    this.clientType.next('All');
    this.isUsingMediaServer.next(null);
    this.participantLength.next(null);
    this.appType.next('All');
    this.callType.next('All');
    this.searchValue.next('');
    this.searchBy.next({ value: 1, label: 'Conference Id' });
    this.selectedOrganization.next('All');
    this.timezone.next('');
    this.pageIndex.next(0);
  };
}
