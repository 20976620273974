type Breadcrumbs = 'investigate' | 'conferences';

type BreadcrumbRoutes = {
  [k in Breadcrumbs]: {
    path: string;
    label: string;
  }[];
};

export const breadcrumbRoutes: BreadcrumbRoutes = {
  investigate: [
    { path: '', label: 'Investigate' },
    { path: '/investigate/failures', label: 'Failures' },
    { path: '/investigate/failures/details', label: 'Details' },
    { path: '/investigate/feedbacks', label: 'Feedbacks' },
  ],
  conferences: [
    { path: '', label: 'Conferences' },
    { path: '/conferences/past', label: 'Past Conferences' },
    { path: '/conferences/past/:conferenceId', label: 'Conference Details' },
    { path: '/conferences/past/:conferenceId/participants/:participantId', label: 'Participant Details' },
    { path: '/conferences/past/:conferenceId/participants/:participantId/peers/:peerId/all-stats', label: 'Connection Stats' },
    { path: '/conferences/ongoing', label: 'Ongoing' },
    { path: '/conferences/ongoing/:conferenceId', label: 'Conference Details' },
    { path: '/conferences/ongoing/:conferenceId/participants/:participantId', label: 'Participant Details' },
    { path: '/conferences/ongoing/:conferenceId/participants/:participantId/peers/:peerId/all-stats', label: 'Connection Stats' },
  ],
};
