import { ReactElement } from 'react';
import { IconProps } from './Icon.props';

export const FallDetectonIcon = ({ className, ...rest }: IconProps): ReactElement => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 16 16' fill='none' {...rest}>
    <path
      d='M2.36282 7.76571L2.65292 9.98727C2.71375 10.2373 2.86505 10.4084 3.10108 10.4599C3.19663 10.4807 3.29568 10.4795 3.39067 10.4562C3.73901 10.372 3.89706 10.0575 3.81335 9.70912L3.59551 7.94144L4.92803 7.21357L6.67127 10.4931C6.74703 10.5778 6.82944 10.6562 6.91771 10.7276L10.9693 13.5751C11.1225 13.6828 11.3121 13.7251 11.4964 13.693C11.6808 13.6608 11.8449 13.5567 11.9525 13.4036C12.0601 13.2504 12.1025 13.0608 12.0703 12.8765C12.0381 12.6921 11.934 12.528 11.7809 12.4204L8.54865 10.1484L10.699 9.12838L13.3833 10.2384C13.4689 10.2738 13.5606 10.292 13.6533 10.292C13.746 10.292 13.8377 10.2737 13.9233 10.2382C14.0089 10.2027 14.0867 10.1507 14.1522 10.0852C14.2177 10.0197 14.2696 9.94187 14.3051 9.85625C14.3405 9.77063 14.3587 9.67888 14.3587 9.58622C14.3587 9.49356 14.3404 9.40181 14.3049 9.31621C14.2694 9.23062 14.2174 9.15285 14.1519 9.08735C14.0863 9.02185 14.0085 8.96989 13.9229 8.93446L10.948 7.70384C10.8569 7.66629 10.759 7.64819 10.6605 7.6507C10.562 7.65321 10.4652 7.67627 10.3761 7.7184L8.88035 8.42807C8.66147 8.50346 8.47171 8.39948 8.3121 8.22011C7.76568 7.61182 7.00765 6.23771 7.36795 6.06666L8.6308 5.47345C8.70877 5.43697 8.77868 5.3853 8.83644 5.32147C8.8942 5.25764 8.93865 5.18293 8.96718 5.10171L9.76004 2.8552C9.78846 2.77477 9.80076 2.68954 9.79623 2.60436C9.7917 2.51917 9.77043 2.43572 9.73364 2.35876C9.69685 2.2818 9.64526 2.21284 9.58181 2.15583C9.51837 2.09881 9.44431 2.05486 9.36387 2.02647C9.02541 1.90637 8.73998 2.21468 8.61936 2.55314L7.89149 4.37749L2.72935 7.07476C2.60663 7.14065 2.50624 7.24149 2.4409 7.36449C2.37555 7.4875 2.34872 7.62714 2.36282 7.76571Z'
      stroke='white'
      strokeMiterlimit='10'
    />
    <path
      d='M4.50001 5.00001C5.14434 5.00001 5.66668 4.47767 5.66668 3.83334C5.66668 3.18901 5.14434 2.66667 4.50001 2.66667C3.85568 2.66667 3.33334 3.18901 3.33334 3.83334C3.33334 4.47767 3.85568 5.00001 4.50001 5.00001Z'
      stroke='white'
      strokeMiterlimit='10'
    />
    <path d='M14.6667 15.3333L2.66669 15.3333' stroke='white' strokeLinecap='round' />
  </svg>
);
