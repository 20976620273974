import { ReactElement, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Loader } from '@components';
import { PeerConnectionStatsContext } from '@context';
import { PeerConnectionStatsService, PeerConnectionStatsState, useService } from '@services';
import moment from 'moment';
import Slider from 'rc-slider';
import { PeerConnectionStatsProps } from './PeerConnectionStats.props';

export const PeerConnectionStatsSlider = ({ participant }: PeerConnectionStatsProps): ReactElement => {
  const [, service] = useService<PeerConnectionStatsState, PeerConnectionStatsService>(PeerConnectionStatsContext);
  const [timeRanges, setTimeRanges] = useState<number[]>([service.min, service.max]);

  const handleSetTimeRange = (val: number | number[]) => {
    const min = typeof val === 'number' ? val : val[0];
    const max = typeof val === 'number' ? val : val[1];
    const startTime =
      participant.joinTime +
      Math.round(
        (((service.isOngoing ? +moment.utc() : participant.leaveTime) - participant.joinTime) / service.divisions) * (typeof val === 'number' ? val : val[0])
      );
    const endTime =
      participant.joinTime +
      Math.round(
        (((service.isOngoing ? +moment.utc() : participant.leaveTime) - participant.joinTime) / service.divisions) * (typeof val === 'number' ? val : val[1])
      );

    service.startTime.next(startTime);
    service.endTime.next(endTime);
    setTimeRanges([min, max]);
  };

  const formatLabels = (range: number) => {
    const d = Math.round((((service.isOngoing ? +moment.utc() : participant.leaveTime) - participant.joinTime) / service.divisions) * range);
    const date = new Date(d + participant.joinTime);
    const dateToUTC = `${moment(date).utc(true).format('dd HH:mm:ss A')} UTC`;

    return dateToUTC;
  };

  return (
    <Col lg={{ size: 12, offset: 0 }} style={{ padding: 0 }}>
      <Card className='dark:bg-dark dark:border-gray-700' style={{ padding: '10px 16px 0px 16px' }}>
        <CardBody>
          {!participant && <Loader />}
          <Slider
            range
            defaultValue={[0, 1000]}
            min={service.min}
            max={service.max}
            step={1}
            value={timeRanges}
            onChange={handleSetTimeRange}
            trackStyle={{ backgroundColor: 'rgb(14 165 233)' }}
            handleStyle={{ backgroundColor: 'rgb(14 165 233)', borderColor: 'rgb(56 189 248)', opacity: 1 }}
          />
          <div className='flex justify-between text-sm text-gray-600 dark:text-gray-400 font-mono mt-2'>
            <b>{formatLabels(timeRanges[0])}</b>
            <b>{formatLabels(timeRanges[1])}</b>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
