import { ReactElement, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, Table } from 'reactstrap';
import { EmptyState, Error, Loader } from '@components';
import { DefaultLayout } from '@containers';
import { failuresDetailsContext, ThemeContext } from '@context';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import {
  ErrorState,
  FailuresDetailsService,
  FailuresDetailsState,
  FailuresDetailsSuccessState,
  LoadingState,
  SubjectState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { breadcrumbRoutes } from '@static';
import { customDateFormat } from '@utils';
import classNames from 'classnames';
import moment from 'moment';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

export const FailuresDetails = (): ReactElement => {
  const [state, service] = useService<FailuresDetailsState, FailuresDetailsService>(failuresDetailsContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const { search } = useLocation();

  const queryParams = queryString.parse(search);
  const successState = state instanceof FailuresDetailsSuccessState ? state : null;
  const failuresDetailsHeaders = ['Method', 'Message', 'Date', 'Conference Id'];
  const isLoading = state instanceof LoadingState;
  const isDarkMode = themeService.theme.value === 'dark';

  useEffect(() => {
    service.start.next(moment(parseInt(queryParams.Start?.toString() || '', 10) * 1000));
    service.end.next(moment(parseInt(queryParams.End?.toString() || '', 10) * 1000));
    service.method.next(queryParams.Method?.toString() || '');
    service.message.next(queryParams.Message?.toString() || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state instanceof ErrorState) {
    return <Error message={state.errorMessage} />;
  }

  return (
    <DefaultLayout breadcrumbs={breadcrumbRoutes.investigate}>
      <SubjectState
        subject={service.message || service.method}
        render={() => (
          <Card className='dark:bg-dark dark:border-gray-700'>
            <Table className={classNames('table mb-0', !isDarkMode && 'table-striped')} responsive>
              <thead className='dark:border-gray-700 dark:bg-dark'>
                <tr className='text-sm dark:text-gray-300'>
                  {failuresDetailsHeaders.map(header => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody className={isLoading ? 'h-[410px]  w-full ' : 'dark:border-gray-700 dark:text-gray-300'}>
                {state instanceof ErrorState && (
                  <tr className='text-sm dark:text-gray-300'>
                    <td colSpan={12}>
                      <Error message={state.errorMessage} />
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr className='text-sm'>
                    <td colSpan={12}>
                      <Loader className='absolute left-[45%] top-[35%]' />
                    </td>
                  </tr>
                )}
                {!successState && !isLoading && !(state instanceof ErrorState) && (
                  <EmptyState className='text-center w-full' as='tr'>
                    <td className='bg-[#fff] dark:bg-dark' colSpan={12}>
                      <NewspaperIcon className='text-gray-600 dark:text-gray-300 w-10 h-10 mx-auto' />
                      <span className='font-medium dark:text-gray-300'>No data to be shown.</span>
                    </td>
                  </EmptyState>
                )}
                {successState &&
                  successState?.failuresDetails.data.map(({ method, message, timestamp, conferenceId }) => (
                    <tr className='text-sm' key={uuidv4()}>
                      <td>{method}</td>
                      <td>{message}</td>
                      <td>{customDateFormat(timestamp, 'DD-MM-YYYY HH:mm')}</td>
                      <td>
                        <Link className='dark:text-sky-400 dark:hover:text-sky-600' to={`/conferences/past/${conferenceId}`}>
                          {conferenceId}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        )}
      />
    </DefaultLayout>
  );
};
