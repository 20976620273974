import { ReactElement, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { AiAlertsLabels, AlertStatus, LastActivityStatus, LastActivityType } from '@enums';
import { ChartOptions } from '@views-pages';
import { PieControllerChartOptions } from 'chart.js';
import { GroupedAlerts, StatisticsProps } from './AiDetections.props';

type KeyIndex = keyof typeof LastActivityType;

export const Statistics = ({ aiAlertsTypeCounts, loadingAlerts, error, isDarkMode }: StatisticsProps): ReactElement => {
  const [showNurseStats, setShowNurseStats] = useState<boolean>(false);

  const getAlertsByType = () => {
    const newAlerts: GroupedAlerts = {};

    aiAlertsTypeCounts?.forEach(alert => {
      if (!newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]]) {
        newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]] = { count: 0, alertStatus: {}, nurseStatus: {} };
      }
      newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]].count = alert.count;

      alert.statusCount?.forEach(st => {
        const alertKey = Object.keys(AlertStatus).find(key => AlertStatus[key as keyof typeof AlertStatus] === st.status);
        if (alertKey) {
          newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]].alertStatus[alertKey] = st.count;
        }
      });
      alert.activityCount?.forEach(ac => {
        const nurseKey = Object.keys(LastActivityStatus).find(key => LastActivityStatus[key as keyof typeof LastActivityStatus] === ac.activityType);
        if (nurseKey) {
          newAlerts[LastActivityType[alert.aiAlertType as KeyIndex]].nurseStatus[nurseKey] = ac.count;
        }
      });
    });

    return newAlerts;
  };

  const getChartHoverColor = () => {
    if (showNurseStats) {
      return '#38bdf8';
    }
    if (isDarkMode) {
      return '#747474';
    }
    return '#545454';
  };

  const getChartColor = () => {
    if (showNurseStats) {
      return '#0ea5e9';
    }
    if (isDarkMode) {
      return '#fff';
    }
    return '#343434';
  };

  return (
    <div className='w-full mt-10'>
      {aiAlertsTypeCounts && (
        <div className='flex items-center dark:bg-dark mb-1'>
          <Label className='mb-0 mr-3 text-sm dark:text-white' htmlFor='check'>
            Show nurse stats
          </Label>
          <Input className='m-0' id='check' type='checkbox' onChange={e => setShowNurseStats(e.target.checked)} />
        </div>
      )}
      <Row className='flex items-center w-full mt-10'>
        {error && aiAlertsTypeCounts?.length === 0 && !loadingAlerts && <p className='mb-0 text-red-500 text-sm text-center'>{error}</p>}

        {Object.entries(getAlertsByType()).map(([k, v]) => {
          const backgroundColor = ['#ef4444', '#22c55e', getChartColor()];
          const hoverBackgroundColor = ['#f87171', '#4ade80', getChartHoverColor()];

          const falseAlerts = (showNurseStats ? v.nurseStatus.FALSE : v.alertStatus.FALSE) || 0;
          const acknowledgedAlerts = (showNurseStats ? v.nurseStatus.ACKNOWLEDGED : v.alertStatus.ACKNOWLEDGED) || 0;
          const forwardedAlerts = (showNurseStats ? v.nurseStatus.FORWARDED : v.alertStatus.NEEDS_REVIEW) || 0;

          const data = {
            datasets: [{ data: [falseAlerts, acknowledgedAlerts, forwardedAlerts], backgroundColor, hoverBackgroundColor, label: k }],
            labels: [`False - ${falseAlerts}`, `Acknowledged - ${acknowledgedAlerts}`, `${showNurseStats ? 'Forwarded' : 'Needs Review'} - ${forwardedAlerts}`],
          };

          const options: ChartOptions<'pie', PieControllerChartOptions> = {
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  color: isDarkMode ? '#fff' : '#000',
                },
              },
              tooltip: {
                callbacks: {
                  label: tooltipitem => `${data.datasets[tooltipitem.datasetIndex].data[tooltipitem.dataIndex]}` || '',
                },
              },
            },
            responsive: true,
            layout: { padding: 20 },
          };

          return (
            <Col key={k} md='3' className='my-2'>
              <Card className='dark:bg-dark dark:border-[1px] dark:border-gray-700'>
                <CardHeader className='text-sm dark:text-gray-300 dark:border-b-gray-700 dark:bg-dark-body'>
                  {AiAlertsLabels[k as keyof typeof AiAlertsLabels]} - {v.count}
                </CardHeader>
                <CardBody className='flex items-center justify-center'>
                  <Pie data={data} options={options} />
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
