import { ReactElement } from 'react';
import { Card } from 'reactstrap';
import { EventsParticipantsProps } from './Events.props';

export const EventsParticipant = ({ participant: { fullName }, checked, handleShowParticipant }: EventsParticipantsProps): ReactElement => (
  <Card style={{ borderTop: '2px solid skyblue', padding: 0 }} className='dark:bg-dark'>
    <label className='flex items-start justify-between p-3 dark:bg-dark-body h-full' style={{ cursor: 'pointer', wordBreak: 'break-word' }}>
      <span className='flex flex-col'>
        <strong>Participant: </strong>
        {fullName}
      </span>
      <span className='flex justify-end'>
        <input onChange={handleShowParticipant} type='checkbox' checked={checked} />
      </span>
    </label>
  </Card>
);
