import { ReactElement } from 'react';
import { GraphsProps } from './Graphs.props';
import { GraphSerie } from './GraphSerie';

export const GraphSeries = ({ series }: GraphsProps): ReactElement => (
  <>
    {series
      .filter(s => typeof s.points[0]?.y === 'number')
      .map(serie => {
        return <GraphSerie key={serie.serieName} serie={serie} />;
      })}
  </>
);
