import { SingleValue } from 'react-select';
import { AIStatisticsApi, getOrganizations } from '@api';
import { OrganizationLevels } from '@enums';
import { HealthSystemsModel, OrganizationProps, RoomModel, SingleValueProps, StatisticsModel } from '@interfaces';
import { startOfDay } from '@static';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, from, merge, Observable, skipWhile, switchMap } from 'rxjs';
import { ErrorState } from '../Helpers/Error.service';
import { LoadingState } from '../Helpers/Loading.service';
import { Service } from '../Service';

interface SelectedOrganizationsParams {
  value: string;
  label: string;
}

export class AIStatisticsState {}

export class AIStatisticsSuccessState {
  constructor(statistics: StatisticsModel) {
    this.statistics = statistics;
  }

  statistics: StatisticsModel;
}

export class AIStatisticsService extends Service<AIStatisticsState> {
  constructor(aiStatisticsApi: AIStatisticsApi) {
    super(new AIStatisticsState());
    this.aiStatisticsApi = aiStatisticsApi;

    this.setValuesFromParams();

    getOrganizations()
      .then(organization => this.organizations.next(organization))
      .catch((error: unknown) => (error as AxiosError).message);

    merge(this.selectedOrganization)
      .pipe(switchMap(this.collectHealthSystems))
      .subscribe(healthSystems => {
        const transformedHealthSystems = (healthSystems as HealthSystemsModel).healthSystems?.map(hs => ({ value: hs.id, label: hs.name })) || [];
        this.healthSystems.next(transformedHealthSystems);
      });

    merge(this.selectedHealthSystem)
      .pipe(switchMap(this.collectRooms))
      .subscribe(rooms => {
        const transformedRooms = (rooms as RoomModel).rooms?.map(({ room }) => ({ value: room.id, label: room.name })) || [];
        this.rooms.next(transformedRooms);
      });

    combineLatest([this.refreshCounter])
      .pipe(
        skipWhile(() => !this.selectedOrganization.value?.value && ![...this.queryParams.values()].length),
        switchMap(this.collect)
      )
      .subscribe(state => this.next(state));
  }

  aiStatisticsApi: AIStatisticsApi;

  organizations = new BehaviorSubject<OrganizationProps[]>([]);

  selectedOrganization = new BehaviorSubject<SingleValue<SelectedOrganizationsParams>>({ label: 'Select an Organization', value: '' });

  start = new BehaviorSubject<Moment>(moment(startOfDay).startOf('day'));

  end = new BehaviorSubject<Moment>(moment(startOfDay).endOf('day'));

  showFrames = new BehaviorSubject<boolean>(false);

  showLogs = new BehaviorSubject<boolean>(false);

  refreshCounter = new BehaviorSubject<number>(0);

  queryParams = new URLSearchParams(window.location.search);

  healhSystemId = new BehaviorSubject<string>('');

  healthSystems = new BehaviorSubject<SingleValueProps[]>([]);

  selectedHealthSystem = new BehaviorSubject<SingleValue<SelectedOrganizationsParams>>({ label: 'Select a health system', value: '' });

  rooms = new BehaviorSubject<SingleValueProps[]>([]);

  selectedRoom = new BehaviorSubject<SingleValue<SelectedOrganizationsParams>>({ label: 'Select a room', value: '' });

  levelType = new BehaviorSubject<number>(0);

  levelId = new BehaviorSubject<string>('');

  collect = (): Observable<AIStatisticsState> => {
    this.next(new LoadingState());
    const getStatistics = async () => {
      try {
        const statistics = await this.aiStatisticsApi.getStatistics(this.selectedOrganization.value?.value || '', {
          fromDate: this.start.value.toISOString(),
          toDate: this.end.value.toISOString(),
          levelId: this.levelId.value,
          ...((this.selectedRoom.value?.value || this.selectedHealthSystem.value?.value) && { levelType: this.levelType.value }),
        });

        return new AIStatisticsSuccessState(statistics);
      } catch (error) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getStatistics());
  };

  collectHealthSystems = () => {
    const getHealthSystems = async () => {
      try {
        if (this.selectedOrganization.value?.value) {
          const healthSystems = await this.aiStatisticsApi.getHealthSystems(this.selectedOrganization.value?.value || '');

          return healthSystems;
        }

        return [];
      } catch (error) {
        return (error as AxiosError).message;
      }
    };

    return from(getHealthSystems());
  };

  collectRooms = () => {
    const getRooms = async (): Promise<RoomModel | string> => {
      try {
        if (this.selectedHealthSystem.value?.value) {
          const rooms = await this.aiStatisticsApi.getRoomsByLevelId(
            this.selectedOrganization.value?.value || '',
            this.selectedHealthSystem.value?.value || ''
          );

          return rooms;
        }

        return { rooms: [] };
      } catch (error) {
        return (error as AxiosError).message;
      }
    };

    return from(getRooms());
  };

  setValuesFromParams = () => {
    if ([...this.queryParams.values()].length) {
      this.start.next(moment(this.queryParams.get('start')));
      this.end.next(moment(this.queryParams.get('end')));
      this.selectedOrganization.next({ label: this.selectedOrganization.value?.label || '', value: this.queryParams.get('companyId') || '' });
      this.selectedHealthSystem.next({ value: this.queryParams.get('healthSystemId') || '', label: this.selectedHealthSystem.value?.label || '' });
      this.selectedRoom.next({ value: this.queryParams.get('roomId') || '', label: this.selectedRoom.value?.label || '' });
      const levelType = this.queryParams.get('levelType');
      if (Number(levelType) === OrganizationLevels.HEALTHSYSTEM) {
        this.levelType.next(Number(this.queryParams.get('levelType')));
        this.levelId.next(this.queryParams.get('levelId') || '');
      } else {
        this.levelType.next(Number(this.queryParams.get('levelType')));
        this.levelId.next(this.queryParams.get('levelId') || '');
      }
    }
    this.refreshCounter.next(this.refreshCounter.value + 1);
  };
}
