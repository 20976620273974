import { ReactElement } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { DefaultLayout } from '@containers';
import { ConferenceFeedbacksProvider } from '@context';
import { breadcrumbRoutes } from '@static';
import { FeedbacksFilters } from './FeedbacksFilters';
import { FeedbacksTable } from './FeedbacksTable';

export const Feedbacks = (): ReactElement => {
  return (
    <DefaultLayout breadcrumbs={breadcrumbRoutes.investigate}>
      <ConferenceFeedbacksProvider>
        <Card className='dark:bg-dark dark:border dark:border-gray-700'>
          <CardHeader className='dark:bg-dark-body text-sm dark:text-gray-300 dark:border-b-gray-500'>Filters</CardHeader>
          <CardBody>
            <FeedbacksFilters />
          </CardBody>
        </Card>
        <Card className='mt-10 dark:bg-dark dark:border dark:border-gray-700'>
          <CardHeader className='dark:bg-dark-body text-sm dark:text-gray-300 dark:border-b-gray-500'>Feedbacks</CardHeader>
          <CardBody>
            <FeedbacksTable />
          </CardBody>
        </Card>
      </ConferenceFeedbacksProvider>
    </DefaultLayout>
  );
};
