import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { BehaviorSubject, debounceTime, from, merge, Observable, switchMap } from 'rxjs';
import { FailuresApiProps, FailuresTimelineProps } from '../../../interfaces/index';
import { startOfDay } from '../../../static';
import { ErrorState } from '../../Helpers/Error.service';
import { LoadingState } from '../../Helpers/Loading.service';
import { Service } from '../../Service';

export class FailuresTimelineState {}

export class FailuresTimelineSuccessState extends FailuresTimelineState {
  constructor(failuresTimeline: FailuresTimelineProps) {
    super();
    this.failuresTimeline = failuresTimeline;
  }

  failuresTimeline: FailuresTimelineProps;
}

export class FailuresTimelineService extends Service<FailuresTimelineState> {
  constructor(failuresApi: FailuresApiProps) {
    super(new LoadingState());

    this.failuresApi = failuresApi;

    merge(this.start, this.end)
      .pipe(debounceTime(200))
      .pipe(switchMap(this.collect))
      .subscribe(state => this.next(state));
  }

  private collect = (): Observable<FailuresTimelineState> => {
    this.next(new LoadingState());

    const getState = async () => {
      try {
        const state = await this.getTimelineData();
        return new FailuresTimelineSuccessState(state.failuresTimeline);
      } catch (error: unknown) {
        return new ErrorState((error as AxiosError).message);
      }
    };

    return from(getState());
  };

  failuresApi: FailuresApiProps;

  start = new BehaviorSubject<Moment>(moment(startOfDay).subtract(7, 'days'));

  end = new BehaviorSubject<Moment>(moment(startOfDay).add(1, 'days').subtract(1, 'seconds'));

  private getTimelineData = async () => {
    const failuresTimelineData = await this.failuresApi.getFailuresTimeline(this.start.getValue(), this.end.getValue());

    return new FailuresTimelineSuccessState(failuresTimelineData);
  };
}
