import Joi from 'joi';

export const PatientReportSchema = (): Joi.Schema =>
  Joi.object({
    patientMrn: Joi.string().required().messages({
      'string.empty': 'Patient MRN must not be empty!',
    }),
    tenantId: Joi.string().required().messages({
      'string.empty': 'Please select an organization!',
    }),
  });
