export enum ParticipantStateEnums {
  CONNECTING = 0,
  CONNECTED = 1,
  BUSY = 2,
  DECLINED = 3,
  OFFLINE = 4,
  LEFT_CALL = 5,
  NOT_ANSWERING = 6,
  CANT_CONNECT = 7,
  DISRUPTED = 8,
  RECONNECTING = 9,
  DISCONNECTED = 10,
  REMOVED = 11,
}
