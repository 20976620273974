import { ReactElement, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { EmptyState, Error, Loader } from '@components';
import { DiagnosisContext, ThemeContext } from '@context';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import { DiagnosisService, DiagnosisState, DiagnosisSuccessState, ErrorState, LoadingState, ThemeService, ThemeState, useService } from '@services';
import classNames from 'classnames';
import remarkGfm from 'remark-gfm';
import './Diagnosis.scss';

export const Diagnosis = (): ReactElement => {
  const [state, service] = useService<DiagnosisState, DiagnosisService>(DiagnosisContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const { conferenceId } = useParams();

  const isDarkMode = themeService.theme.value === 'dark';
  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;
  const isEmpty = !isLoading && !(state instanceof ErrorState) && state instanceof DiagnosisSuccessState && !state.summary;
  const isSuccessState = state instanceof DiagnosisSuccessState;

  useEffect(() => {
    service.conferenceId.next(conferenceId || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className='dark:bg-dark dark:border dark:border-gray-700 ' style={{ border: 0 }}>
      <CardBody>
        {isLoading && <Loader />}
        {isEmpty && (
          <EmptyState className='text-center w-full bg-[#fff] dark:bg-dark'>
            <NewspaperIcon className='text-gray-600 dark:text-gray-300 w-10 h-10 mx-auto' />
            <span className='font-medium dark:text-gray-300'>No data to be shown.</span>
          </EmptyState>
        )}
        {isError && <Error message={state.errorMessage} />}
        {isSuccessState && (
          <ReactMarkdown className={classNames('diagnosis text-sm dark:text-gray-400', isDarkMode ? '' : 'diagnosis-light')} remarkPlugins={[remarkGfm]}>
            {state.summary}
          </ReactMarkdown>
        )}
      </CardBody>
    </Card>
  );
};
