import { gatewayApi, signalingApi } from '@config';
import { ConferenceModel, ConferencesApiProps, ConferencesModel, ConferencesParams, OngoingConferencesParams } from '@interfaces';
import { AxiosResponse, CancelToken } from 'axios';

export class ConferencesApi implements ConferencesApiProps {
  getOngoingConferences = async (params: OngoingConferencesParams): Promise<ConferencesModel> => {
    const { data }: AxiosResponse<ConferencesModel> = await gatewayApi.get(`v1/conferences/ongoing`, {
      params: { ...params, start: params.start.unix(), end: params.end.unix() },
    });

    return data;
  };

  getPastConferences = async (params: ConferencesParams, cancelToken: CancelToken): Promise<ConferencesModel> => {
    const { data }: AxiosResponse<ConferencesModel> = await gatewayApi.get(`v1/conferences/past`, {
      params: { ...params, start: params.start.unix(), end: params.end.unix() },
      cancelToken,
    });

    return data;
  };

  getOngoingConferenceDetais = async (conferenceId: string): Promise<ConferenceModel> => {
    const { data }: AxiosResponse<{ conference: ConferenceModel }> = await gatewayApi.get(`v1/conferences/ongoing/${conferenceId}`);

    return data.conference;
  };

  getAutomaticDiagnosis = async (conferenceId: string): Promise<{ summary: string }> => {
    const { data }: AxiosResponse<{ summary: string }> = await gatewayApi.get(`v1/conferences/past/${conferenceId}/automatic-diagnosis`);

    return data;
  };

  terminateConferenceCall = async (conferenceId: string): Promise<boolean> => {
    const { data }: AxiosResponse<boolean> = await signalingApi.post('conferences/terminate', { conferenceId });

    return data;
  };

  terminateParticipantCall = async (participantId: string): Promise<boolean> => {
    const { data }: AxiosResponse<boolean> = await signalingApi.post(`conferences/participants/${participantId}/terminate`);

    return data;
  };

  getConferenceDetails = async (id: string): Promise<ConferenceModel> => {
    const { data }: AxiosResponse<{ conference: ConferenceModel }> = await gatewayApi.get(`v1/conferences/${id}`);

    return data.conference;
  };
}
