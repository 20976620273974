import { ReactElement } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, Table, TabPane } from 'reactstrap';
import { EmptyState, Error, Loader } from '@components';
import { ParticipantsContext, SingleParticipantContext, ThemeContext } from '@context';
import { ParticipantStateEnums, Peers } from '@enums';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import {
  ErrorState,
  LoadingState,
  ParticipantsService,
  ParticipantsState,
  ParticipantsSuccessState,
  SingleParticipantService,
  SingleParticipantState,
  SingleParticipantSucessState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { peerParticipantsHeaders } from '@static';
import { getLowerCaseString, utcTimeStampToLocaleTime } from '@utils';

export const PeerParticipants = (): ReactElement => {
  const [state, service] = useService<SingleParticipantState, SingleParticipantService>(SingleParticipantContext);
  const [participantsState] = useService<ParticipantsState, ParticipantsService>(ParticipantsContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const { conferenceId, participantId } = useParams();
  const { SUBSCRIBER, PUBLISHER, SIP } = Peers;

  const activeTab = 0;
  const isSuccessState = state instanceof SingleParticipantSucessState;
  const isLoading = state instanceof LoadingState;
  const isError = state instanceof ErrorState;
  const isEmptyState =
    !isLoading && !(state instanceof ErrorState) && state instanceof SingleParticipantSucessState && state.peerParticipants.peers.length === 0;
  const isDarkMode = themeService.theme.value === 'dark';

  return (
    <div className='mt-24'>
      <Nav className='dark:border-gray-700' tabs>
        <NavItem>
          <NavLink
            className={isDarkMode ? 'active-tab' : ''}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              color: isDarkMode ? 'rgb(156, 163, 175)' : 'rgb(75 85 99)',
            }}
            active={activeTab === 0}>
            <UserGroupIcon className='w-6 h-6 text-gray-400 mr-2' />
            Peers
          </NavLink>
        </NavItem>
      </Nav>

      <Card className='dark:bg-dark dark:border-gray-700' style={{ borderTop: 0, borderRadius: '0 0 6px 6px' }}>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <Table>
                <thead>
                  <tr className='text-sm dark:border-gray-700 dark:text-[#989082]'>
                    {peerParticipantsHeaders.map((header, index) => (
                      <th key={header} colSpan={index === peerParticipantsHeaders.length - 1 ? 2 : 0}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='dark:border-gray-700 dark:text-gray-300'>
                  {isEmptyState && (
                    <EmptyState className='text-center w-full' as='tr'>
                      <td className='bg-[#fff] dark:bg-dark' colSpan={14}>
                        <UserGroupIcon className='text-gray-600 dark:text-gray-400 w-10 h-10 mx-auto' />
                        <span className='font-medium text-sm dark:text-gray-400'>No peers in this conference.</span>
                      </td>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <tr className='text-sm'>
                      <td colSpan={6}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {isError && (
                    <tr className='text-sm'>
                      <td colSpan={6}>
                        <Error message={state.errorMessage} />
                      </td>
                    </tr>
                  )}
                  {isSuccessState &&
                    state.peerParticipants.peers.map(peer => {
                      if (peer === PUBLISHER || peer === SUBSCRIBER || peer === SIP) {
                        return (
                          <tr className='text-sm' key={peer}>
                            <td>
                              {(peer === PUBLISHER || peer === SUBSCRIBER) && 'Mediaserver'} {peer}
                            </td>
                            <td />
                            <td />
                            <td />
                            <td>
                              <Link
                                className='no-underline text-sky-400 dark:hover:text-sky-600'
                                to={{
                                  pathname: `/conferences/${
                                    service.isOngoing ? 'ongoing' : 'past'
                                  }/${conferenceId}/participants/${participantId}/peers/${peer}/all-stats`,
                                }}>
                                Show Connection Stats
                              </Link>
                            </td>
                          </tr>
                        );
                      }

                      const participant =
                        participantsState instanceof ParticipantsSuccessState ? participantsState.participants.find(p => p.id === peer) : null;

                      return (
                        <tr className='text-sm' key={participant?.id}>
                          <td>{participant?.fullName}</td>
                          <td>{participant?.device}</td>
                          <td>{getLowerCaseString(ParticipantStateEnums[participant?.state || 0])}</td>
                          <td>{(participant?.joinTime || 0) > 0 ? utcTimeStampToLocaleTime(participant?.joinTime || 0) : ''}</td>
                          <td>
                            <Link
                              className='no-underline text-sky-400 dark:hover:text-sky-600'
                              to={`/conferences/${service.isOngoing ? 'ongoing' : 'past'}/${conferenceId}/participants/${participantId}/peers/${
                                participant?.id
                              }/all-stats`}>
                              Show Connection Stats
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};
